import { UNIT_FREE_SOURCES_ROUTE, UNIT_FREE_USERS_ROUTE, UnitQueryParam } from '@/shared/constants';
import { useParams, useSearchParam } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';

const useUnitRouteInfo = () => {
  const { unitId = '' } = useParams<UnitRouteParams>();
  const [search] = useSearchParam(UnitQueryParam.Search);

  const isFreeSourcesRoute = unitId === UNIT_FREE_SOURCES_ROUTE;
  const isFreeUsersRoute = unitId === UNIT_FREE_USERS_ROUTE;
  const isUnitsSearchByUser = search.startsWith('@') || search.startsWith('+');

  return {
    routeUnitId: isFreeSourcesRoute || isFreeUsersRoute ? 'null' : unitId,
    isFreeSourcesRoute,
    isFreeUsersRoute,
    isUnitsSearchByUser,
  };
};

export default useUnitRouteInfo;
