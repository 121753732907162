import { FocusEventHandler, KeyboardEventHandler, forwardRef } from 'react';
import { Input, InputProps, InputRef, Tooltip } from '@/shared/components';

type PickedInputProps = Pick<
  InputProps,
  'defaultValue' | 'disabled' | 'placeholder' | 'value' | 'onChange' | 'autoFocus' | 'className'
>;

export interface Props extends PickedInputProps {
  onBlur: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  tooltipTitle?: string;
}

const DISABLED_INPUT_TOOLTIP = 'неможливо змінити';

const CallSignInput = forwardRef<InputRef, Props>(
  (
    {
      defaultValue,
      disabled,
      onBlur,
      onKeyDown,
      tooltipTitle,
      placeholder,
      value,
      onChange,
      autoFocus,
      className,
      ...restProps
    },
    ref
  ) => {
    return disabled && tooltipTitle ? (
      <Tooltip title={tooltipTitle || DISABLED_INPUT_TOOLTIP}>
        <Input
          className={className}
          defaultValue={defaultValue}
          disabled
          placeholder={placeholder}
          isTransparentOnDesktop
        />
      </Tooltip>
    ) : (
      <Input
        className={className}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        maxLength={255}
        isTransparentOnDesktop
        {...restProps}
      />
    );
  }
);

CallSignInput.displayName = 'CallSignInput';

export default CallSignInput;
