import { useMemo } from 'react';
import { MediaProps, MediaType } from '../types';
import { isImageMedia, isVideoMedia } from '../utils';

interface Params extends Pick<MediaProps, 'memoMedia'> {}

const useMedia = ({ memoMedia }: Params) => {
  const mediaType = useMemo((): MediaType => {
    if (isImageMedia(memoMedia)) return 'image';
    if (isVideoMedia(memoMedia)) return 'video';
    return 'none';
  }, [memoMedia]);

  return {
    mediaType,
  };
};

export default useMedia;
