import { useCallback, useEffect } from 'react';
import { MESSAGE_SELECTOR } from '@/entities/transcript/constants';
import { getSelectionText } from '@/entities/transcript/slices';
import { useAppSelector, useIsTabletOrMobile } from '@/shared/hooks';
import { extendSelectionToWholeWord, getEntityElement } from '../utils';
import { useSelectionBox } from './useSelectionBox';
import { useSelectionDetails } from './useSelectionDetails';

const useMessageClickListener = () => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const stateSelection = useAppSelector(getSelectionText);
  const openSelectionBox = useSelectionBox();
  const openSelectionDetails = useSelectionDetails();

  const getCurrentSelection = () => window.getSelection();

  const handleMessageClick = useCallback(
    (messageElement: HTMLElement, target: HTMLElement) => {
      const selection = getCurrentSelection();
      const { entityElement, isCodeTarget } = getEntityElement(target);

      if (selection?.toString()) {
        extendSelectionToWholeWord(selection);
      }

      const selectedEntry = getCurrentSelection()?.toString().trim();

      if (selectedEntry && selectedEntry.length > 1 && selectedEntry !== stateSelection && !isCodeTarget) {
        return openSelectionBox(messageElement);
      }

      if (!selectedEntry && entityElement) {
        return openSelectionDetails(messageElement, entityElement);
      }
    },
    [stateSelection, openSelectionBox, openSelectionDetails]
  );

  useEffect(() => {
    const eventHandler = (event: Event) => {
      // ignore events outside the transcript message element
      if (!(event.target instanceof HTMLElement)) return;
      const messageElement = event.target.closest<HTMLElement>(MESSAGE_SELECTOR);
      if (!messageElement) return;

      if (event.type === 'contextmenu') event.preventDefault();

      handleMessageClick(messageElement, event.target);
    };

    document.addEventListener('click', eventHandler);
    if (isTabletOrMobile) {
      document.addEventListener('contextmenu', eventHandler);
    }
    return () => {
      document.removeEventListener('click', eventHandler);
      if (isTabletOrMobile) {
        document.removeEventListener('contextmenu', eventHandler);
      }
    };
  }, [handleMessageClick, isTabletOrMobile]);
};

export default useMessageClickListener;
