const SPACE_MATCH_REGEX = /[^\u0400-\u04FFa-zA-Z0-9'‘’]/;

const extendSelectionToWholeWord = (selection: Selection) => {
  const checkRegex = () => SPACE_MATCH_REGEX.test(selection.toString());

  if (!checkRegex()) {
    selection.modify('move', 'left', 'word');
    selection.modify('extend', 'right', 'word');

    // shouldn't include the symbols from regex
    if (checkRegex() && selection.anchorNode && selection.focusNode) {
      // move backward to remove the last character
      selection.setBaseAndExtent(
        selection.anchorNode,
        selection.anchorOffset,
        selection.focusNode,
        selection.focusOffset - 1
      );
    }
  }
};

export default extendSelectionToWholeWord;
