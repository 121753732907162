import { ReactElement } from 'react';
import { Layout } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import { ProfileIcon } from '@/shared/assets';
import NavigationButton from '@/shared/components/NavigationButton';
import { COPIES, MobileView } from '@/shared/constants';
import { useAppDispatch, useAppSelector, useIsTablet, useMobileActiveViewQueryParam } from '@/shared/hooks';
import {
  setIsNavigationSidebarCollapsed,
  selectIsNavigationSidebarCollapsed,
  setNavigationDrawer,
} from '@/shared/slices';
import { NavigationMenu } from './components';
import styles from './styles.module.css';

export interface Props {
  footerTopChildren?: ReactElement;
}

export const NavigationContent = ({ footerTopChildren }: Props) => {
  const isTablet = useIsTablet();
  const { setMobileActiveView } = useMobileActiveViewQueryParam();
  const isSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const dispatch = useAppDispatch();
  // Hide until new requirement
  const isSubscriptionHidden = true;

  const handleMenuItemClick = () => {
    setMobileActiveView?.(MobileView.PrimaryScreen, false);
    if (isTablet) dispatch(setIsNavigationSidebarCollapsed(true));
  };

  const handlePlaceholderClick = () => dispatch(setIsNavigationSidebarCollapsed(false));

  const openSubscription = () => {
    dispatch(setNavigationDrawer('subscription'));
    handleMenuItemClick();
  };

  const openProfile = () => {
    dispatch(setNavigationDrawer('profile'));
    handleMenuItemClick();
  };

  return (
    <>
      <Layout.Content className={styles.navigationContent}>
        <NavigationMenu onMenuItemClick={handleMenuItemClick} />
      </Layout.Content>
      <Layout.Content
        className={styles.placeholder}
        onClick={isTablet && isSidebarCollapsed ? handlePlaceholderClick : undefined}
      />
      <Layout.Footer className={styles.navigationFooter}>
        {footerTopChildren}
        {!isSubscriptionHidden && (
          <NavigationButton
            Icon={MobileOutlined}
            label={COPIES.SUBSCRIPTION}
            onClick={openSubscription}
          />
        )}
        <NavigationButton
          Icon={ProfileIcon}
          label={COPIES.PROFILE}
          onClick={openProfile}
          data-testid="profile-button"
        />
      </Layout.Footer>
    </>
  );
};
