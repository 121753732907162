import { memo } from 'react';
import { Col } from 'antd';
import { TranscriptsProps } from '@/shared/types';
import { TranscriptList } from './components';
import styles from './styles.module.css';
import useTranscripts from './useTranscripts';

const Transcripts = memo((props: TranscriptsProps) => {
  const { renderSelectionDetails } = useTranscripts();

  return (
    <>
      <Col flex="auto">
        <TranscriptList {...props} />
      </Col>
      <Col
        flex="none"
        className={styles.layoutFlex}
      >
        {renderSelectionDetails()}
      </Col>
    </>
  );
});

Transcripts.displayName = 'Transcripts';

export default Transcripts;
