import { MutateOptions, useMutation } from '@tanstack/react-query';
import { markVoiceExist as markVoiceExistApi } from '@/entities/source/@fox/api';
import { MarkVoiceExist } from '@/entities/source/@fox/types';
import { MARK_VOICE_EXIST_MUTATION_KEY } from '@/shared/constants';

const useMarkVoiceExist = (options: MutateOptions<MarkVoiceExist, unknown, string> = {}) => {
  const { mutate: markVoiceExist, isLoading: isMarkVoiceExistLoading } = useMutation({
    ...options,
    mutationKey: [MARK_VOICE_EXIST_MUTATION_KEY],
    mutationFn: markVoiceExistApi,
  });

  return {
    markVoiceExist,
    isMarkVoiceExistLoading,
  };
};

export default useMarkVoiceExist;
