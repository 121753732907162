import { useSpectrogramController } from './hooks';
import { SpectrogramComponent as SpectrogramComponentType, SpectrogramFrequency } from './types';

interface Props {
  isSpectrogramEnabled: boolean;
  SpectrogramComponent: SpectrogramComponentType;
  memoOnFrequencyClick?: (id: SpectrogramFrequency['id']) => void;
}

const temporaryMemoHandleFrequencyEdit = async () => {};

const SpectrogramController = ({ SpectrogramComponent, isSpectrogramEnabled, memoOnFrequencyClick }: Props) => {
  const {
    renderWatermark,
    watermarkColor,
    decibelPower,
    detections,
    minDecibel,
    maxDecibel,
    detectionsVisibilityRange,
    startFrequency,
    endFrequency,
    memoFrequencies,
    handleCreateFoxFrequency,
    handleDeleteFoxFrequency,
    spectrogramStatus,
    memoSelectedFrequencies,
    memoHandleFrequenciesSelect,
    isLoading,
  } = useSpectrogramController({
    isSpectrogramEnabled,
  });

  return (
    isSpectrogramEnabled &&
    startFrequency &&
    endFrequency && (
      <SpectrogramComponent
        isLoading={isLoading}
        status={spectrogramStatus}
        detections={detections}
        decibelPower={decibelPower}
        minDecibel={minDecibel}
        maxDecibel={maxDecibel}
        detectionsVisibilityRange={detectionsVisibilityRange}
        startFrequency={startFrequency}
        endFrequency={endFrequency}
        memoFrequencies={memoFrequencies}
        memoOnDeleteFrequency={handleDeleteFoxFrequency}
        memoOnFrequencyClick={memoOnFrequencyClick}
        memoOnEditFrequency={temporaryMemoHandleFrequencyEdit}
        onCreateFrequency={handleCreateFoxFrequency}
        memoSelectedFrequencies={memoSelectedFrequencies}
        memoOnFrequenciesSelect={memoHandleFrequenciesSelect}
      >
        {renderWatermark?.({ color: watermarkColor })}
      </SpectrogramComponent>
    )
  );
};

export default SpectrogramController;
