import { FrequenciesSelectedBoundaries } from '../types';

interface Params {
  frequenciesSelectedBoundaries: FrequenciesSelectedBoundaries;
}

const validateFrequenciesSelectedBoundaries = ({ frequenciesSelectedBoundaries }: Params) => {
  const isRestrictedZoomLevel =
    frequenciesSelectedBoundaries.startSelectedFrequency === frequenciesSelectedBoundaries.endSelectedFrequency;
  const lowestZoomFrequenciesSelectedBoundaries = {
    startSelectedFrequency: frequenciesSelectedBoundaries.startSelectedFrequency,
    endSelectedFrequency: frequenciesSelectedBoundaries.startSelectedFrequency + 0.001,
  };
  const validFrequenciesSelectedBoundaries = isRestrictedZoomLevel
    ? lowestZoomFrequenciesSelectedBoundaries
    : frequenciesSelectedBoundaries;

  return {
    isRestrictedZoomLevel,
    validFrequenciesSelectedBoundaries,
  };
};

export default validateFrequenciesSelectedBoundaries;
