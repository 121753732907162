import { useEffect, useState } from 'react';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';
import useGetFiles from './useGetFiles';

const useIsSelectedRecordDecoded = () => {
  const [isDecoded, setIsDecoded] = useState(false);
  const { tabEntityId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const { filesList } = useGetFiles({
    options: {
      enabled: false,
    },
    queryParams,
  });

  useEffect(() => {
    const record = filesList.find((file) => file.id === tabEntityId);
    setIsDecoded(record?.metadata.isDecoded ?? false);
  }, [tabEntityId, filesList]);

  return isDecoded;
};

export default useIsSelectedRecordDecoded;
