import { Typography } from 'antd';
import { Notification } from '@/entities/notification/api/types';
import styles from './styles.module.css';

interface Props {
  title: Notification['title'];
}

const Title = ({ title }: Props) => {
  return <Typography.Title className={styles.title}>{title}</Typography.Title>;
};

export default Title;
