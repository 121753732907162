import { memo } from 'react';
import { ResponsiveManager } from '@/shared/components';
import {
  Timeline,
  Pipe,
  PlayButton,
  RateMenu,
  AutoplayButton,
  AudioInformation,
  SilenceSkip,
  SilenceSkipIconButton,
} from './components';
import { usePlayer } from './hooks';
import styles from './styles.module.css';
import { AudioPlayerProps } from './type';

const AudioPlayer = memo(
  ({
    isRecordSelected,
    shouldBePlaying = false,
    memoSettings,
    memoOnPlayPause,
    memoOnSettingsUpdate,
    memoPlayNextRecord,
    memoNavigateToPreviousRecord,
    memoNavigateToNextRecord,
    hasVoice,
    timestamp,
    url,
    stringifiedPeaks,
    duration,
    isDisabled = false,
  }: AudioPlayerProps) => {
    const {
      containerRef,
      currentTime,
      isPlaying,
      isAutoplayOn,
      audioRate,
      timeTooltipPosition,
      handleRateSelect,
      memoToggleAutoplay,
      memoPlayPause,
      isSkipSilenceEnabled,
      memoHandleSilenceSkipChange,
      loadedAudioDuration,
    } = usePlayer({
      isRecordSelected,
      shouldBePlaying,
      memoSettings,
      memoPlayNextRecord,
      memoOnPlayPause,
      memoOnSettingsUpdate,
      memoNavigateToPreviousRecord,
      memoNavigateToNextRecord,
      hasVoice,
      url,
      stringifiedPeaks,
      duration,
      isDisabled,
    });

    return (
      <div
        className={styles.playerWrap}
        tabIndex={0}
      >
        <Timeline
          containerRef={containerRef}
          currentTime={currentTime}
          timeTooltipPosition={timeTooltipPosition}
        />
        <div className={styles.player}>
          <div className={styles.leftNav}>
            <PlayButton
              isPlaying={isPlaying}
              playPause={memoPlayPause}
            />
            <Pipe />
            <AutoplayButton
              autoplay={isAutoplayOn}
              onClick={memoToggleAutoplay}
            />
            <Pipe />
            <AudioInformation
              timestamp={timestamp}
              currentTime={currentTime}
              duration={loadedAudioDuration}
            />
          </div>
          <div className={styles.rightNav}>
            <RateMenu
              audioRate={audioRate}
              onRateSelect={handleRateSelect}
            />
            <ResponsiveManager
              desktop={
                <SilenceSkip
                  isSkipSilenceEnabled={isSkipSilenceEnabled}
                  onChange={memoHandleSilenceSkipChange}
                />
              }
              mobile={
                <SilenceSkipIconButton
                  isSkipSilenceEnabled={isSkipSilenceEnabled}
                  onClick={memoHandleSilenceSkipChange}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }
);

AudioPlayer.displayName = 'AudioPlayer';

export default AudioPlayer;
