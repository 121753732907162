import { useRef, useEffect } from 'react';
import { SpectrumService, SpectrumInstance } from '../services';
import { convertDecibelPowerToSpectrumData } from '../utils';
import { UseSpectrogramInstanceParams as Params } from './types';

let canvasBackgroundColor: string;

const useSpectrogramInstance = ({
  canvasId,
  spectrumPercent,
  startFrequency,
  endFrequency,
  minDecibel,
  maxDecibel,
}: Params) => {
  const spectrogram = useRef<SpectrumInstance>(null);

  useEffect(() => {
    if (!canvasBackgroundColor) {
      const styles = getComputedStyle(document.documentElement);
      canvasBackgroundColor = styles.getPropertyValue('--spectrogram-background-color');
    }

    if (!spectrogram.current) {
      // @ts-ignore
      spectrogram.current = new SpectrumService(canvasId, {
        spectrumPercent,
        start_wavelength: startFrequency,
        end_wavelength: endFrequency,
        step_num: 15,
        fill_style: canvasBackgroundColor,
        min_db: convertDecibelPowerToSpectrumData(minDecibel),
        max_db: convertDecibelPowerToSpectrumData(maxDecibel),
        minCanvasWidthQuality: 5000,
      });
    }
  }, [minDecibel, maxDecibel]);

  return spectrogram.current;
};

export default useSpectrogramInstance;
