import { FocusEvent, ForwardedRef, KeyboardEvent, useState } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import { TextAreaProps, TextAreaRef } from '../types';

interface Params extends TextAreaProps {}

const useTextArea = (
  { defaultValue, value, onFocus, onBlur, onPressEnter, isTransparentOnDesktop, overflowEllipsisRows }: Params,
  ref: ForwardedRef<TextAreaRef>
) => {
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();
  const [isFocused, setIsFocused] = useState(false);

  const pseudoElementValue = value || defaultValue;
  const showPseudoElement = overflowEllipsisRows && pseudoElementValue;

  const size: SizeType = isTabletOrMobile ? 'large' : 'middle';
  const isHidden = showPseudoElement && !isFocused;
  const isTransparent = isTransparentOnDesktop && !isMobile;

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handleClick = () => {
    if (ref && 'current' in ref) {
      ref.current?.focus();
    }
  };

  const handlePressEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const elem = e.currentTarget;

    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      elem.form?.requestSubmit();
      if (isTransparentOnDesktop) elem.blur();
      onPressEnter?.(e);
    }
  };

  return {
    size,
    isHidden,
    isTransparent,
    isFocused,
    handleFocus,
    handleBlur,
    handleClick,
    handlePressEnter,
    showPseudoElement,
    pseudoElementValue,
  };
};

export default useTextArea;
