import { RefObject, useRef, useState, useEffect } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SelectProps } from 'antd/es/select';
import { useDebounceFn } from '@reactuses/core';
import isBoolean from 'lodash/isBoolean';
import type { BaseSelectRef } from 'rc-select';
import { DEBOUNCE_DEFAULT, SEARCH_MIN_LENGTH } from '@/shared/constants';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import { invisibleTextService } from '@/shared/services';

const SINGLE_SELECT_PLACEHOLDER = 'Виберіть опцію';
const MULTI_SELECT_PLACEHOLDER = 'Виберіть опції';

interface Params extends Pick<SelectProps, 'mode' | 'searchValue' | 'onSearch' | 'onClear' | 'onBlur' | 'loading'> {
  inputRef?: RefObject<BaseSelectRef>;
  isAsync?: boolean;
  isTransparentOnDesktop?: boolean;
  stickToParentContainer?: boolean | RefObject<HTMLElement>;
}

export const useSelect = ({
  inputRef,
  mode,
  searchValue,
  onSearch = () => null,
  onClear,
  onBlur,
  loading,
  isAsync,
  isTransparentOnDesktop,
  stickToParentContainer,
}: Params) => {
  const internalRef = useRef<BaseSelectRef>(null);
  const selectRef = inputRef ?? internalRef;
  const [search, setSearch] = useState('');
  const [notFoundValue, setNotFoundValue] = useState('');
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  const getDefaultPlaceholder = () => (!mode ? SINGLE_SELECT_PLACEHOLDER : MULTI_SELECT_PLACEHOLDER);

  const getPopupContainer: SelectProps['getPopupContainer'] = (trigger) => {
    if (!stickToParentContainer) return;
    if (isBoolean(stickToParentContainer) && stickToParentContainer) {
      return trigger.parentElement;
    }
    if (stickToParentContainer.current) {
      return stickToParentContainer.current;
    }
  };

  const { run: debouncedSearch } = useDebounceFn(onSearch, isAsync ? DEBOUNCE_DEFAULT : 0);
  const handleSearch: SelectProps['onSearch'] = (inputValue) => {
    const normalizedValue = invisibleTextService.replaceInvisibleSymbols(inputValue.trimStart());
    const isSearchAllowed = normalizedValue.length === 0 || normalizedValue.length >= SEARCH_MIN_LENGTH;

    setSearch(normalizedValue);

    if (isSearchAllowed) {
      debouncedSearch(normalizedValue);
    }
  };

  const handleClear: SelectProps['onClear'] = () => {
    if (isTransparentOnDesktop && !isMobile) {
      selectRef.current?.blur();
    }
    onClear?.();
  };

  const handleBlur: SelectProps['onBlur'] = (event) => {
    onBlur?.(event);
    setSearch('');
    onSearch('');
  };

  useEffect(() => {
    // set default input value
    if (searchValue) {
      setSearch(searchValue);
    }
  }, []);

  useEffect(() => {
    // wait response to update no search results value
    if (!loading) {
      setNotFoundValue(search);
    }
  }, [loading]);

  return {
    selectRef,
    getDefaultPlaceholder,
    getPopupContainer: stickToParentContainer ? getPopupContainer : undefined,
    search,
    handleSearch,
    notFoundValue: isAsync ? notFoundValue : search,
    handleClear,
    handleBlur,
    isMobile,
    size: (isTabletOrMobile ? 'large' : 'middle') as SizeType,
  };
};
