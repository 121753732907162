import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import FoxFrequencyActions from '@/entities/source/@fox/components/ScanningTab/components/FoxFrequencyActions';
import {
  useFoxFrequenciesQueryParams,
  useGetFilteredFoxFrequencies,
} from '@/entities/source/@fox/components/ScanningTab/hooks';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { FoxFrequency } from '@/entities/source/@fox/types';
import { useSource } from '@/entities/source/hooks';
import { setLastSelectedFrequency } from '@/entities/source/slices';
import { FoxSource } from '@/entities/source/types';
import { FOX_FREQUENCIES_QUERY_KEY, FoxScanningQueryParam } from '@/shared/constants';
import {
  useAppDispatch,
  useParams,
  useInvalidateQueryOnTop,
  useSearchParam,
  useScrollTopPosition,
} from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { CreateFoxFrequencyForm, FoxFrequencyDetails, FoxFrequencyDetailsTitle, FoxFrequencyRow } from '../components';
import styles from '../styles.module.css';

type Params = {
  highlightedFoxFrequencyId: string | null;
};

const useMobileFoxFrequenciesList = ({ highlightedFoxFrequencyId }: Params) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryParams = useFoxFrequenciesQueryParams();
  const source = useSource<FoxSource>();
  const isSourceOnline = source?.status === 'online';
  const dispatch = useAppDispatch();
  const [search] = useSearchParam(FoxScanningQueryParam.Search);
  const frequenciesListWrapperRef = useRef<HTMLDivElement>(null);

  const { foxFrequencies, isFoxFrequenciesLoading, isFoxFrequenciesFetching, refetchFoxFrequencies } =
    useGetFilteredFoxFrequencies();
  const isLoading = isFoxFrequenciesLoading || isFoxFrequenciesFetching;

  const handleRowClassName = ({ id, status }: FoxFrequency) => {
    return classNames({
      [styles.highlightedItem]: id === highlightedFoxFrequencyId,
      [styles.inactiveItem]: status === FoxFrequencyStatus.Inactive,
    });
  };

  const renderRow = (frequency: FoxFrequency) => (
    <FoxFrequencyRow
      frequency={frequency}
      search={search}
    />
  );

  const renderDetailsTitle = (frequency: FoxFrequency) => <FoxFrequencyDetailsTitle frequency={frequency} />;
  const renderDetailsComponent = (frequency: FoxFrequency) => <FoxFrequencyDetails frequency={frequency} />;

  const renderCreateComponent = (onCreateFinish: VoidFunction) => (
    <CreateFoxFrequencyForm onCloseForm={onCreateFinish} />
  );

  const renderActions = ({ id, status }: FoxFrequency, _onEdit: VoidFunction, onDelete: VoidFunction) => (
    <FoxFrequencyActions
      foxFrequencyId={id}
      foxFrequencyStatus={status}
      onDelete={onDelete}
    />
  );

  const handleItemClick = ({ id }: FoxFrequency) => {
    dispatch(setLastSelectedFrequency(id));
  };

  const { handleScroll, scrollTopPosition } = useScrollTopPosition();
  useInvalidateQueryOnTop({
    queryKey: [FOX_FREQUENCIES_QUERY_KEY, sourceId, queryParams],
    scrollTopPosition,
    isRefetchEnabled: !isFoxFrequenciesFetching,
  });

  useEffect(() => {
    frequenciesListWrapperRef.current?.scrollTo({ top: 0 });
  }, [queryParams, search]);

  return {
    isSourceOnline,
    foxFrequencies,
    isLoading,
    handleRowClassName,
    renderRow,
    renderDetailsTitle,
    renderDetailsComponent,
    renderCreateComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    refetchFoxFrequencies,
    scrollTopPosition,
    frequenciesListWrapperRef,
  };
};

export default useMobileFoxFrequenciesList;
