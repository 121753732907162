import { memo } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import { useIsMobile } from '@/shared/hooks';
import { timeService } from '@/shared/services';
import styles from './styles.module.css';

const NO_ACTIVITY = 'Немає активності';

interface Props {
  className?: string;
  activity?: number | null;
}

const SourceActivity = memo(({ className, activity }: Props) => {
  const isMobile = useIsMobile();
  const tooltipTitle = activity ? timeService.getFormattedDate(activity, true) : NO_ACTIVITY;

  return (
    <Typography.Text
      className={classNames(styles.activity, className)}
      ellipsis={isMobile ? { tooltip: tooltipTitle } : undefined}
    >
      {activity ? (
        timeService.getFormattedFullTimeOrDate(activity)
      ) : (
        <Typography.Text
          className={styles.noActivity}
          disabled
        >
          {NO_ACTIVITY}
        </Typography.Text>
      )}
    </Typography.Text>
  );
});

SourceActivity.displayName = 'SourceActivity';

export default SourceActivity;
