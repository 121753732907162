import { useEffect, useState } from 'react';
import { useDebounce } from '@reactuses/core';
import { DEBOUNCE_DEFAULT, MAX_REFETCH_INTERVAL } from '@/shared/constants';

const useRefetchInterval = (shouldAutoRefetch = false) => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(MAX_REFETCH_INTERVAL);
  const debouncedRefetchInterval = useDebounce(refetchInterval, DEBOUNCE_DEFAULT, { leading: true });

  useEffect(() => setRefetchInterval(shouldAutoRefetch ? MAX_REFETCH_INTERVAL : false), [shouldAutoRefetch]);

  return {
    refetchInterval: debouncedRefetchInterval,
    setRefetchInterval,
  };
};

export default useRefetchInterval;
