import dayjs from 'dayjs';
import { Notification } from '@/entities/notification/api/types';
import { ResponseData } from '@/shared/api';

const selectSortedNotificationsByDate = (data: ResponseData<Notification[]>): ResponseData<Notification[]> => {
  const sortedNotifications = data.data.toSorted((notificationA, notificationB) => {
    if (!notificationA.date) return -1;
    if (notificationA.date && notificationB.date) {
      return dayjs(notificationA.date).isBefore(dayjs(notificationB.date)) ? 1 : -1;
    }
    return 1;
  });

  return {
    data: sortedNotifications,
  };
};

export default selectSortedNotificationsByDate;
