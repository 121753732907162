import { Drawer as AntdDrawer } from 'antd';
import { MobileContainer } from '@/shared/components/mobile/MobileContainer';
import { MobileView } from '@/shared/constants';
import { useDrawer } from './hooks';
import styles from './styles.module.css';
import { DrawerProps } from './types';

export const Drawer = ({
  title,
  open = false,
  onClose,
  destroyOnClose = true,
  footer,
  mobileClassName,
  children,
  ...restDrawerProps
}: DrawerProps) => {
  const { isMobile, isTablet } = useDrawer({ open, onClose });

  return isMobile ? (
    <MobileContainer
      viewType={MobileView.SecondaryScreen}
      isOpen={Boolean(open)}
      onClose={onClose}
      title={title}
      footer={footer}
      shouldDestroyOnClose={destroyOnClose}
      shouldSynchronizeWithModalQueryParam
      className={mobileClassName}
    >
      {children}
    </MobileContainer>
  ) : (
    <AntdDrawer
      placement="right"
      open={open}
      onClose={onClose}
      rootClassName={styles.drawer}
      classNames={{ header: styles.header, footer: styles.footer }}
      title={title}
      width={isTablet ? '400px' : '500px'}
      footer={footer}
      destroyOnClose={destroyOnClose}
      data-drawer
      {...restDrawerProps}
    >
      {children}
    </AntdDrawer>
  );
};
