import { z } from 'zod';
import { responseDataSchema } from '@/shared/validationSchemas';

export const notificationVideoSchema = z.object({
  url: z.string(),
  mime: z.string(),
});

const notificationImageFormatSchema = z.object({
  url: z.string(),
  width: z.number(),
  height: z.number(),
});

export const notificationImageSchema = z.object({
  url: z.string(),
  mime: z.string(),
  width: z.number(),
  height: z.number(),
  formats: z
    .object({
      large: notificationImageFormatSchema.nullish(),
      medium: notificationImageFormatSchema.nullish(),
      small: notificationImageFormatSchema.nullish(),
      thumbnail: notificationImageFormatSchema.nullish(),
    })
    .nullish(),
});

export const notificationSchema = z.object({
  documentId: z.string(),
  title: z.string(),
  date: z.string().nullish(),
  topic: z.string().nullish(),
  shortDescription: z.string().nullish(),
  fullDescription: z.string().nullish(),
  button: z
    .object({
      text: z.string(),
      link: z.string(),
      isExternal: z.boolean(),
    })
    .nullish(),
  media: notificationImageSchema.or(notificationVideoSchema).or(z.null()),
});

export const getNotificationsSchema = responseDataSchema(z.array(notificationSchema));
