import { z } from 'zod';
import {
  transcriptionFiltersSchema,
  responseDataSchema,
  paginatedWithFoffsetSchema,
  latLngCoordinatesSchema,
  transcriptTagSchema,
  transcriptCategorySchema,
  transcriptLocationSchema,
  transcriptFactionSchema,
  transcriptCodeSchema,
  transcriptCallSignSchema,
  transcriptSourceSchema,
  transcriptFrequencySchema,
  transcriptNetworkSchema,
  sourceTypeSchema,
  transcriptTagSchemaV1,
  transcriptFactionSchemaV1,
} from '@/shared/validationSchemas';

const transcriptionMetadataSchema = z.object({
  frequency: z.string().nullish(),
  coordinates: z.array(latLngCoordinatesSchema),
});

const baseTranscriptionSchema = z.object({
  id: z.string(),
  frequencyId: z.string().nullish(),
  networkId: z.string().nullish(),
  networkName: z.string().nullish(),
  group: z.string().nullish(),
  date_created: z.string(),
  timestamp_created: z.number(),
  source_id: z.number().nullish(),
  source_name: z.string().nullish(),
  source_type: sourceTypeSchema.nullish(),
  creator_name: z.string().nullish(),
  original_message: z.string(),
  clean_message: z.string().nullish(),
  metadata: transcriptionMetadataSchema,
  tags: z.array(transcriptTagSchemaV1),
  categories: z.array(transcriptCategorySchema),
  locations: z.array(transcriptLocationSchema),
  factions: z.array(transcriptFactionSchemaV1),
  codes: z.array(transcriptCodeSchema),
  callsigns: z.array(transcriptCallSignSchema),
  is_duplicate: z.boolean(),
  is_attack: z.boolean(),
});

type Transcription = z.infer<typeof baseTranscriptionSchema> & {
  original_transcript: Transcription | null;
};

// attach entities endpoints still use this transcription response (V1 api)
export const transcriptionSchemaV1: z.ZodType<Transcription> = baseTranscriptionSchema.and(
  z.object({
    original_transcript: z.lazy(() => transcriptionSchemaV1).nullable(),
  })
);

// (V3 api)
export const transcriptionSchema = z.object({
  id: z.string(),
  type: z.literal('transcript'),
  frequencyId: z.string().nullish(),
  networkId: z.string().nullish(),
  networkName: z.string().nullish(),
  timestamp: z.number(),
  sourceId: z.number().nullish(),
  sourceName: z.string().nullish(),
  sourceType: sourceTypeSchema.nullish(),
  creatorName: z.string().nullish(),
  message: z.string(),
  metadata: transcriptionMetadataSchema,
  tags: z.array(transcriptTagSchema),
  categories: z.array(transcriptCategorySchema),
  locations: z.array(transcriptLocationSchema),
  factions: z.array(transcriptFactionSchema),
  codes: z.array(transcriptCodeSchema),
  callsigns: z.array(transcriptCallSignSchema),
});

const transcriptIdsSchema = z.object({ transcriptIds: z.array(z.string()) });

const clusterMetadataSchema = z.object({
  sources: z.array(transcriptSourceSchema),
  frequencies: z.array(transcriptFrequencySchema.and(transcriptIdsSchema)),
  networks: z.array(transcriptNetworkSchema.and(transcriptIdsSchema)),
  factions: z.array(transcriptFactionSchema.and(transcriptIdsSchema)),
  coordinates: z.array(latLngCoordinatesSchema.and(transcriptIdsSchema)),
  locations: z.array(transcriptLocationSchema.and(transcriptIdsSchema)),
  categories: z.array(transcriptCategorySchema.and(transcriptIdsSchema)),
});

export const clusterSchema = z.object({
  id: z.string(),
  type: z.literal('cluster'),
  metadata: clusterMetadataSchema,
  transcript: transcriptionSchema,
});

const transcriptsListItemSchema = z.discriminatedUnion('type', [transcriptionSchema, clusterSchema]);

export const getTranscriptsFilterSchema = responseDataSchema(transcriptionFiltersSchema);
export const getTranscriptsListSchema = paginatedWithFoffsetSchema(transcriptsListItemSchema).and(
  z.object({
    clusterIncluded: z.array(z.string()).optional(),
    lastItemTimestamp: z.number().nullish(),
    score: z.number().nullish(),
  })
);
export const getTranscriptSchema = responseDataSchema(transcriptionSchema);
export const updateTranscriptSchema = responseDataSchema(transcriptionSchema);
