import { SpectrogramProps, ZoomData } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  zoomData: ZoomData;
  oneStepZoomPercent?: number;
}

const getOneStepInData = ({ zoomData, startFrequency, endFrequency, oneStepZoomPercent = 10 }: Params) => {
  const currentStartFrequency = zoomData?.startZoomFrequency ?? startFrequency;
  const currentEndFrequency = zoomData?.endZoomFrequency ?? endFrequency;
  const oneStepFrequencies = ((currentEndFrequency - currentStartFrequency) / 100) * oneStepZoomPercent;
  const zoomOneStepInStartFrequency = getRoundedFloat(currentStartFrequency + oneStepFrequencies);
  const zoomOneStepInEndFrequency = getRoundedFloat(currentEndFrequency - oneStepFrequencies);
  const isRestrictedZoomLevel = zoomOneStepInEndFrequency - zoomOneStepInStartFrequency < 1;
  const frequenciesSelectedBoundaries = {
    startSelectedFrequency: zoomOneStepInStartFrequency,
    endSelectedFrequency: isRestrictedZoomLevel ? zoomOneStepInStartFrequency + 0.01 : zoomOneStepInEndFrequency,
  };

  return {
    isRestrictedZoomLevel,
    frequenciesSelectedBoundaries,
  };
};

export default getOneStepInData;
