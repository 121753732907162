import { useCallback, useMemo } from 'react';
import { OnPlayPauseParams } from '@/entities/source/@fox/components/RecordsTab/types';
import {
  setIsRecordPlaying,
  selectIsRecordPlaying,
  selectSelectedRecordId,
  setSelectedRecordId,
} from '@/entities/source/slices';
import { useAppSelector, useAppDispatch, useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { getAudioUrl } from '../utils';
import useAudioPlayerStorageSettings from './useAudioPlayerStorageSettings';
import useAutoplay from './useAutoplay';
import useIsSelectedRecordDecoded from './useIsSelectedRecordDecoded';
import useNavigateToSiblingRecord from './useNavigateToSiblingRecord';
import useSelectedRecord from './useSelectedRecord';

const useAudioPlayer = () => {
  const dispatch = useAppDispatch();
  const isRecordPlaying = useAppSelector(selectIsRecordPlaying);
  const selectedRecordId = useAppSelector(selectSelectedRecordId);
  const selectedRecord = useSelectedRecord();
  const { tabEntityId } = useParams<SourceRouteParams>();
  const isRecordSelected = selectedRecordId === tabEntityId;
  const { memoNavigateToPreviousRecord, memoNavigateToNextRecord } = useNavigateToSiblingRecord();
  const isSelectedRecordDecoded = useIsSelectedRecordDecoded();

  const { settings, memoSaveSettings } = useAudioPlayerStorageSettings();

  const { memoPlayNextRecord } = useAutoplay({
    isSkipSilenceEnabled: !!settings.isSkipSilenceEnabled,
    isAutoplayEnabled: !!settings.autoplay,
  });

  const memoHandlePlayPause = useCallback(
    ({ isPlaying, shouldPlaySelectedRecord }: OnPlayPauseParams) => {
      if (tabEntityId && ((!selectedRecordId && isPlaying) || shouldPlaySelectedRecord)) {
        dispatch(setSelectedRecordId(tabEntityId));
      }

      dispatch(setIsRecordPlaying(isPlaying));
    },
    [dispatch, selectedRecordId, tabEntityId]
  );

  const stringifiedPeaks = useMemo(() => {
    return selectedRecord?.waveform?.peaks?.join();
  }, [selectedRecord]);

  return {
    recordUrl: selectedRecord?.metadata.isDecoded ? getAudioUrl(selectedRecord.id) : undefined,
    recordDuration: selectedRecord?.waveform?.duration ? selectedRecord.waveform.duration / 1000 : undefined,
    isSelectedRecordDecoded,
    stringifiedPeaks,
    recordHasVoice: selectedRecord?.metadata.hasVoice,
    recordInterceptedAt: selectedRecord?.metadata.interceptedAt,
    isRecordSelected,
    isPlaying: isRecordPlaying,
    settings,
    memoHandlePlayPause,
    memoSaveSettings,
    memoPlayNextRecord,
    memoNavigateToNextRecord,
    memoNavigateToPreviousRecord,
  };
};

export default useAudioPlayer;
