import { useMemo } from 'react';
import { GetNotificationsQueryParams } from '@/entities/notification/api/types';
import { RoleName } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks';
import { selectCurrentUserRole } from '@/shared/slices';

const useGetNotificationsQueryParams = () => {
  const role = useAppSelector(selectCurrentUserRole);

  return useMemo((): GetNotificationsQueryParams => {
    if (role === RoleName.ADMIN) {
      return { status: 'draft' };
    }

    return { status: 'published' };
  }, [role]);
};

export default useGetNotificationsQueryParams;
