// render progressBar

export const renderFunction = (_: (Float32Array | number[])[], ctx: CanvasRenderingContext2D) => {
  const { width } = ctx.canvas;
  const pixelRatio = window.devicePixelRatio || 1;

  ctx.rect(0, 8 * pixelRatio, width, 2 * pixelRatio);

  ctx.fill();
  ctx.closePath();
};

export default renderFunction;
