import { timeService } from '@/shared/services';
import { DateProps } from '../types';

interface Params extends Pick<DateProps, 'date'> {}

const useDate = ({ date }: Params) => {
  const formattedDate = date ? timeService.getFormattedMiddleMonthWithDotes(date) : null;

  return {
    formattedDate,
  };
};

export default useDate;
