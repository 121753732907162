import { z } from 'zod';
import { FoxFrequencyEncryptionType, FoxRecordSignalType } from '@/entities/source/@fox/constants';
import { paginatedWithFoffsetSchema, responseDataSchema } from '@/shared/validationSchemas';

export const fileMetadataSchema = z.object({
  frequency: z.string(),
  interceptedAt: z.number(),
  signalType: z.nativeEnum(FoxRecordSignalType),
  hasVoice: z.boolean(),
  encryptionType: z.string().nullish(),
  keyId: z.string().nullish(),
  sourceAddress: z.string().nullish(),
  groupAddress: z.string().nullish(),
  colorCode: z.string().nullish(),
  lat: z.string().nullish(),
  lng: z.string().nullish(),
  connectionType: z.string().nullish(),
  featureSet: z.string().nullish(),
  gpsErrorRadiusM: z.number().nullish(),
  isDecoded: z.boolean(),
});

export const fileSchema = z.object({
  id: z.string(),
  fileName: z.string(),
  sourceId: z.string(),
  contentType: z.string(),
  createdAt: z.number(),
  metadata: fileMetadataSchema,
  waveform: z
    .object({
      peaks: z.array(z.number()).optional(),
      pixelsPerSec: z.number().optional(),
      duration: z.number().optional(),
    })
    .nullish(),
});

export const fileFiltersSchema = z.object({
  encryptionTypes: z.array(z.nativeEnum(FoxFrequencyEncryptionType)),
  frequencies: z.array(z.string()),
  sourceAddresses: z.array(z.string()),
  groupAddresses: z.array(z.string()),
});

export const getFilesSchema = paginatedWithFoffsetSchema(fileSchema);

export const getFileFiltersSchema = responseDataSchema(fileFiltersSchema);

export const downloadFileSchema = z.instanceof(Blob);

export const markVoiceExistSchema = responseDataSchema(z.object({}));

export const markVoiceSkipSchema = responseDataSchema(z.object({}));
