import { FC } from 'react';
import { Button } from 'antd';
import { useBlurButtonOnClick } from '@/entities/source/@fox/components/RecordsTab/components/AudioPlayer/hooks';
import { PlayIcon, PauseIcon } from '@/shared/assets';
import { Tooltip } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  isPlaying: boolean;
  playPause: VoidFunction;
}

const TOOLTIP_TITLE = 'Відтворити аудіо (Пробіл)';

const ControlCheckbox: FC<Props> = ({ isPlaying, playPause }) => {
  const { buttonRef, blurButtonOnClick: handleClick } = useBlurButtonOnClick({ onClick: playPause });

  return (
    <Tooltip
      isDesktopOnly
      isOneLine
      title={TOOLTIP_TITLE}
    >
      <div className={styles.btn}>
        <Button
          ref={buttonRef}
          icon={isPlaying ? <PauseIcon className={styles.icon} /> : <PlayIcon className={styles.icon} />}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={handleClick}
        />
      </div>
    </Tooltip>
  );
};

export default ControlCheckbox;
