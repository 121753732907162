import { TopicsProps } from '../types';

interface Params extends Pick<TopicsProps, 'topics'> {}

const useTopics = ({ topics }: Params) => {
  const parsedTopics = topics ? topics.split(', ') : null;

  return {
    parsedTopics,
  };
};

export default useTopics;
