import { Components } from 'react-markdown';
import { BodyProps } from '../types';

interface Params extends Pick<BodyProps, 'fullDescription'> {}

const useBody = ({ fullDescription }: Params) => {
  const isLongBody = !!fullDescription;

  const components: Components = {
    a: ({ href, children }) => {
      if (typeof children === 'string' && children?.endsWith('.mp4')) {
        return (
          <video
            controls
            controlsList="nodownload"
            preload="none"
          >
            <source
              src={href}
              type="video/mp4"
            />
          </video>
        );
      }
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  };

  return {
    isLongBody,
    components,
  };
};

export default useBody;
