import { useMemo } from 'react';
import {
  useGetNotifications,
  useGetNotificationsQueryParams,
  useSeenNotifications,
} from '@/entities/notification/hooks';

const useAreNewNotificationsAvailable = () => {
  const seenNotifications = useSeenNotifications();
  const queryParams = useGetNotificationsQueryParams();
  const { notifications } = useGetNotifications({ queryParams, options: { enabled: false } });

  return useMemo(() => {
    for (const notification of notifications) {
      if (!Object.hasOwn(seenNotifications, notification.documentId)) {
        return true;
      }
    }

    return false;
  }, [notifications, seenNotifications]);
};

export default useAreNewNotificationsAvailable;
