import { useRenderPropsContext } from '@/shared/context';
import { SourceRenderProps } from '@/shared/types';
import useSpectrogramData from './useSpectrogramData';
import useSpectrogramHandlers from './useSpectrogramHandlers';

const WATERMARK_COLOR = 'rgba(139, 139, 139, 0.5)';

interface Params {
  isSpectrogramEnabled: boolean;
}

const useSpectrogramController = ({ isSpectrogramEnabled }: Params) => {
  const spectrogramData = useSpectrogramData({ isSpectrogramEnabled });
  const handlers = useSpectrogramHandlers();
  const { renderWatermark } = useRenderPropsContext<SourceRenderProps>();

  return {
    renderWatermark,
    watermarkColor: WATERMARK_COLOR,
    ...spectrogramData,
    ...handlers,
  };
};

export default useSpectrogramController;
