import { useState } from 'react';
import { useSelectedRadioNetwork } from '@/entities/network';
import { useTranscriptQueryParams, useTranscriptsFilter } from '@/entities/transcript';
import { RadioNetworkType } from '@/shared/constants';

const DRAWER_FILTER_TITLE = {
  [RadioNetworkType.NETWORK]: 'Фільтр в мережі',
  [RadioNetworkType.FREQUENCY]: 'Фільтр в частоті',
};

const useFiltersDrawer = () => {
  const { selectedRadioNetwork } = useSelectedRadioNetwork();
  const { search } = useTranscriptQueryParams();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const handleFilterButtonClick = () => setIsFiltersVisible(true);

  const filtersDrawerTitle = selectedRadioNetwork ? DRAWER_FILTER_TITLE[selectedRadioNetwork.type] : undefined;

  const { transcriptFilters, isTranscriptFiltersLoading } = useTranscriptsFilter({
    enabled: search.length !== 1,
  });

  return {
    isFiltersVisible,
    setIsFiltersVisible,
    handleFilterButtonClick,
    filtersDrawerTitle,
    transcriptFilters,
    isTranscriptFiltersLoading,
  };
};

export default useFiltersDrawer;
