import { CALL_SIGN_ENTITY_SELECTOR, UNDERLINED_ENTITY_SELECTOR } from '@/entities/transcript/constants';

type Response = {
  entityElement: HTMLElement | null;
  isCodeTarget: boolean;
};

export const getEntityElement = (target: HTMLElement): Response => {
  const underlinedEntity = target.closest<HTMLElement>(UNDERLINED_ENTITY_SELECTOR);
  const callSignEntity = target.closest<HTMLElement>(CALL_SIGN_ENTITY_SELECTOR);
  const code = target.closest<HTMLElement>('code');

  // code is not interactive
  if (code) {
    return {
      entityElement: null,
      isCodeTarget: true,
    };
  }

  // call sign has the highest priority
  return {
    entityElement: callSignEntity ?? underlinedEntity,
    isCodeTarget: false,
  };
};
