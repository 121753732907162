import { TranscriptSourcesProps } from '@/entities/transcript/types';
import { Tabs } from '@/shared/components';
import { TranscriptSource } from './components';
import { useTabs } from './hooks';
import styles from './styles.module.css';

const TranscriptSources = ({ clusterId, clusterMetadata, transcript }: TranscriptSourcesProps) => {
  const { tabs, activeTab, handleTabChange } = useTabs({ clusterId, clusterMetadata, transcript });

  return clusterMetadata ? (
    <Tabs
      className={styles.tabs}
      tabBarGutter={12}
      activeKey={activeTab}
      items={tabs}
      onChange={handleTabChange}
    />
  ) : (
    <TranscriptSource
      sourceId={transcript.sourceId}
      sourceName={transcript.sourceName}
      sourceType={transcript.sourceType}
      creatorName={transcript.creatorName}
      isNetwork={Boolean(transcript.networkId)}
    />
  );
};

export default TranscriptSources;
