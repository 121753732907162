import { Transcription } from '@/entities/transcript/types';
import { LatLngCoordinates, TranscriptLocationsProps } from '@/shared/types';
import useLocationsCoordinatesTags from './useLocationsCoordinatesTags';

interface Props extends Pick<TranscriptLocationsProps, 'locations' | 'isCluster'> {
  transcript: Transcription;
  coordinates: LatLngCoordinates[];
}

const LocationsCoordinatesTags = ({ transcript, locations, coordinates, isCluster }: Props) => {
  const { handleCoordinatesClick, handleLocationClick, renderTranscriptCoordinates, renderTranscriptLocations } =
    useLocationsCoordinatesTags(transcript);

  return (
    <>
      {renderTranscriptCoordinates({
        transcriptId: transcript.id,
        coordinates,
        onItemClick: handleCoordinatesClick,
        isCluster,
      })}
      {renderTranscriptLocations({
        transcriptId: transcript.id,
        locations,
        onItemClick: handleLocationClick,
        isCluster,
      })}
    </>
  );
};

export default LocationsCoordinatesTags;
