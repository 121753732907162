import { useDebounceFn } from '@reactuses/core';
import {
  selectCallingElementPosition,
  setCallingElementPosition,
  setMarkerCoordinates,
  setMarkerType,
  resetModalMapState,
} from '@/entities/map/slices';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { LatLngCoordinates, Location } from '@/shared/types';

interface Params {
  coords?: LatLngCoordinates | null;
  locations: Location[];
  selectDropdownClassName: string;
}

const DEBOUNCE_SHOW_MODAL_MAP = 1000;

const useModalMapVisibilityController = ({ coords, locations, selectDropdownClassName }: Params) => {
  const dispatch = useAppDispatch();
  const modalMapCallingElementPosition = useAppSelector(selectCallingElementPosition);

  const { run: showModalMap, cancel: cancelShowModalMap } = useDebounceFn((optionValue?: string) => {
    const selectDropdownDomRect = document.querySelector(`.${selectDropdownClassName}`)?.getBoundingClientRect();
    const hoveredOption = optionValue ? locations.find((location) => location.id === optionValue) : undefined;
    const coordinates = coords || hoveredOption?.coordinates;

    if (!selectDropdownDomRect || !coordinates) return;

    dispatch(
      setCallingElementPosition({
        top: selectDropdownDomRect.top,
        left: selectDropdownDomRect.left,
        right: selectDropdownDomRect.right,
      })
    );
    dispatch(setMarkerCoordinates(coordinates));
    dispatch(setMarkerType(coords ? 'point' : 'location'));
  }, DEBOUNCE_SHOW_MODAL_MAP);

  const hideModalMap = () => {
    cancelShowModalMap();
    if (!modalMapCallingElementPosition) return;

    dispatch(resetModalMapState());
  };

  return {
    showModalMap,
    hideModalMap,
  };
};

export default useModalMapVisibilityController;
