import { ScrollUpToRefresh, Table, LoadingWrapper } from '@/shared/components';
import { useRecordsTable } from './hooks';
import styles from './styles.module.css';

const RecordsTable = () => {
  const {
    filesList,
    columns,
    isFilesFetching,
    handleScrollUpToReFetch,
    handleScroll,
    handleRow,
    rowClassName,
    scrollToPosition,
  } = useRecordsTable();

  return (
    <LoadingWrapper
      isLoading={isFilesFetching}
      isFixedOverlay
    >
      <ScrollUpToRefresh
        onRefresh={handleScrollUpToReFetch}
        isScrollUpToRefreshEnabled={filesList.length > 0}
        onScroll={handleScroll}
      >
        <Table
          scroll={scrollToPosition}
          rowKey="id"
          className={styles.table}
          dataSource={filesList}
          columns={columns}
          pagination={false}
          onRow={handleRow}
          rowClassName={rowClassName}
        />
      </ScrollUpToRefresh>
    </LoadingWrapper>
  );
};

export default RecordsTable;
