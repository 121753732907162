import { Transcription } from '@/entities/transcript/types';

const getTranscriptSourceNameTooltipTitle = (sourceType: Transcription['sourceType']) => {
  switch (sourceType) {
    case 'af':
      return 'Airforce';
    case 'bilka':
      return 'Commintify';
    case 'sailor-moon':
      return 'Hawkeye';
    case 'signal':
      return 'сигнал група';
    case 'vuho':
      return 'Vuho';
    case 'whatsapp':
      return 'Whatsapp чат';
    default:
      return null;
  }
};

export default getTranscriptSourceNameTooltipTitle;
