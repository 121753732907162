import { useInterval } from '@reactuses/core';
import { QueryKey } from '@tanstack/react-query';
import { MIN_REFETCH_INTERVAL, DISABLED_REFETCH_SCROLL_TOP } from '@/shared/constants';
import useClearInfiniteListAndRefetch from './useClearInfiniteListAndRefetch';

interface Params {
  queryKey: QueryKey;
  scrollTopPosition: number;
  refetchTopArea?: number;
  refetchInterval?: number;
  isRefetchEnabled?: boolean;
}
const useReFetchInfiniteListOnTop = ({
  queryKey,
  scrollTopPosition,
  refetchTopArea = DISABLED_REFETCH_SCROLL_TOP,
  refetchInterval = MIN_REFETCH_INTERVAL,
  isRefetchEnabled = true,
}: Params) => {
  const handleClearInfiniteListAndRefetch = useClearInfiniteListAndRefetch(queryKey);

  useInterval(
    () => {
      if (scrollTopPosition < refetchTopArea) {
        handleClearInfiniteListAndRefetch();
      }
    },
    isRefetchEnabled ? refetchInterval : null
  );
};

export default useReFetchInfiniteListOnTop;
