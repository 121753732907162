import classNames from 'classnames';
import { Button } from '@/shared/components';
import { usePlayButton } from './hooks';
import styles from './styles.module.css';

interface Props {
  id: string;
  className?: string;
}

const PlayButton = ({ id, className }: Props) => {
  const { handlePlay, getIcon, buttonRef, handleFocus } = usePlayButton();

  return (
    <Button
      ref={buttonRef}
      onClick={handlePlay(id)}
      icon={getIcon(id)}
      type="text"
      className={classNames(styles.playBtn, className)}
      onFocus={handleFocus}
    />
  );
};

export default PlayButton;
