import { useCallback, useEffect, useRef, useState, RefObject } from 'react';
import { useThrottleFn } from '@reactuses/core';
import { THROTTLE_DEFAULT } from '@/shared/constants';
import { useAppDispatch, useAppSelector, useLocalStorageForLimitedDays } from '@/shared/hooks';
import {
  selectIsNavigationSidebarCollapsed,
  setIsLayoutSidebarRightInDrawer,
  setIsNavigationSidebarCollapsed,
} from '@/shared/slices';
import { getLayoutSidebarLocalStorageKey } from '@/shared/utils';
import { LayoutDesktopSidebarProps } from '../types';
import useResizeRightSidebarOnWindowSizeChange from './useResizeRightSidebarOnWindowSizeChange';

const SIDEBAR_MIN_WIDTH = 240;
const SIDEBAR_MAX_WIDTH = 600;
const SIDEBAR_DEFAULT_WIDTH = 400;

interface Params extends Omit<LayoutDesktopSidebarProps, 'layoutSidebarClassName' | 'resizerClassName'> {}

interface UseLayoutDesktopSidebar {
  sidebarRef: RefObject<HTMLDivElement>;
  sidebarWidth: number | string;
  startResizing: VoidFunction;
}

export const useLayoutDesktopSidebar = ({
  layoutRef,
  storageKey,
  sidebarMinWidth = SIDEBAR_MIN_WIDTH,
  sidebarMaxWidth = SIDEBAR_MAX_WIDTH,
  sidebarDefaultWidth = SIDEBAR_DEFAULT_WIDTH,
  forceResizeSidebarToWidth,
  onResizeFinish,
  onResizeStart,
  resizerPosition = 'right',
}: Params): UseLayoutDesktopSidebar => {
  const dispatch = useAppDispatch();
  const isNavigationSidebarCollapsed = useAppSelector(selectIsNavigationSidebarCollapsed);
  const { getStorageValue, setStorageValue } = useLocalStorageForLimitedDays<number | string>(
    getLayoutSidebarLocalStorageKey(storageKey)
  );
  const persistedSidebarWidth = getStorageValue();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const sidebarDefWidth = persistedSidebarWidth ?? sidebarDefaultWidth;
  const [sidebarWidth, setSidebarWidth] = useState<number | string>(sidebarDefWidth);
  const { run: throttledSetSidebarWidth } = useThrottleFn(setSidebarWidth, THROTTLE_DEFAULT);

  const { forcedSidebarMaxWidth } = useResizeRightSidebarOnWindowSizeChange({
    layoutRef,
    sidebarMinWidth,
    sidebarMaxWidth,
    sidebarWidth,
    setSidebarWidth,
    resizerPosition,
  });

  const startResizing = () => {
    setIsResizing(true);
    document.body.style.userSelect = 'none';
    if (onResizeStart) onResizeStart();
  };

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    document.body.style.userSelect = 'initial';
    if (onResizeFinish) onResizeFinish();
  }, [onResizeFinish]);

  const handleResize = useCallback(
    (width: number | string) => {
      if (
        resizerPosition === 'right' &&
        typeof width === 'number' &&
        width < sidebarMinWidth &&
        !isNavigationSidebarCollapsed
      ) {
        dispatch(setIsNavigationSidebarCollapsed(true));
      }
      if (resizerPosition === 'left' && typeof width === 'number' && width > forcedSidebarMaxWidth && !isResizing) {
        dispatch(setIsLayoutSidebarRightInDrawer(true));
      }
      if (typeof width === 'string' || (width <= forcedSidebarMaxWidth && width >= sidebarMinWidth)) {
        throttledSetSidebarWidth(width);
      }
    },
    [dispatch, isNavigationSidebarCollapsed, setStorageValue, forcedSidebarMaxWidth, sidebarMinWidth, isResizing]
  );

  const handleMove = useCallback(
    (moveEvent: MouseEvent | TouchEvent) => {
      if (!isResizing || !sidebarRef.current) return;

      const clientX = moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX;
      const calculatedWidth =
        resizerPosition === 'right'
          ? clientX - sidebarRef.current.getBoundingClientRect().left
          : sidebarRef.current.getBoundingClientRect().right - clientX;
      handleResize(calculatedWidth);
    },
    [isResizing, handleResize]
  );

  useEffect(() => {
    if (persistedSidebarWidth === sidebarWidth) return;

    setStorageValue(sidebarWidth);
  }, [persistedSidebarWidth, setStorageValue, sidebarWidth]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', stopResizing);
      window.addEventListener('touchmove', handleMove);
      window.addEventListener('touchend', stopResizing);
    }

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', stopResizing);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', stopResizing);
    };
  }, [isResizing, handleMove, stopResizing]);

  useEffect(() => {
    if (forceResizeSidebarToWidth) {
      handleResize(forceResizeSidebarToWidth);
    }
  }, [forceResizeSidebarToWidth, handleResize]);

  return {
    sidebarRef,
    sidebarWidth,
    startResizing,
  };
};
