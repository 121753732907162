import { useEffect, useState } from 'react';
import { useFoxRecordsQueryParams } from '@/entities/source/@fox/components/RecordsTab/hooks';
import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useScrollTopOnQueryParamsChange = () => {
  const [search] = useSearchParam(FoxRecordsQueryParam.Search);
  const queryParams = useFoxRecordsQueryParams();
  const [scrollToPosition, setScrollToPosition] = useState<{ x: number; y: number } | undefined>();

  useEffect(() => {
    setScrollToPosition({
      x: 0,
      y: 0,
    });
  }, [queryParams, search]);

  useEffect(() => {
    if (!scrollToPosition) return;

    setScrollToPosition(undefined);
  }, [scrollToPosition]);

  return {
    scrollToPosition,
  };
};

export default useScrollTopOnQueryParamsChange;
