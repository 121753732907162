import { useMemo, useCallback } from 'react';
import { LocalStorageService } from '@/shared/services';

export const useLocalStorage = <T>(key: string) => {
  const localStorageServiceInstance = useMemo(() => {
    return new LocalStorageService<T>(key);
  }, [key]);

  const getStorageValue = useCallback(() => {
    return localStorageServiceInstance.getStorageValue();
  }, [localStorageServiceInstance]);

  const setStorageValue = useCallback(
    (value: T) => {
      return localStorageServiceInstance.setStorageValue(value);
    },
    [localStorageServiceInstance]
  );

  const removeStorageValue = useCallback(() => {
    return localStorageServiceInstance.removeStorageValue();
  }, [localStorageServiceInstance]);

  return {
    getStorageValue,
    setStorageValue,
    removeStorageValue,
  };
};
