import { useEffect, useRef, useState } from 'react';

const useVideo = () => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [videoWrapperWidth, setVideoWrapperWidth] = useState<number | null>(null);

  const videoWidth = videoWrapperWidth ?? 0;
  const videoHeight = window.innerHeight / 3;
  const shouldVideoBeRendered = !!videoWidth && !!videoHeight;

  useEffect(() => {
    if (!videoWrapperRef.current?.clientWidth) return;

    setVideoWrapperWidth(videoWrapperRef.current?.clientWidth);
  }, []);

  return {
    videoWrapperRef,
    shouldVideoBeRendered,
    videoWidth,
    videoHeight,
  };
};

export default useVideo;
