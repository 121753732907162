import { useEffect } from 'react';
import { DrawerProps } from 'antd';
import { Drawer } from '@/shared/components/ui/Drawer';
import { useAppDispatch, useIsMobile } from '@/shared/hooks';
import { setNavigationDrawer } from '@/shared/slices';

interface Props extends DrawerProps {
  mobileClassName?: string;
}

const NavigationDrawer = ({ children, onClose, mobileClassName, ...restDrawerProps }: Props) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(setNavigationDrawer(null));

  useEffect(() => {
    if (isMobile) {
      handleClose();
    }
  }, [isMobile]);

  return (
    <Drawer
      {...restDrawerProps}
      mobileClassName={mobileClassName}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
};

export default NavigationDrawer;
