import maplibregl from 'maplibre-gl';
import { MAP_COLORS } from '@/entities/map/constants';
import { useMapContext } from '@/entities/map/context';
import { useMapEffect } from '@/entities/map/hooks';
import { selectMarkerCoordinates, selectMarkerType } from '@/entities/map/slices';
import { useAppSelector } from '@/shared/hooks';

const useMarkerPosition = () => {
  const position = useAppSelector(selectMarkerCoordinates);
  const type = useAppSelector(selectMarkerType);
  const { map } = useMapContext();

  useMapEffect(() => {
    if (!position) return;

    map.jumpTo({
      center: position,
    });

    const marker = new maplibregl.Marker({ color: type === 'point' ? MAP_COLORS.primary : MAP_COLORS.location });
    marker.setLngLat(position).addTo(map);

    return () => {
      marker.remove();
    };
  }, [map, position]);
};

export default useMarkerPosition;
