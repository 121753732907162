import { NotificationImage, NotificationVideo } from '@/entities/notification/api/types';
import { exhaustiveGuard } from '@/shared/utils';
import { Image, Video } from './compoennts';
import { useMedia } from './hooks';
import { MediaProps as Props } from './types';

const Media = ({ memoMedia }: Props) => {
  const { mediaType } = useMedia({ memoMedia });

  switch (mediaType) {
    case 'image':
      return <Image memoImage={memoMedia as NotificationImage} />;
    case 'video':
      return (
        <Video
          url={(memoMedia as NotificationVideo).url}
          mime={(memoMedia as NotificationVideo).mime}
        />
      );
    case 'none':
      return null;
    default:
      exhaustiveGuard(mediaType);
      return null;
  }
};

export default Media;
