import { useEffect } from 'react';
import { usePrevious } from '@reactuses/core';
import { AudioPlayerProps } from '../type';

interface Params extends Pick<AudioPlayerProps, 'hasVoice' | 'memoSettings' | 'memoPlayNextRecord'> {
  memoFinishRecord: () => void;
  isPlaying: boolean;
}

const useSkipRecordsWithoutVoice = ({
  hasVoice,
  memoSettings,
  memoFinishRecord,
  memoPlayNextRecord,
  isPlaying,
}: Params) => {
  const { autoplay: isAutoplayEnabled, isSkipSilenceEnabled } = memoSettings;
  const previousIsSkipSilenceEnabled = usePrevious(isSkipSilenceEnabled);

  useEffect(() => {
    const skipRecordWithoutVoice = () => {
      if (isAutoplayEnabled) {
        memoPlayNextRecord?.();
      } else {
        memoFinishRecord();
      }
    };

    if (!hasVoice && isSkipSilenceEnabled && previousIsSkipSilenceEnabled !== isSkipSilenceEnabled && isPlaying) {
      skipRecordWithoutVoice();
    }
  }, [
    hasVoice,
    isAutoplayEnabled,
    isSkipSilenceEnabled,
    memoPlayNextRecord,
    memoFinishRecord,
    isPlaying,
    previousIsSkipSilenceEnabled,
  ]);
};

export default useSkipRecordsWithoutVoice;
