import { useCallback, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ADD_SOURCE } from '@/entities/source/constants';
import { setIsCreateSourceModalOpened } from '@/entities/source/slices';
import { RoleName } from '@/shared/constants';
import { useAppDispatch, useIsUserRole } from '@/shared/hooks';

const ROLES = [RoleName.ADMIN];

const useSourcesTitleActions = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useIsUserRole({ memoRoles: ROLES });

  const handleAddSourceClick = useCallback(() => {
    dispatch(setIsCreateSourceModalOpened(true));
  }, [dispatch]);

  const items = useMemo(() => {
    if (!isAdmin) return [];

    return [
      {
        key: 'add',
        label: ADD_SOURCE,
        icon: <PlusOutlined />,
        onClick: handleAddSourceClick,
      },
    ];
  }, [handleAddSourceClick, isAdmin]);

  return {
    items,
  };
};

export default useSourcesTitleActions;
