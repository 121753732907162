import { useEffect } from 'react';

interface Params {
  isDrawerOpen: boolean;
  memoRefetch: VoidFunction;
}

const useRefetchNotificationsOnDrawerOpen = ({ isDrawerOpen, memoRefetch }: Params) => {
  useEffect(() => {
    if (!isDrawerOpen) return;

    memoRefetch();
  }, [isDrawerOpen, memoRefetch]);
};

export default useRefetchNotificationsOnDrawerOpen;
