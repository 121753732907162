import { PropsWithChildren } from 'react';
import { MapContextProvider } from '@/entities/map/context';
import { MapLayout } from './components';
import { useInitializeMap } from './hooks';
import { MapContainerProps } from './types';

const MapContainer = ({ tileSource, mapOptions, children, ...restProps }: PropsWithChildren<MapContainerProps>) => {
  const { mapContainerRefCallback, map, isMapLoaded } = useInitializeMap(mapOptions, tileSource);

  return (
    <MapContextProvider map={map}>
      <MapLayout
        mapContainerRefCallback={mapContainerRefCallback}
        map={map}
        tileSource={tileSource}
        {...restProps}
      >
        {isMapLoaded && children}
      </MapLayout>
    </MapContextProvider>
  );
};

export default MapContainer;
