import { useEffect, useRef } from 'react';

interface Params {
  callbackInterval: number;
  memoCallback: VoidFunction;
  intervalTimeout?: number;
}

// useInterval based on current time timestamp value, works even after the computer goes out of sleep mode.

const useTimestampInterval = ({ callbackInterval, memoCallback, intervalTimeout = 1000 }: Params) => {
  const callbackTime = useRef(Date.now() + callbackInterval);

  useEffect(() => {
    callbackTime.current = Date.now() + callbackInterval;
  }, [callbackInterval]);

  useEffect(() => {
    const handleInterval = () => {
      const now = Date.now();

      if (now >= callbackTime.current) {
        callbackTime.current = now + callbackInterval;
        memoCallback();
      }
    };

    const interval = setInterval(handleInterval, intervalTimeout);

    return () => clearInterval(interval);
  }, [callbackInterval, memoCallback, intervalTimeout]);
};

export default useTimestampInterval;
