import { useEffect, PropsWithChildren } from 'react';
import { Flex, Layout } from 'antd';
import SupportNotification from '@/shared/components/SupportNotification';
import { LayoutMobileView } from '@/shared/components/layouts/LayoutMobileView';
import { MobileView } from '@/shared/constants';
import { useIsMobile } from '@/shared/hooks';
import { NavigationContent, NavigationContentProps, NavigationSidebar } from './components';
import { useIsDragging, useNavigationHotkeys } from './hooks';
import styles from './styles.module.css';

interface Props {
  navigation?: NavigationContentProps;
}

export const LayoutApp = ({ children, navigation }: PropsWithChildren<Props>) => {
  const isDragging = useIsDragging();
  const isMobile = useIsMobile();

  useNavigationHotkeys();

  useEffect(() => {
    // firefox UI fix for react-dnd
    if (isDragging) {
      document.body.style.userSelect = 'none';
    }
    return () => {
      document.body.style.userSelect = 'initial';
    };
  }, [isDragging]);

  return (
    <Flex
      className={styles.layoutWrapper}
      vertical
    >
      {!isMobile && <SupportNotification />}
      <Layout className={styles.layout}>
        {isMobile ? (
          <LayoutMobileView viewType={MobileView.Menu}>
            <NavigationContent {...navigation} />
          </LayoutMobileView>
        ) : (
          <NavigationSidebar>
            <NavigationContent {...navigation} />
          </NavigationSidebar>
        )}
        {children}
      </Layout>
    </Flex>
  );
};
