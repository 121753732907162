import { useMemo } from 'react';
import { selectSelectedRecordId } from '@/entities/source/slices';
import { useAppSelector } from '@/shared/hooks';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';
import useGetFiles from './useGetFiles';

const useSelectedRecord = () => {
  const queryParams = useFoxRecordsQueryParams();
  const selectedRecordId = useAppSelector(selectSelectedRecordId);
  const { filesList } = useGetFiles({
    options: {
      enabled: false,
    },
    queryParams,
  });

  return useMemo(() => filesList.find((file) => file.id === selectedRecordId), [selectedRecordId, filesList]);
};

export default useSelectedRecord;
