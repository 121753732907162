import { MouseEventHandler, UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce, usePrevious } from '@reactuses/core';
import { useIsFrequencyDragging } from '@/entities/frequency';
import {
  useGetRadioNetworks,
  selectIsNetworkListUpdating,
  selectScrollToNetworkId,
  setScrollToNetworkId,
  useNetworkQueryParams,
  useSelectedRadioNetwork,
} from '@/entities/network';
import {
  MAX_REFETCH_INTERVAL,
  NETWORKS_QUERY_KEY,
  DEBOUNCE_DEFAULT,
  DISABLED_REFETCH_SCROLL_TOP,
} from '@/shared/constants';
import {
  useAppDispatch,
  useAppSelector,
  useDeleteInfinitePagesOnQueryChange,
  useClearInfiniteListAndRefetch,
  useConditionEffectOnce,
} from '@/shared/hooks';

let scrollUpToReFetchTimeoutID: NodeJS.Timeout | undefined;

const useNetworkListScrolling = () => {
  const { queryParams } = useNetworkQueryParams();
  const [scrollNetworkListDirection, setScrollNetworkListDirection] = useState<'up' | 'down' | null>(null);
  const [refetchInterval, setRefetchInterval] = useState<number>(MAX_REFETCH_INTERVAL);
  const [scrollToRowIndex, setScrollToRowIndex] = useState(-1);
  const scrollToNetworkId = useAppSelector(selectScrollToNetworkId);
  const isNetworkListUpdating = useAppSelector(selectIsNetworkListUpdating);
  const isFrequencyDragging = useIsFrequencyDragging();
  const { selectedRadioNetwork } = useSelectedRadioNetwork();

  const prevMouseYRef = useRef<number | null>(null);
  const networkListWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const scrollNetworkListTop = () => networkListWrapperRef.current?.scrollTo({ top: 0 });

  useDeleteInfinitePagesOnQueryChange({
    query: queryParams,
    queryKey: [NETWORKS_QUERY_KEY, queryParams],
    onDeleteInfiniteCache: scrollNetworkListTop,
  });

  const debouncedRefetchInterval = useDebounce(refetchInterval, DEBOUNCE_DEFAULT, { leading: true });
  const { refetch, remove, isNetworksLoading, isNetworksFetching, networks } = useGetRadioNetworks({
    queryParams,
    refetchInterval: debouncedRefetchInterval,
    isFrequencyDragging,
  });
  const previousIsNetworksFetching = usePrevious(isNetworksFetching);

  const handleClearInfiniteListAndRefetch = useClearInfiniteListAndRefetch([NETWORKS_QUERY_KEY, queryParams]);
  const handleListScroll = ({ currentTarget: { scrollTop } }: UIEvent<HTMLDivElement>) => {
    clearTimeout(scrollUpToReFetchTimeoutID);
    if (isFrequencyDragging || isNetworkListUpdating) return;
    if (scrollTop >= DISABLED_REFETCH_SCROLL_TOP && debouncedRefetchInterval) {
      setRefetchInterval(0);
    } else if (scrollTop < DISABLED_REFETCH_SCROLL_TOP && !debouncedRefetchInterval) {
      setRefetchInterval(MAX_REFETCH_INTERVAL);
      scrollUpToReFetchTimeoutID = setTimeout(async () => {
        await handleClearInfiniteListAndRefetch();
      }, MAX_REFETCH_INTERVAL);
    }
  };

  const stopNetworkListScrolling = useCallback(() => {
    if (scrollNetworkListDirection) {
      setScrollNetworkListDirection(null);
      prevMouseYRef.current = null;
    }
  }, [scrollNetworkListDirection]);

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isFrequencyDragging || !networkListWrapperRef.current) return;
    const viewportHeight = window.innerHeight;
    const y = event.clientY;
    const isCursorNearBottomOfNetworkList = viewportHeight - y <= 100;
    const isCursorNearTopOfNetworkList = y - (viewportHeight - networkListWrapperRef.current.clientHeight) <= 100;
    const isMovingUp = prevMouseYRef.current && y - prevMouseYRef.current < 0;
    const isMovingDown = prevMouseYRef.current && y - prevMouseYRef.current > 0;
    if (isCursorNearBottomOfNetworkList && scrollNetworkListDirection !== 'down' && isMovingDown) {
      setScrollNetworkListDirection('down');
    } else if (isCursorNearTopOfNetworkList && scrollNetworkListDirection !== 'up' && isMovingUp) {
      setScrollNetworkListDirection('up');
    } else if (
      scrollNetworkListDirection !== null &&
      ((scrollNetworkListDirection === 'down' && isMovingUp) || (scrollNetworkListDirection === 'up' && isMovingDown))
    ) {
      stopNetworkListScrolling();
    }
    prevMouseYRef.current = y;
  };

  const setScrollToRowIndexByNetworkId = (id: string) => {
    const scrollToNetworkIndex = networks.findIndex((network) => network.id === id);
    if (scrollToNetworkIndex !== -1) {
      setScrollToRowIndex(scrollToNetworkIndex);
    }
  };

  useEffect(() => {
    if (!scrollNetworkListDirection) return;
    const scrollId = setInterval(() => {
      const networkListElement = networkListWrapperRef.current;
      const scrollAmount = scrollNetworkListDirection === 'up' ? -100 : 100;
      networkListElement?.scrollTo({ top: scrollAmount, behavior: 'smooth' });
    }, 100);
    return () => {
      clearInterval(scrollId);
    };
  }, [scrollNetworkListDirection]);

  useEffect(() => {
    if (!isFrequencyDragging && scrollNetworkListDirection) {
      stopNetworkListScrolling();
    }
  }, [isFrequencyDragging, scrollNetworkListDirection, stopNetworkListScrolling]);

  useEffect(() => {
    const resetNetworkList = () => {
      remove();
      refetch();
      setRefetchInterval(MAX_REFETCH_INTERVAL);
    };

    if (scrollToNetworkId) {
      if (networkListWrapperRef.current?.scrollTop !== 0) {
        scrollNetworkListTop();
      }
      resetNetworkList();
    }
  }, [refetch, remove, scrollToNetworkId]);

  useEffect(() => {
    if (previousIsNetworksFetching && !isNetworksFetching && scrollToNetworkId) {
      setScrollToRowIndexByNetworkId(scrollToNetworkId);
      dispatch(setScrollToNetworkId(null));
    }
  }, [isNetworksFetching, scrollToNetworkId, networks, dispatch, previousIsNetworksFetching]);

  useConditionEffectOnce(() => {
    if (selectedRadioNetwork) {
      setScrollToRowIndexByNetworkId(selectedRadioNetwork.id);
    }
  }, !isNetworksLoading);

  return {
    isOnTopOfNetworkList: Boolean(refetchInterval),
    networkListWrapperRef,
    scrollToRowIndex,
    handleListScroll,
    handleMouseMove,
    stopNetworkListScrolling,
  };
};

export default useNetworkListScrolling;
