import { SpectrogramProps, ZoomData } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  zoomData: ZoomData;
  shiftPercent?: number;
}

const getZoomDataShiftedLeft = ({
  zoomData,
  startFrequency,
  endFrequency,
  shiftPercent = 5,
}: Params): ZoomData | null => {
  if (!zoomData || zoomData.startZoomPercent === 0) return null;

  const newStartZoomPercent = zoomData.startZoomPercent - shiftPercent;
  const newEndZoomPercent = zoomData.endZoomPercent - shiftPercent;
  const newStartZoomFrequency = getRoundedFloat(
    ((endFrequency - startFrequency) / 100) * newStartZoomPercent + startFrequency
  );
  const newEndZoomFrequency = getRoundedFloat(
    ((endFrequency - startFrequency) / 100) * newEndZoomPercent + startFrequency
  );
  const isAtLeftCorner = newStartZoomPercent <= 0;

  const newZoomData = {
    startZoomFrequency: newStartZoomFrequency,
    endZoomFrequency: newEndZoomFrequency,
    startZoomPercent: newStartZoomPercent,
    endZoomPercent: newEndZoomPercent,
  };

  const leftCornerZoomData = {
    startZoomPercent: 0,
    endZoomPercent: zoomData.endZoomPercent - zoomData.startZoomPercent,
    endZoomFrequency: startFrequency + (zoomData.endZoomFrequency - zoomData.startZoomFrequency),
    startZoomFrequency: startFrequency,
  };

  return isAtLeftCorner ? leftCornerZoomData : newZoomData;
};

export default getZoomDataShiftedLeft;
