import { openSelectionDetails, selectTranscriptEntitiesDictionary } from '@/entities/transcript/slices';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

export const useSelectionDetails = () => {
  const transcriptEntitiesDictionary = useAppSelector(selectTranscriptEntitiesDictionary);
  const dispatch = useAppDispatch();

  return (messageElement: HTMLElement, entityElement: HTMLElement) => {
    const {
      dataset: { cluster, transcriptId, networkId },
    } = messageElement;
    const {
      dataset: { id: attachId },
    } = entityElement;

    if (!transcriptId || !attachId) return;

    const entity = transcriptEntitiesDictionary[transcriptId]?.[attachId];

    if (!entity) return;

    // setTimeout is used to open SelectionDetails, so to stay in the same execution context we need to use it here also
    return setTimeout(() =>
      dispatch(
        openSelectionDetails({
          isCluster: cluster === 'true',
          transcriptId,
          networkId,
          selection: entity.text,
          entityType: entity.entityType,
          entity,
        })
      )
    );
  };
};
