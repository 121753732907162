import { Row, Typography } from 'antd';
import { File } from '@/entities/source/@fox/types';
import { timeService } from '@/shared/services';
import styles from './styles.module.css';

const DETAILS_TITLE = 'Запис';

type Props = {
  file: File;
};

const DetailsHeader = ({ file: { metadata } }: Props) => {
  return (
    <Row className={styles.row}>
      <Typography className={styles.title}>{DETAILS_TITLE}</Typography>
      {Boolean(metadata.interceptedAt) && (
        <Typography className={styles.date}>
          {timeService.getFormattedFullTimeOrDate(metadata.interceptedAt)}
        </Typography>
      )}
    </Row>
  );
};

export default DetailsHeader;
