import { ResponseData, request } from '@/shared/api';
import { V3_PREFIX } from '@/shared/config';
import { GetNotificationsQueryParams, Notification } from './types';
import { getNotificationsSchema } from './validationSchemas';

export const getNotifications = ({ queryParams }: { queryParams: GetNotificationsQueryParams }) => {
  return request<ResponseData<Notification[]>>('/notifications/info', {
    endpointPrefix: V3_PREFIX,
    validationSchema: getNotificationsSchema,
    params: {
      ...queryParams,
    },
  });
};
