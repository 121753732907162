import { ButtonProps, ModalProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useIsMobile, useIsTabletOrMobile } from '@/shared/hooks';
import { FORM_SUBMIT_BUTTON_PROPS, MODAL_FORM_ID } from '../constants';

interface Params extends Pick<ModalProps, 'afterOpenChange'> {
  withForm?: boolean;
}

const useModal = ({ afterOpenChange, withForm }: Params) => {
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  const buttonSize: SizeType = isTabletOrMobile ? 'large' : 'middle';
  const formSubmitButtonProps: ButtonProps = withForm ? FORM_SUBMIT_BUTTON_PROPS : {};

  const handleAfterOpenChange: ModalProps['afterOpenChange'] = (open) => {
    afterOpenChange?.(open);

    if (open && withForm) {
      const form = document.activeElement?.querySelector<HTMLElement>('form');
      const firstField = form?.querySelector<HTMLElement>('input, textarea');

      if (form) form.id = MODAL_FORM_ID;
      firstField?.focus();
    }
  };

  return {
    isMobile,
    buttonSize,
    formSubmitButtonProps,
    handleAfterOpenChange,
  };
};

export default useModal;
