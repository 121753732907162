import { useMemo } from 'react';
import { UnitUser } from '@/entities/unit/types';
import { UnitUserQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';

const useGetSearchedUsers = (users: UnitUser[]) => {
  const [search] = useSearchParam(UnitUserQueryParam.Search);

  return useMemo(() => {
    if (!search) return users;

    return users.filter(({ fullName, phone }) => {
      const matchedByName = (fullName ?? '').toLowerCase().includes(search.toLowerCase());
      const matchedByPhone = phone.includes(search);

      return matchedByName || matchedByPhone;
    });
  }, [users, search]);
};

export default useGetSearchedUsers;
