import { useMemo } from 'react';
import { MenuProps } from 'antd';
import { CopyIcon, RulerIcon } from '@/shared/assets';
import { useCopyToClipboard } from '@/shared/hooks';
import { coordinatesConverterService } from '@/shared/services';
import { LatLngCoordinates } from '@/shared/types';
import { ContextMenuItem } from '../components';

const MEASURE_DISTANCE = 'Виміряти відстань';

type Params = {
  coords: LatLngCoordinates | null;
  createRuler: (coords: LatLngCoordinates) => void;
};

const useContextMenu = ({ coords, createRuler }: Params) => {
  const { copyToClipboard } = useCopyToClipboard();

  return useMemo<MenuProps['items']>(() => {
    if (!coords) return [];
    const wgs84 = [coords.lat, coords.lng].map((val) => val.toFixed(7)).join(', ');
    const ucs2000 = coordinatesConverterService.convertWGS84toUCS2000(coords.lat, coords.lng)?.join(', ') ?? '';
    const mgrs = coordinatesConverterService.convertWGS84toMGRS(coords) ?? '';

    const handleCopyCoords = (val: string) => {
      copyToClipboard(val);
    };

    return [
      {
        key: 'wgs84',
        label: (
          <ContextMenuItem
            icon={CopyIcon}
            label={wgs84}
          />
        ),
        onClick: () => handleCopyCoords(wgs84),
      },
      ucs2000
        ? {
            key: 'ucs2000',
            label: (
              <ContextMenuItem
                icon={CopyIcon}
                label={ucs2000}
              />
            ),
            onClick: () => handleCopyCoords(ucs2000),
          }
        : null,
      mgrs
        ? {
            key: 'mgrs',
            label: (
              <ContextMenuItem
                icon={CopyIcon}
                label={mgrs}
              />
            ),
            onClick: () => handleCopyCoords(mgrs),
          }
        : null,
      {
        key: 'ruler',
        label: (
          <ContextMenuItem
            icon={RulerIcon}
            label={MEASURE_DISTANCE}
          />
        ),
        onClick: () => createRuler(coords),
      },
    ];
  }, [coords]);
};

export default useContextMenu;
