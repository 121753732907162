import classNames from 'classnames';
import { SilenceIcon } from '@/shared/assets';
import { Button } from '@/shared/components';

interface Props {
  onClick: VoidFunction;
  isSkipSilenceEnabled: boolean;
}

const SilenceSkipIconButton = ({ onClick, isSkipSilenceEnabled }: Props) => {
  return (
    <Button
      onClick={onClick}
      icon={<SilenceIcon className={classNames({ active: isSkipSilenceEnabled })} />}
    />
  );
};

export default SilenceSkipIconButton;
