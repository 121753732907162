import { ReactNode, PropsWithChildren } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';
import { HeaderTitle } from '@/shared/components/HeaderTitle';
import styles from './styles.module.css';

type Props = {
  addInfo?: ReactNode;
  actions?: ReactNode;
  className?: string;
  testId?: string;
};

export const Header = ({ actions, addInfo, className, testId, children }: PropsWithChildren<Props>) => {
  return (
    <Row
      className={classNames(styles.wrapper, className)}
      data-testid={testId}
    >
      <HeaderTitle>{children}</HeaderTitle>
      <div className={styles.addBlock}>
        {addInfo}
        {actions}
      </div>
    </Row>
  );
};
