import { Typography } from 'antd';
import { useDate } from './hooks';
import { DateProps } from './types';

const Date = ({ date }: DateProps) => {
  const { formattedDate } = useDate({ date });

  if (!formattedDate) return null;

  return <Typography>{formattedDate}</Typography>;
};

export default Date;
