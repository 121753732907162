// cookies will be added to fetch request if only it contains "credentials = 'include'" option
// some of fetch requests are done by third-party libraries
// this function monkey patch global fetch, so cookies are enabled for all fetch requests that are made to same domain as a client.

const enableFetchCookies = () => {
  const originalFetch = window.fetch;

  window.fetch = (...args) => {
    const url = args[0];
    const isSameDomain = typeof url === 'string' && new URL(url).hostname.includes(window.location.hostname);
    const options = args[1] || {};

    if (isSameDomain) {
      options.credentials = 'include';
    }

    // @ts-ignore
    return originalFetch(url, options);
  };
};

export default enableFetchCookies;
