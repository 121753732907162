import { useImage } from './hooks';
import styles from './styles.module.css';
import { ImageProps as Props } from './types';

const Image = ({ memoImage }: Props) => {
  const { imageWrapperRef, image } = useImage({ memoImage });

  return (
    <div ref={imageWrapperRef}>
      {image && (
        <img
          loading="lazy"
          src={image.url}
          width={image.width}
          height={image.height}
          alt=""
          className={styles.image}
        />
      )}
    </div>
  );
};

export default Image;
