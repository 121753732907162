import { Flex } from 'antd';
import { ActionsDropdown, Button, Tooltip } from '@/shared/components';
import { useRecordActions } from './hooks';
import styles from './styles.module.css';
import { RecordActionsProps } from './types';

const RecordActions = (props: RecordActionsProps) => {
  const {
    toggleVoiceBtnTooltipTitle,
    toggleVoiceBtnIcon,
    isToggleVoiceBtnLoading,
    handleToggleVoiceClick,
    items,
    dangerItems,
    isLoading,
    isMobile,
  } = useRecordActions(props);

  return (
    <Flex gap={8}>
      {!isMobile && (
        <Tooltip title={toggleVoiceBtnTooltipTitle}>
          <Button
            className={styles.toggleVoiceBtn}
            type="text"
            icon={toggleVoiceBtnIcon}
            loading={isToggleVoiceBtnLoading}
            onClick={handleToggleVoiceClick}
            withStopPropagation
            data-toggle-voice
          />
        </Tooltip>
      )}
      <ActionsDropdown
        items={items}
        dangerItems={dangerItems}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default RecordActions;
