import { useState } from 'react';
import { Form } from 'antd';
import { useGetCategories } from '@/entities/category';
import { useGetLocations } from '@/entities/location';
import { useRadioNetworksFilter } from '@/entities/network';
import { LoadingWrapper } from '@/shared/components';
import { useGetSelectedLocations, useGetSubscriptions } from '@/widgets/SubscriptionDrawer/hooks';
import { CategoryField, FactionField, FrequencyField, LocationField, KeywordField } from './components';
import { useGetSelectedFrequencies } from './hooks';
import { mergeLocations } from './utils';

const SubscriptionSettingsControls = () => {
  const { subscriptions } = useGetSubscriptions();
  const [locationSearch, setLocationSearch] = useState('');

  const { locations, isLocationsLoading, isLocationsFetching } = useGetLocations({ search: locationSearch });
  const { selectedLocations, isSelectedLocationsLoading } = useGetSelectedLocations(subscriptions);
  const allLocations = locationSearch ? locations : mergeLocations(locations, selectedLocations);

  const { networkFilters, isNetworkFiltersLoading } = useRadioNetworksFilter();
  const { factions } = networkFilters;

  const { categories, isCategoriesLoading } = useGetCategories();

  const { selectedFrequencies, isSelectedFrequenciesLoading } = useGetSelectedFrequencies();

  const isLoading =
    isLocationsLoading || isNetworkFiltersLoading || isCategoriesLoading || isSelectedFrequenciesLoading;

  return (
    <LoadingWrapper
      isLoading={isLoading}
      isEmpty={false}
    >
      <Form layout="vertical">
        <LocationField
          locations={allLocations}
          setSearch={setLocationSearch}
          isLoading={isLocationsLoading || isSelectedLocationsLoading || isLocationsFetching}
        />
        <FactionField
          factions={factions}
          isLoading={isNetworkFiltersLoading}
        />
        <CategoryField
          categories={categories}
          isLoading={isCategoriesLoading}
        />
        <FrequencyField selectedFrequencies={selectedFrequencies} />
        <KeywordField />
      </Form>
    </LoadingWrapper>
  );
};

export default SubscriptionSettingsControls;
