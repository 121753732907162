import { useEffect, useMemo, useState } from 'react';
import { selectScrollToTranscriptId, setScrollToTranscriptId } from '@/entities/transcript/slices';
import { TranscriptionList } from '@/entities/transcript/types';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

const ANIMATION_TIME = 5000;
let scrollAnimationTimeoutID: NodeJS.Timeout | undefined;

interface UseScrollToTranscriptRowParams {
  transcripts: TranscriptionList;
  isFetching: boolean;
}

const useScrollToTranscriptRow = ({ transcripts, isFetching }: UseScrollToTranscriptRowParams) => {
  const [activeTranscriptId, setActiveTranscriptId] = useState('');
  const scrollToTranscriptId = useAppSelector(selectScrollToTranscriptId);
  const dispatch = useAppDispatch();

  const scrollToRowIndex = useMemo(() => {
    if (!scrollToTranscriptId) return -1;

    return transcripts.findIndex((item) => {
      if (item.type === 'cluster') {
        const clusterTranscriptIds = item.metadata.sources.map(({ transcriptId }) => transcriptId);
        return clusterTranscriptIds.includes(scrollToTranscriptId);
      }

      return item.id === scrollToTranscriptId;
    });
  }, [scrollToTranscriptId, transcripts]);

  useEffect(() => {
    if (scrollToTranscriptId && scrollToRowIndex >= 0) {
      setActiveTranscriptId(scrollToTranscriptId);
      dispatch(setScrollToTranscriptId(''));

      clearTimeout(scrollAnimationTimeoutID);
      scrollAnimationTimeoutID = setTimeout(() => {
        setActiveTranscriptId('');
      }, ANIMATION_TIME);
    }
  }, [dispatch, scrollToRowIndex, scrollToTranscriptId]);

  useEffect(() => {
    setActiveTranscriptId('');
  }, [isFetching]);

  return { activeTranscriptId, scrollToRowIndex };
};

export default useScrollToTranscriptRow;
