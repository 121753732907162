import { Notification } from '@/entities/notification/api/types';
import { NotificationsDictionary } from '@/entities/notification/types';

// TODO cover function with test
const convertNotificationsToDictionary = (notifications: Notification[]): NotificationsDictionary => {
  const notificationsIdList = notifications.map(({ documentId }) => [documentId, true]);
  return Object.fromEntries(notificationsIdList);
};

export default convertNotificationsToDictionary;
