import { useEffect, useMemo, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { generateFoxWaterfallAuth } from '@/entities/source/@fox/api';
import { useGetFilteredFoxFrequencies } from '@/entities/source/@fox/components/ScanningTab/hooks';
import { FoxFrequencyStatus } from '@/entities/source/@fox/constants';
import { useSource } from '@/entities/source/hooks';
import { selectSelectedFrequencies } from '@/entities/source/slices';
import { FoxSource } from '@/entities/source/types';
import { WATERFALL_SOCKET_ENDPOINT } from '@/shared/config';
import { ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY, DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY } from '@/shared/constants';
import { useAppSelector, useParams } from '@/shared/hooks';
import { validationService, sentryService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import { centrifugeService } from '../services';
import { SpectrumPublication, ScannerPublicationValueItem, ConnectionStatus, SubscriptionStatus } from '../types';
import { getWaterfallAuthToken, getSpectrogramStatus, isFoxWaterfallAuthValid } from '../utils';
import { spectrumPublicationSchema } from '../validationSchemas';
import useDecoder from './useDecoder';
import useGenerateFoxWaterfallAuth from './useGenerateFoxWaterfallAuth';

const MIN_DECIBEL = -130;
const MAX_DECIBEL = -50;
const DETECTIONS_VISIBILITY_RANGE = 0.0025;

interface Params {
  isSpectrogramEnabled: boolean;
}

const useSpectrogramData = ({ isSpectrogramEnabled }: Params) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const memoSelectedFrequencies = useAppSelector(selectSelectedFrequencies) ?? undefined;
  const [decibelPower, setDecibelPower] = useState<number[]>([]);
  const [detections, setDetections] = useState<Pick<ScannerPublicationValueItem, 'frequency' | 'isDigital'>[]>([]);
  const [socketConnectionStatus, setSocketConnectionStatus] = useState<ConnectionStatus>('disconnected');
  const [spectrumSubscriptionStatus, setSpectrumSubscriptionStatus] = useState<SubscriptionStatus>('unsubscribed');
  const [scannerSubscriptionStatus, setScannerSubscriptionStatus] = useState<SubscriptionStatus>('unsubscribed');
  const spectrogramStatus = useMemo(() => {
    return getSpectrogramStatus({ socketConnectionStatus, spectrumSubscriptionStatus, scannerSubscriptionStatus });
  }, [scannerSubscriptionStatus, socketConnectionStatus, spectrumSubscriptionStatus]);

  const isFoxFrequenciesActivating = useIsMutating({ mutationKey: [ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY] });
  const isFoxFrequenciesDeactivating = useIsMutating({ mutationKey: [DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY] });
  const isLoading = Boolean(isFoxFrequenciesActivating) || Boolean(isFoxFrequenciesDeactivating);

  const { isDecoderReady, decodeSpectrumBase64, decodeScannerBase64 } = useDecoder();
  const source = useSource<FoxSource>();
  const exceptionExtraData = source ? pick(source, ['status', 'deviceStatus', 'systemLoad', 'frequencyRange']) : null;
  const { foxWaterfallAuth, removeFoxWaterfallAuth } = useGenerateFoxWaterfallAuth({
    sourceId,
    options: {
      enabled: isSpectrogramEnabled && Boolean(sourceId),
    },
  });
  const { foxFrequencies } = useGetFilteredFoxFrequencies();
  const memoFrequencies = useMemo(() => {
    return foxFrequencies.map(({ value, status, id, isDigital }) => ({
      frequency: Number(value),
      id,
      isActive: status === FoxFrequencyStatus.Active,
      isDigital,
    }));
  }, [foxFrequencies]);

  useEffect(() => {
    if (!isSpectrogramEnabled && foxWaterfallAuth) {
      removeFoxWaterfallAuth();
    }
  }, [isSpectrogramEnabled, foxWaterfallAuth, removeFoxWaterfallAuth]);

  useEffect(() => {
    if (
      !sourceId ||
      !isDecoderReady ||
      !isSpectrogramEnabled ||
      !foxWaterfallAuth ||
      !isFoxWaterfallAuthValid(foxWaterfallAuth) ||
      !WATERFALL_SOCKET_ENDPOINT
    )
      return;

    centrifugeService.init({
      endpoint: WATERFALL_SOCKET_ENDPOINT,
      options: {
        token: foxWaterfallAuth.authToken,
        getToken: getWaterfallAuthToken({ sourceId, tokenType: 'authToken', generateFoxWaterfallAuth }),
      },
      onInitializationFail: (error) => {
        sentryService.captureExpandedException({
          exception: error,
          extraData: {
            name: 'onInitializationFail',
            value: exceptionExtraData,
          },
        });
        setSocketConnectionStatus('failed');
      },
    });

    centrifugeService.addEventListeners({
      connecting: () => {
        setSocketConnectionStatus('connecting');
      },
      connected: () => {
        setSocketConnectionStatus('connected');
      },
      disconnected: () => {
        setSocketConnectionStatus('disconnected');
      },
      error: ({ error }) => {
        if (!centrifugeService.shouldCaptureException(error)) return;
        sentryService.captureExpandedException({
          exception: error,
          extraData: {
            name: 'general centrifuge error',
            value: exceptionExtraData,
          },
        });
      },
    });

    const unsubscribeFromSpectrumChanel = centrifugeService.subscribeToChanel({
      options: {
        token: foxWaterfallAuth.spectrumToken,
        getToken: getWaterfallAuthToken({ sourceId, tokenType: 'spectrumToken', generateFoxWaterfallAuth }),
      },
      onSubscriptionFail: (error) => {
        sentryService.captureExpandedException({
          exception: error,
          extraData: {
            name: 'onSubscriptionFail spectrum',
            value: exceptionExtraData,
          },
        });
        setSpectrumSubscriptionStatus('failed');
      },
      listeners: {
        publication: (data: SpectrumPublication) => {
          validationService.validateResponseData({
            validationSchema: spectrumPublicationSchema,
            responseData: data,
            url: `source spectrum subscription ${sourceId}`,
          });

          const decodedData = decodeSpectrumBase64(data.data.value);
          if (decodedData && decodedData.length > 0) {
            setDecibelPower(decodedData);
          }
        },
        subscribing: () => {
          setSpectrumSubscriptionStatus('subscribing');
        },
        subscribed: () => {
          setSpectrumSubscriptionStatus('subscribed');
        },
        unsubscribed: () => {
          setSpectrumSubscriptionStatus('unsubscribed');
        },
        error: ({ error }) => {
          if (!centrifugeService.shouldCaptureException(error)) return;
          sentryService.captureExpandedException({
            exception: error,
            extraData: {
              name: 'spectrum error',
              value: exceptionExtraData,
            },
          });
        },
      },
    });

    const unsubscribeFromScannerChanel = centrifugeService.subscribeToChanel({
      options: {
        token: foxWaterfallAuth.scannerToken,
        getToken: getWaterfallAuthToken({ sourceId, tokenType: 'scannerToken', generateFoxWaterfallAuth }),
      },
      onSubscriptionFail: (error) => {
        sentryService.captureExpandedException({
          exception: error,
          extraData: {
            name: 'onSubscriptionFail scanner',
            value: exceptionExtraData,
          },
        });
        setScannerSubscriptionStatus('failed');
      },
      listeners: {
        publication: (data) => {
          validationService.validateResponseData({
            validationSchema: spectrumPublicationSchema,
            responseData: data,
            url: `source scanner subscription ${sourceId}`,
          });
          const decodedData = decodeScannerBase64(data.data.value);
          if (decodedData) {
            setDetections(decodedData);
          }
        },
        subscribing: () => {
          setScannerSubscriptionStatus('subscribing');
        },
        subscribed: () => {
          setScannerSubscriptionStatus('subscribed');
        },
        unsubscribed: () => {
          setScannerSubscriptionStatus('unsubscribed');
        },
        error: ({ error }) => {
          if (!centrifugeService.shouldCaptureException(error)) return;
          sentryService.captureExpandedException({
            exception: error,
            extraData: {
              name: 'scanner error',
              value: exceptionExtraData,
            },
          });
        },
      },
    });

    centrifugeService.connect();

    return () => {
      unsubscribeFromSpectrumChanel?.();
      unsubscribeFromScannerChanel?.();
      centrifugeService.disconnect();
    };
  }, [sourceId, isSpectrogramEnabled, isDecoderReady, decodeSpectrumBase64, foxWaterfallAuth]);

  return {
    isLoading,
    spectrogramStatus,
    decibelPower,
    detections,
    minDecibel: MIN_DECIBEL,
    maxDecibel: MAX_DECIBEL,
    detectionsVisibilityRange: DETECTIONS_VISIBILITY_RANGE,
    startFrequency: source?.frequencyRange.from,
    endFrequency: source?.frequencyRange.to,
    memoFrequencies,
    memoSelectedFrequencies,
  };
};

export default useSpectrogramData;
