import { AudioPlayerProps } from '../type';
import useAudioPlayerHotKeys from './useAudioPlayerHotKeys';
import useAutoplayControl from './useAutoplayControl';
import usePlayPause from './usePlayPause';
import useRateControls from './useRateControls';
import useSilenceSkipManager from './useSilenceSkipManager';
import useSkipRecordsWithoutVoice from './useSkipRecordsWithoutVoice';
import useTimeTooltipPosition from './useTimeTooltipPosition';
import useWavesurfer from './useWavesurfer';
import useWsFinishEventListener from './useWsFinishEventListener';

interface Params
  extends Pick<
    AudioPlayerProps,
    | 'isRecordSelected'
    | 'shouldBePlaying'
    | 'memoSettings'
    | 'memoOnPlayPause'
    | 'memoOnSettingsUpdate'
    | 'memoPlayNextRecord'
    | 'memoNavigateToPreviousRecord'
    | 'memoNavigateToNextRecord'
    | 'hasVoice'
    | 'url'
    | 'duration'
    | 'stringifiedPeaks'
    | 'isDisabled'
  > {}

const usePlayer = ({
  isRecordSelected,
  shouldBePlaying,
  memoSettings,
  memoOnPlayPause,
  memoOnSettingsUpdate,
  memoPlayNextRecord,
  memoNavigateToPreviousRecord,
  memoNavigateToNextRecord,
  hasVoice,
  url,
  duration,
  stringifiedPeaks,
  isDisabled,
}: Params) => {
  const { containerRef, wavesurfer, currentTime, isPlaying, isReady, loadedAudioDuration } = useWavesurfer({
    memoSettings,
    url,
    stringifiedPeaks,
    duration,
  });

  const { memoPlayPause, memoStop, memoPlay, memoPause } = usePlayPause({
    isRecordSelected,
    wavesurfer,
    shouldBePlaying,
    isPlaying,
    isReady,
    memoOnPlayPause,
    hasVoice,
    isSkipSilenceEnabled: memoSettings.isSkipSilenceEnabled,
    isDisabled,
  });

  const { isAutoplayOn, memoToggleAutoplay } = useAutoplayControl({
    memoSettings,
    memoOnSettingsUpdate,
    memoPlay,
  });

  const { audioRate, handleRateSelect, memoUpdateRate } = useRateControls({
    memoSettings,
    memoOnSettingsUpdate,
  });

  const { isSkipSilenceEnabled, memoHandleSilenceSkipChange } = useSilenceSkipManager({
    defaultIsSkipSilenceEnabled: memoSettings.isSkipSilenceEnabled,
    memoOnSettingsUpdate,
  });

  const timeTooltipPosition = useTimeTooltipPosition({ wavesurfer, isPlaying, pause: memoPause, play: memoPlay });

  useWsFinishEventListener({ wavesurfer, isAutoplayOn, memoStop, memoPlayNextRecord });

  useAudioPlayerHotKeys({
    wavesurfer,
    currentTime,
    audioRate,
    memoUpdateRate,
    memoToggleAutoplay,
    memoNavigateToNextRecord,
    memoNavigateToPreviousRecord,
    memoPlayPause,
    memoHandleSilenceSkipChange,
    duration: loadedAudioDuration,
  });

  useSkipRecordsWithoutVoice({
    isPlaying,
    hasVoice,
    memoSettings,
    memoFinishRecord: memoStop,
    memoPlayNextRecord,
  });

  return {
    containerRef,
    currentTime,
    isPlaying,
    isAutoplayOn,
    audioRate,
    timeTooltipPosition,
    handleRateSelect,
    memoToggleAutoplay,
    memoPlayPause,
    isSkipSilenceEnabled,
    memoHandleSilenceSkipChange,
    loadedAudioDuration,
  };
};

export default usePlayer;
