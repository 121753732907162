import { useEffect } from 'react';
import { addTranscriptsDictionary, selectTranscriptsDictionary } from '@/entities/transcript/slices';
import { TranscriptionDictionaryItem, TranscriptionList } from '@/entities/transcript/types';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

type Params = {
  memoTranscripts: TranscriptionList;
};

const useSyncTranscriptsDictionary = ({ memoTranscripts }: Params) => {
  const transcriptsDictionary = useAppSelector(selectTranscriptsDictionary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (memoTranscripts.length === 0) return;

    const flatTranscripts = memoTranscripts.flatMap<TranscriptionDictionaryItem>((item) => {
      if (item.type === 'cluster') {
        return item.metadata.sources
          .map<TranscriptionDictionaryItem | null>((source) => {
            const frequency = item.metadata.frequencies.find(({ transcriptIds }) =>
              transcriptIds.includes(source.transcriptId)
            );

            // do not override cluster children if they have already been added
            if (transcriptsDictionary[source.transcriptId]) return null;

            return {
              id: source.transcriptId,
              frequencyId: frequency?.id,
              frequencyValue: frequency?.value,
              sourceName: source.name,
            };
          })
          .filter((x): x is TranscriptionDictionaryItem => Boolean(x));
      }

      return {
        id: item.id,
        frequencyId: item.frequencyId,
        frequencyValue: item.metadata.frequency,
        sourceName: item.sourceName,
        categories: item.categories,
        coordinates: item.metadata.coordinates,
      };
    });

    dispatch(addTranscriptsDictionary(flatTranscripts));
  }, [memoTranscripts, dispatch]);
};

export default useSyncTranscriptsDictionary;
