import {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useState,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import { Input, Flex } from 'antd';
import { SearchProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useDebounceFn } from '@reactuses/core';
import classNames from 'classnames';
import { Key } from 'ts-key-enum';
import { COPIES, SEARCH_MIN_LENGTH, SortType } from '@/shared/constants';
import { hotkeysHelperService, invisibleTextService } from '@/shared/services';
import { FilterButton } from '../FilterButton';
import { SortButton } from './components';
import styles from './styles.module.css';
import { escapeNewLine, unescapeNewLine } from './utils';

const SEARCH_DEBOUNCE = 1200;

const SLASH_KEY = '/';

interface Props extends SearchProps {
  value?: string;
  onSearch: (value: string) => void;
  filterButtonOptions?: {
    selectedCount: number;
    onClick: VoidFunction;
  };
  sortButtonOptions?: {
    order: SortType;
    onClick: VoidFunction;
  };
  additionalControlsLeft?: ReactElement;
  additionalControlsRight?: ReactElement;
  isAsync?: boolean;
  isFocusHotkeyEnabled?: boolean;
}

const SearchField = ({
  className,
  placeholder = COPIES.SEARCH_PLACEHOLDER,
  value = '',
  onSearch,
  filterButtonOptions: filter,
  sortButtonOptions: sort,
  additionalControlsLeft,
  additionalControlsRight,
  isAsync = false,
  isFocusHotkeyEnabled,
}: Props) => {
  const inputRef = useRef<TextAreaRef>(null);
  const [localSearchState, setLocalSearchState] = useState('');
  const { run: debouncedOnSearch, flush: immediateSearch } = useDebounceFn(
    () => {
      const searchValue = invisibleTextService.replaceInvisibleSymbols(unescapeNewLine(localSearchState)).trim();
      const isSearchAllowed = searchValue.length === 0 || searchValue.length >= SEARCH_MIN_LENGTH;

      if (isSearchAllowed) {
        onSearch(searchValue);
      }
    },
    isAsync ? SEARCH_DEBOUNCE : 0
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setLocalSearchState(escapeNewLine(e.target.value));
      debouncedOnSearch();
    },
    [debouncedOnSearch]
  );

  const handleKeyDown = useCallback(
    (ev: ReactKeyboardEvent<HTMLTextAreaElement>) => {
      if (ev.key === Key.Enter) {
        immediateSearch();
        ev.preventDefault();
      }
      if (ev.key === Key.Escape) {
        setLocalSearchState('');
        debouncedOnSearch();
      }
    },
    [immediateSearch, debouncedOnSearch]
  );

  const handleDocumentKeydown = (e: KeyboardEvent) => {
    if (hotkeysHelperService.getIsInputOrTextarea(e) || hotkeysHelperService.getIsOverlayedComponentOpen()) return;

    if (isFocusHotkeyEnabled && e.key === SLASH_KEY) {
      inputRef.current?.focus();
      inputRef.current?.resizableTextArea?.textArea.select();
      e.preventDefault();
    }
  };

  useEffect(() => {
    setLocalSearchState(escapeNewLine(value));
  }, [value]);

  useEffect(() => {
    document.addEventListener('keydown', handleDocumentKeydown);
    return () => document.removeEventListener('keydown', handleDocumentKeydown);
  }, []);

  return (
    <div
      className={classNames(styles.wrapper, {
        className,
      })}
      data-testid="search-field"
    >
      <SearchOutlined className={styles.searchIcon} />
      <Input.TextArea
        ref={inputRef}
        className={styles.input}
        placeholder={placeholder}
        value={localSearchState}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        variant="borderless"
      />
      <Flex gap={8}>
        {additionalControlsLeft}
        {sort && (
          <SortButton
            onClick={sort.onClick}
            direction={sort.order}
          />
        )}
        {filter && (
          <FilterButton
            onClick={filter.onClick}
            filtersCount={filter.selectedCount}
          />
        )}
        {additionalControlsRight}
      </Flex>
    </div>
  );
};

export default SearchField;
