import { useState } from 'react';
import { handleBlobError } from '@/entities/source/@fox/components/RecordsTab/utils';
import { authService, errorHandlingService, logout } from '@/shared/api';
import { AUTH_DATA_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { LocalStorageService } from '@/shared/services';

const localStorageAuthDataService = new LocalStorageService(AUTH_DATA_LOCAL_STORAGE_KEY);

const createDownloadLink = (obj: Blob, fileName: string) => {
  const imageURL = window.URL.createObjectURL(obj);
  const anchor = document.createElement('a');
  anchor.href = imageURL;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const useDownloadRecord = () => {
  const { openNotification } = useNotificationContext();
  const authData = authService.getAuthDataFromLocalStorage();

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadRecord = (url: string, fileName: string) => {
    setIsDownloading(true);
    const request = new XMLHttpRequest();

    request.responseType = 'blob';
    request.open('get', url, true);

    if (authData?.accessToken) {
      request.setRequestHeader('X-Access-Token', authData.accessToken);
    }

    request.send();

    request.onloadend = () => {
      setIsDownloading(false);
    };

    request.onerror = () => {
      setIsDownloading(false);
    };

    request.onreadystatechange = async function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          createDownloadLink(this.response, fileName);
        } else if (request.status === 401) {
          if (!authData) {
            logout({ shouldSaveLastRoute: true });
            return;
          }
          try {
            const refreshedData = await errorHandlingService.getRefreshToken({
              refresh_token: authData.refreshToken,
            });
            localStorageAuthDataService.setStorageValue(JSON.stringify(refreshedData));
            request.open('get', url, true);
            request.setRequestHeader('X-Access-Token', refreshedData.data.accessToken);
            request.send();
          } catch (e) {
            logout({ shouldSaveLastRoute: true });
          }
        } else {
          handleBlobError(this.response, openNotification);
        }
      }
    };
  };

  return { isDownloading, downloadRecord };
};

export default useDownloadRecord;
