import { UIEvent, useState } from 'react';
import { useDebounceFn } from '@reactuses/core';
import { DEBOUNCE_DEFAULT } from '@/shared/constants';

const useScrollTopPosition = () => {
  const [scrollTopPosition, setScrollTopPosition] = useState<number>(0);
  const { run: debouncedSetScrollTopPosition } = useDebounceFn((value: number) => {
    setScrollTopPosition(value);
  }, DEBOUNCE_DEFAULT);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;
    debouncedSetScrollTopPosition(scrollTop);
  };

  return {
    scrollTopPosition,
    handleScroll,
  };
};

export default useScrollTopPosition;
