import { ReactNode, FC, UIEvent, forwardRef } from 'react';
import classNames from 'classnames';
import { SoundWave } from './components';
import styles from './styles.module.css';
import useScrollUpToRefresh from './useScrollUpToRefresh';

// className, childrenWrapperClassName, isRefreshTriggerDisabled are needed only for components where used react-virtualized
interface Props {
  children: ReactNode;
  onRefresh: () => Promise<unknown>;
  LoadingComponent?: FC<{ isActive: boolean }>;
  isScrollUpToRefreshEnabled?: boolean;
  loadingComponentWrapperClassName?: string;
  className?: string;
  childrenWrapperClassName?: string;
  isRefreshTriggerDisabled?: boolean;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
  hasMobileVirtualListChildren?: boolean;
}

const ScrollUpToRefresh = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      children,
      onRefresh,
      LoadingComponent = SoundWave,
      isScrollUpToRefreshEnabled = true,
      loadingComponentWrapperClassName,
      className,
      childrenWrapperClassName,
      isRefreshTriggerDisabled,
      onScroll,
      hasMobileVirtualListChildren,
    }: Props,
    ref
  ) => {
    const {
      handleScroll,
      isLoadingComponentVisible,
      isChildrenWrapperMovedDown,
      isMobileVirtualListStylesEnabled,
      setRefs,
    } = useScrollUpToRefresh({
      onRefresh,
      isScrollUpToRefreshEnabled,
      onScroll,
      isRefreshTriggerDisabled,
      forwardRef: ref,
      hasMobileVirtualListChildren,
    });

    return (
      <div
        ref={setRefs}
        className={classNames(styles.wrapper, className, {
          [styles.wrapperWithMobileVirtualList]: isMobileVirtualListStylesEnabled,
        })}
        onScroll={handleScroll}
      >
        <div
          className={classNames(
            styles.loadingComponentWrapper,
            {
              [styles.visibleLoading]: isLoadingComponentVisible,
            },
            loadingComponentWrapperClassName
          )}
        >
          <LoadingComponent isActive={isLoadingComponentVisible} />
        </div>
        <div
          className={classNames(styles.childrenWrapper, childrenWrapperClassName, {
            [styles.childrenWrapperMovedDown]: isChildrenWrapperMovedDown,
            [styles.childrenWrapperWithMobileVirtualList]: isMobileVirtualListStylesEnabled,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);
ScrollUpToRefresh.displayName = 'ScrollUpToRefresh';

export default ScrollUpToRefresh;
