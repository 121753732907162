import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageService } from '@/shared/services';
import { RootState } from '@/shared/types';
import { ThemeState } from './types';

const DARK_MODE_STATE_LOCALSTORAGE_KEY = 'isDarkMode';
const localStorageDarkMenuService = new LocalStorageService<string>(DARK_MODE_STATE_LOCALSTORAGE_KEY);
const persistedIsDarkMode = localStorageDarkMenuService.getStorageValue();

const initialState: ThemeState = {
  isDarkMode: persistedIsDarkMode
    ? JSON.parse(persistedIsDarkMode)
    : window.matchMedia('(prefers-color-scheme: dark)').matches || false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setIsDarkMode: (state: ThemeState, action: PayloadAction<ThemeState['isDarkMode']>) => {
      state.isDarkMode = action.payload;
      localStorageDarkMenuService.setStorageValue(JSON.stringify(action.payload));
    },
  },
});

export const { setIsDarkMode } = themeSlice.actions;

export const selectIsDarkMode = (state: RootState) => state.theme.isDarkMode;

export default themeSlice.reducer;
