import { Typography } from 'antd';
import dayjs from 'dayjs';
import { Transcription } from '@/entities/transcript/types';
import { Tooltip } from '@/shared/components';
import { DATE_FULL, DATE_TIME, DATE_FULL_TIME_FULL } from '@/shared/constants';
import styles from './styles.module.css';

type Props = {
  timestamp: Transcription['timestamp'];
};

const TranscriptTime = ({ timestamp }: Props) => {
  const isSameYear = dayjs().isSame(timestamp, 'year');
  const labelFormat = isSameYear ? DATE_TIME : DATE_FULL;

  return (
    <Tooltip title={dayjs(timestamp).format(DATE_FULL_TIME_FULL)}>
      <Typography.Text
        className={styles.time}
        data-testid="date"
      >
        {dayjs(timestamp).format(labelFormat)}
      </Typography.Text>
    </Tooltip>
  );
};

export default TranscriptTime;
