import { useState, useCallback } from 'react';
import { ResponseData } from '@/shared/api';
import { AUTH_DATA_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { useLocalStorage } from '@/shared/hooks/useLocalStorage';
import useLocalStorageListener from '@/shared/hooks/useLocalStorageListener';
import { AuthData } from '@/shared/types';

const usePersistedAuthData = () => {
  const { getStorageValue } = useLocalStorage<ResponseData<AuthData>>(AUTH_DATA_LOCAL_STORAGE_KEY);
  const [authData, setAuthData] = useState<ResponseData<AuthData> | null>(getStorageValue());

  // sometimes when a user puts their computer to sleep mode for several minutes
  // and then awakes it, we get invalid authData.
  // syncAuthDataManually is used in several places, its purpose is to fix this rare bug.
  const syncAuthDataManually = useCallback(() => {
    setAuthData(getStorageValue());
  }, [getStorageValue]);

  useLocalStorageListener({
    key: AUTH_DATA_LOCAL_STORAGE_KEY,
    memoOnLocalStorageChange: setAuthData,
  });

  return { authData, syncAuthDataManually };
};

export default usePersistedAuthData;
