import { Flex, TableColumnsType, Tag, theme, Typography } from 'antd';
import UnitUserActions from '@/entities/unit/components/UnitContent/components/UnitUsersTab/components/UnitUserActions';
import { UnitUser } from '@/entities/unit/types';
import { Highlighter } from '@/shared/components';
import { ROLES_DESCRIPTION, UnitUserQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { GetListRefFn } from '@/shared/types';
import styles from '../styles.module.css';

enum ColumnTitle {
  User = 'КОРИСТУВАЧ',
  Role = 'РОЛЬ',
  AccessGroup = 'ГРУПА ДОСТУПУ',
}

const USER_DEACTIVATED = 'Блоковано';

type Params = {
  getUserRef: GetListRefFn;
  handleOpenEditModal: (id: UnitUser['id']) => void;
};

const useUnitUsersColumns = ({ getUserRef, handleOpenEditModal }: Params): TableColumnsType<UnitUser> => {
  const [search] = useSearchParam(UnitUserQueryParam.Search);
  const { token } = theme.useToken();

  return [
    {
      title: ColumnTitle.User,
      width: '40%',
      ellipsis: true,
      className: styles.column,
      render: (_, { fullName, phone, isActive }, index) => (
        <Flex
          ref={getUserRef(index)}
          vertical
        >
          <Flex className={styles.nameContainer}>
            <Typography.Text
              strong
              type={!isActive ? 'secondary' : undefined}
              ellipsis={{ tooltip: fullName }}
            >
              <Highlighter
                text={fullName ?? '--'}
                searchTokens={[search]}
              />
            </Typography.Text>
            {!isActive && <Tag color={token.colorPrimary}>{USER_DEACTIVATED}</Tag>}
          </Flex>
          <Typography.Text
            type={!isActive ? 'secondary' : undefined}
            ellipsis={{ tooltip: phone }}
          >
            <Highlighter
              text={phone}
              searchTokens={[search]}
            />
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: ColumnTitle.Role,
      width: '30%',
      className: styles.column,
      render: (_, { isActive, role }) => {
        const roleLabel = ROLES_DESCRIPTION[role].label;
        return (
          <Typography.Text
            type={!isActive ? 'secondary' : undefined}
            ellipsis={{ tooltip: roleLabel }}
          >
            {roleLabel || '--'}
          </Typography.Text>
        );
      },
    },
    {
      title: ColumnTitle.AccessGroup,
      width: '30%',
      className: styles.column,
      render: (_, { groupName }) => (
        <Typography.Paragraph ellipsis={{ tooltip: groupName, rows: 2 }}>{groupName}</Typography.Paragraph>
      ),
    },
    {
      width: 64,
      render: (_, { id, isActive }) => (
        <UnitUserActions
          userId={id}
          isActive={isActive}
          onEditUserClick={handleOpenEditModal}
        />
      ),
    },
  ];
};

export default useUnitUsersColumns;
