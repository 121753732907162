import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ReadMore, Button } from './components';
import { useBody } from './hooks';
import styles from './styles.module.css';
import { BodyProps } from './types';

const Body = ({ shortDescription, fullDescription, button }: BodyProps) => {
  const { isLongBody, components } = useBody({ fullDescription });

  return (
    <div className={styles.wrapper}>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={components}
      >
        {shortDescription}
      </Markdown>
      {!isLongBody && <Button data={button} />}
      {isLongBody && (
        <ReadMore>
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={components}
          >
            {fullDescription}
          </Markdown>
          <Button data={button} />
        </ReadMore>
      )}
    </div>
  );
};

export default Body;
