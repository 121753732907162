import { useEffect } from 'react';
import { Notification } from '@/entities/notification/api/types';
import { SEEN_NOTIFICATIONS_STORAGE_KEY } from '@/entities/notification/constants';
import { NotificationsDictionary } from '@/entities/notification/types';
import { convertNotificationsToDictionary } from '@/entities/notification/utils';
import { useLocalStorage } from '@/shared/hooks';

interface Params {
  memoNotifications: Notification[];
  isSavingEnabled: boolean;
  savingDelay: number;
}

const useSaveSeenNotifications = ({ memoNotifications, isSavingEnabled, savingDelay }: Params) => {
  const { setStorageValue } = useLocalStorage<NotificationsDictionary>(SEEN_NOTIFICATIONS_STORAGE_KEY);

  useEffect(() => {
    if (!isSavingEnabled) return;

    const timeout = setTimeout(() => {
      const seenNotifications = convertNotificationsToDictionary(memoNotifications);
      setStorageValue(seenNotifications);
    }, savingDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [isSavingEnabled, savingDelay, memoNotifications]);
};

export default useSaveSeenNotifications;
