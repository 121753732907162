import { useEffect } from 'react';
import { LocalStorageService } from '@/shared/services';

interface Params<T> {
  key: string;
  memoOnLocalStorageChange: (value: T | null) => void;
}

const useLocalStorageListener = <T>({ key, memoOnLocalStorageChange }: Params<T>) => {
  useEffect(() => {
    const handleStorageChange = (data: CustomEvent<T | null>) => {
      const localStorageService = new LocalStorageService<T>(key);
      const storageValue = localStorageService.parseStorageValue(data.detail);
      memoOnLocalStorageChange(storageValue);
    };

    window.addEventListener(`storage-${key}`, handleStorageChange as EventListener);
    return () => window.removeEventListener(`storage-${key}`, handleStorageChange as EventListener);
  }, [key, memoOnLocalStorageChange]);
};

export default useLocalStorageListener;
