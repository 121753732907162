import { PropsWithChildren } from 'react';
import { Dropdown } from 'antd';
import DrawLegend from '@/entities/map/components/DrawLegend';
import { DISTANCE_LABEL } from '@/entities/map/constants';
import { MaplibreMap } from '@/entities/map/types';
import useContextMenuTrigger from './useContextMenuTrigger';

type Props = {
  map: MaplibreMap | null;
};

const ContextMenuTrigger = ({ map, children }: PropsWithChildren<Props>) => {
  const { triggerRef, items, distanceUnit, distanceValue } = useContextMenuTrigger(map);

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={['contextMenu']}
        destroyPopupOnHide
      >
        <div ref={triggerRef}>{children}</div>
      </Dropdown>
      <DrawLegend
        label={DISTANCE_LABEL}
        unit={distanceUnit}
        value={distanceValue}
      />
    </>
  );
};

export default ContextMenuTrigger;
