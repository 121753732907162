import { useEffect } from 'react';
import { resetTranscripts } from '@/entities/transcript/slices';
import { useRenderPropsContext } from '@/shared/context';
import { useAppDispatch, useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams, TranscriptRenderProps } from '@/shared/types';
import { useMessageClickListener } from './hooks';

const useTranscripts = () => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const { renderSelectionDetails } = useRenderPropsContext<TranscriptRenderProps>();
  const dispatch = useAppDispatch();

  useMessageClickListener();

  useEffect(() => {
    dispatch(resetTranscripts());
  }, [radioNetworkId, dispatch]);

  return { renderSelectionDetails };
};

export default useTranscripts;
