import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MapboxGlDraw } from '@/entities/map/types';
import './styles.module.css';
import stylesConfig from './stylesConfig';

const polygon = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  styles: stylesConfig,
}) as MapboxGlDraw;

export default polygon;
