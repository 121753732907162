import { Flex, Typography } from 'antd';
import { Button } from '@/shared/components/ui/Button';
import { Drawer } from '@/shared/components/ui/Drawer';
import { useFiltersDrawer } from './hooks';
import styles from './styles.module.css';
import { FiltersDrawerProps } from './types';

const RESET_FILTERS = 'Очистити все';

const FiltersDrawer = ({ title, hasActiveFilters, filterQueryParam, children, ...restProps }: FiltersDrawerProps) => {
  const { handleFiltersReset } = useFiltersDrawer({ filterQueryParam });

  const drawerTitle = title ? (
    <Flex className={styles.titleWrapper}>
      <Typography.Text
        className={styles.title}
        ellipsis
      >
        {title}
      </Typography.Text>
      {hasActiveFilters && (
        <Button
          type="link"
          onClick={handleFiltersReset}
        >
          {RESET_FILTERS}
        </Button>
      )}
    </Flex>
  ) : undefined;

  return (
    <Drawer
      title={drawerTitle}
      {...restProps}
    >
      {children}
    </Drawer>
  );
};

export default FiltersDrawer;
