import { ScrollUpToRefresh, LoadingWrapper, VirtualList } from '@/shared/components';
import { TranscriptsProps } from '@/shared/types';
import { TranscriptItem } from './components';
import { useTranscriptList } from './hooks';
import styles from './styles.module.css';

interface Props extends TranscriptsProps {}

export const TranscriptList = (props: Props) => {
  const {
    transcripts,
    listRef,
    isListLoading,
    handleScrollUpToReFetch,
    handleListScroll,
    isRefreshTriggerDisabled,
    activeTranscriptId: activeItemId,
    scrollToRowIndex,
  } = useTranscriptList(props);

  return (
    <ScrollUpToRefresh
      className={styles.scrollUpToRefresh}
      childrenWrapperClassName={styles.list}
      isScrollUpToRefreshEnabled={transcripts.length > 0}
      onRefresh={handleScrollUpToReFetch}
      isRefreshTriggerDisabled={isRefreshTriggerDisabled}
    >
      <LoadingWrapper
        isEmpty={!transcripts.length}
        isLoading={isListLoading}
        isFixedOverlay
        emptyClassName={styles.emptyList}
      >
        <VirtualList
          listRef={listRef}
          data={transcripts}
          scrollToRowIndex={scrollToRowIndex}
          onScroll={handleListScroll}
          renderRow={(item) => (
            <TranscriptItem
              item={item}
              activeItemId={activeItemId}
            />
          )}
        />
      </LoadingWrapper>
    </ScrollUpToRefresh>
  );
};
