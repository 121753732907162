import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getNotifications } from '@/entities/notification/api';
import { GetNotificationsQueryParams, Notification } from '@/entities/notification/api/types';
import { ResponseData } from '@/shared/api';
import { NOTIFICATIONS_QUERY_KEY } from '@/shared/constants';

interface Params {
  options?: UseQueryOptions<
    ResponseData<Notification[]>,
    unknown,
    ResponseData<Notification[]>,
    (string | GetNotificationsQueryParams)[]
  >;
  queryParams: GetNotificationsQueryParams;
}

const useGetNotifications = ({ options, queryParams }: Params) => {
  const { data, isFetching, refetch } = useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY, queryParams],
    queryFn: () => getNotifications({ queryParams }),
    ...options,
  });

  return {
    notifications: data?.data ?? [],
    isFetching,
    refetch,
  };
};

export default useGetNotifications;
