export enum ItemKey {
  ToggleVoice = 'toggle-voice',
  Download = 'download',
  Delete = 'delete',
}

export enum ItemLabel {
  Download = 'Завантажити запис',
  Delete = 'Видалити запис',
}

export const MARK_VOICE_EXIST = 'Позначити присутність голосу';
export const MARK_VOICE_SKIP = 'Позначити відсутність голосу';

export const DELETE_MODAL_TITLE = 'Видалити запис?';
export const DELETE_MODAL_CONTENT = `Вибраний запис буде видалено з цього списку, а також з джерела, назавжди.`;
