import { Flex } from 'antd';
import { LoadingWrapper, ScrollUpToRefresh, SearchField, Table } from '@/shared/components';
import { EditUnitUserModal } from './components';
import { useUnitUsersTab } from './hooks';
import styles from './styles.module.css';

const UnitUsersTab = () => {
  const { search, handleSearch, users, isLoading, columns, handleScrollUpToReFetch, editUnitUserId, handleCloseModal } =
    useUnitUsersTab();

  return (
    <Flex
      className={styles.tabContent}
      vertical
    >
      <SearchField
        value={search}
        onSearch={handleSearch}
        isAsync
      />
      <LoadingWrapper
        isLoading={isLoading}
        isFixedOverlay
      >
        <ScrollUpToRefresh
          isScrollUpToRefreshEnabled={users.length > 0}
          onRefresh={handleScrollUpToReFetch}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={users}
          />
        </ScrollUpToRefresh>
      </LoadingWrapper>
      {editUnitUserId && (
        <EditUnitUserModal
          userId={editUnitUserId}
          onClose={handleCloseModal}
        />
      )}
    </Flex>
  );
};

export default UnitUsersTab;
