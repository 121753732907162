import { useUnitRouteInfo, useTableItemModalManager } from '@/entities/unit/hooks';
import { UnitUser } from '@/entities/unit/types';
import { UNIT_USERS_QUERY_KEY, UNIT_FREE_USERS_QUERY_KEY, UnitUserQueryParam } from '@/shared/constants';
import { useParams, useScrollUpToReFetch, useSearchParam } from '@/shared/hooks';
import { UnitRouteParams } from '@/shared/types';
import useGetFreeUsers from './useGetFreeUsers';
import useGetSearchedUsers from './useGetSearchedUsers';
import useGetUnitUsers from './useGetUnitUsers';
import useUnitUsersColumns from './useUnitUsersColumns';

const useUnitUsersTab = () => {
  const { unitId } = useParams<UnitRouteParams>();
  const [search, setSearch] = useSearchParam(UnitUserQueryParam.Search);
  const { isFreeUsersRoute } = useUnitRouteInfo();

  const { unitUsersList, getUnitUserRef, isUnitUsersFetching } = useGetUnitUsers({
    unitId: unitId ?? '',
    options: { enabled: !!unitId && !isFreeUsersRoute },
  });
  const { freeUsersList, getFreeUsersRef, isFreeUsersFetching } = useGetFreeUsers({
    options: { enabled: isFreeUsersRoute },
  });

  const usersList = isFreeUsersRoute ? freeUsersList : unitUsersList;
  const isLoading = (isFreeUsersRoute && isFreeUsersFetching) || (!isFreeUsersRoute && isUnitUsersFetching);
  const getUserRef = isFreeUsersRoute ? getFreeUsersRef : getUnitUserRef;

  const { itemId: editUnitUserId, handleOpenModal, handleCloseModal } = useTableItemModalManager<UnitUser['id']>();

  const searchedUsers = useGetSearchedUsers(usersList);
  const columns = useUnitUsersColumns({ getUserRef, handleOpenEditModal: handleOpenModal });

  const { handleScrollUpToReFetch: handleScrollUpToReFetchUnitUsers } = useScrollUpToReFetch([
    UNIT_USERS_QUERY_KEY,
    unitId,
  ]);

  const { handleScrollUpToReFetch: handleScrollUpToReFetchFreeUsers } = useScrollUpToReFetch([
    UNIT_FREE_USERS_QUERY_KEY,
  ]);

  const handleScrollUpToReFetch = async () => {
    if (isFreeUsersRoute) {
      await handleScrollUpToReFetchFreeUsers();
    } else {
      await handleScrollUpToReFetchUnitUsers();
    }
  };

  return {
    search,
    handleSearch: setSearch,
    users: searchedUsers,
    isLoading,
    columns,
    handleScrollUpToReFetch,
    editUnitUserId,
    handleCloseModal,
  };
};

export default useUnitUsersTab;
