import { useEffect } from 'react';
import { useAppSelector } from '@/shared/hooks';
import { invisibleTextService } from '@/shared/services';
import { selectCurrentUser } from '@/shared/slices';

const useUserFingerprint = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!currentUser) return;

    const handleCopy = (event: ClipboardEvent) => {
      const selectedText = window.getSelection()?.toString();
      if (!selectedText) return;

      const modifiedText = invisibleTextService.injectInvisibleText({
        originalText: selectedText,
        injectableInvisibleText: currentUser.id.toString(),
      });

      event.clipboardData?.setData('text/plain', modifiedText);
      event.preventDefault();
    };

    document.addEventListener('copy', handleCopy);
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, [currentUser]);
};

export default useUserFingerprint;
