import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { HexagonMapState as MapState } from './types';

const initialState: MapState = {
  hexagons: [],
  draftCells: [],
};

const hexagonMapSlice = createSlice({
  name: 'hexagonMap',
  initialState,
  reducers: {
    setHexagons: (state: MapState, action: PayloadAction<MapState['hexagons']>) => {
      state.hexagons = action.payload;
    },
    setDraftCells: (state: MapState, action: PayloadAction<MapState['draftCells']>) => {
      state.draftCells = action.payload;
    },
    resetHexagons: () => initialState,
  },
});

export const { setHexagons, setDraftCells, resetHexagons } = hexagonMapSlice.actions;

export const selectHexagons = (state: RootState) => state.hexagonMap.hexagons;
export const selectDraftCells = (state: RootState) => state.hexagonMap.draftCells;

export default hexagonMapSlice.reducer;
