import { ReactNode } from 'react';
import { Header } from '@/shared/components/Header';
import styles from './styles.module.css';

type Props = {
  className?: string;
  prefix?: string;
  title?: ReactNode;
  actions?: ReactNode;
};

export const MobileDetailsContainerHeader = ({ className, prefix, title, actions }: Props) => (
  <Header
    className={className}
    actions={actions}
  >
    <div className={styles.title}>
      {prefix} {title}
    </div>
  </Header>
);
