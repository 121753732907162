import { forwardRef } from 'react';
import { Form, Progress, theme } from 'antd';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import { CODE_TIMEOUT } from '@/entities/auth/constants';
import { InputRef, MaskedInput } from '@/shared/components';

interface Props extends MaskedInputProps {
  timerValue?: number;
}

export const MaskedCodeInput = forwardRef<InputRef, Props>(({ timerValue, mask, placeholder, ...props }, ref) => {
  const { status } = Form.Item.useStatus();
  const { token } = theme.useToken();
  const inputStatus = status === 'error' ? 'error' : undefined;
  const isTimeoutOver = Boolean(timerValue && timerValue >= CODE_TIMEOUT);

  return (
    <MaskedInput
      ref={ref}
      {...props}
      mask={mask}
      maskOptions={{ lazy: true }}
      placeholder={placeholder}
      disabled={isTimeoutOver}
      status={inputStatus}
      autoFocus
      suffix={
        timerValue ? (
          <Progress
            type="circle"
            strokeColor={token.colorPrimary}
            trailColor={token.colorBorderSecondary}
            percent={timerValue / (CODE_TIMEOUT / 100)}
            strokeWidth={20}
            size={14}
            showInfo={false}
            status="normal"
          />
        ) : undefined
      }
    />
  );
});

MaskedCodeInput.displayName = 'MaskedCodeInput';
