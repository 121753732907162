import { useEffect, useRef } from 'react';
import RecordActions from '@/entities/source/@fox/components/RecordsTab/components/RecordActions';
import {
  useFilesQueryKey,
  useFoxRecordsQueryParams,
  useGetFiles,
  useNavigateToRecord,
} from '@/entities/source/@fox/components/RecordsTab/hooks';
import { File } from '@/entities/source/@fox/types';
import { useParams, useReFetchInfiniteListOnTop, useScrollUpToReFetch, useScrollTopPosition } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import { DetailsHeader, MetadataDetails } from '../components';
import styles from '../styles.module.css';

const useMobileRecordsList = () => {
  const { sourceId } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();
  const navigateToRecord = useNavigateToRecord();
  const filesQueryKey = useFilesQueryKey();
  const recordsListWrapperRef = useRef<HTMLDivElement>(null);

  const { filesList, getFileRef, isFilesLoading, isFilesFetching } = useGetFiles({ queryParams });
  const { handleScrollUpToReFetch, isReFetchingByScrollUp } = useScrollUpToReFetch(filesQueryKey);
  const isLoading = (isFilesLoading || isFilesFetching) && !isReFetchingByScrollUp;

  const rowClassName = (item: File) => {
    if (!item.metadata.hasVoice || !item.metadata.isDecoded) return styles.halfTransparentRow;
  };

  const renderDetailsTitle = (file: File) => <DetailsHeader file={file} />;
  const renderDetailsComponent = () => <MetadataDetails />;

  const renderActions = (file: File, _onEdit: VoidFunction, onDelete: VoidFunction) =>
    file.metadata.isDecoded && (
      <RecordActions
        id={file.id}
        fileName={file.fileName}
        hasVoice={file.metadata.hasVoice}
        onDelete={onDelete}
      />
    );

  const handleItemClick = (item: File) => {
    navigateToRecord({
      routeParams: {
        sourceId,
        tabEntityId: item.id,
      },
      options: { withAllQueryParams: true },
    });
  };

  const { handleScroll, scrollTopPosition } = useScrollTopPosition();
  useReFetchInfiniteListOnTop({ queryKey: filesQueryKey, scrollTopPosition });

  useEffect(() => {
    recordsListWrapperRef.current?.scrollTo({ top: 0 });
  }, [queryParams]);

  return {
    filesList,
    getFileRef,
    isLoading,
    rowClassName,
    renderDetailsTitle,
    renderDetailsComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    handleScrollUpToReFetch,
    scrollTopPosition,
    recordsListWrapperRef,
  };
};

export default useMobileRecordsList;
