import styles from './styles.module.css';

interface Props {
  isVisible: boolean;
}

const Attention = ({ isVisible }: Props) => {
  if (!isVisible) return null;

  return <div className={styles.attention} />;
};

export default Attention;
