import { NotificationVideo } from '@/entities/notification/api/types';
import { useVideo } from './hooks';
import styles from './styles.module.css';

interface Props extends NotificationVideo {}

const Video = ({ url, mime }: Props) => {
  const { videoWrapperRef, videoWidth, videoHeight, shouldVideoBeRendered } = useVideo();

  return (
    <div ref={videoWrapperRef}>
      {shouldVideoBeRendered && (
        <video
          width={videoWidth}
          height={videoHeight}
          controls
          controlsList="nodownload"
          preload="none"
          className={styles.video}
        >
          <source
            src={url}
            type={mime}
          />
        </video>
      )}
    </div>
  );
};

export default Video;
