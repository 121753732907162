import { forwardRef } from 'react';
import { Input, Typography } from 'antd';
import classNames from 'classnames';
import commonInputStyles from '../commonInput.styles.module.css';
import { useTextArea } from './hooks';
import styles from './styles.module.css';
import { TextAreaProps, TextAreaRef } from './types';

const TextArea = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
  const {
    size,
    isHidden,
    isTransparent,
    isFocused,
    handleFocus,
    handleBlur,
    handleClick,
    handlePressEnter,
    showPseudoElement,
    pseudoElementValue,
  } = useTextArea(props, ref);
  const { className, isTransparentOnDesktop, overflowEllipsisRows, ...restProps } = props;

  return (
    <>
      <Input.TextArea
        ref={ref}
        size={size}
        classNames={{
          textarea: classNames({
            [styles.hiddenTextArea]: isHidden,
            [commonInputStyles.transparentInput]: isTransparent,
            className,
          }),
        }}
        {...restProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onPressEnter={handlePressEnter}
      />
      {showPseudoElement && (
        <div className={classNames({ [styles.hiddenElement]: isFocused }, styles.pseudoElement)}>
          <Typography.Paragraph
            className={styles.pseudoElementContent}
            onClick={handleClick}
            onKeyDown={handleClick}
            ellipsis={{
              rows: overflowEllipsisRows,
              tooltip: {
                overlayClassName: styles.tooltip,
                mouseLeaveDelay: 0,
              },
            }}
          >
            {String(pseudoElementValue)}
          </Typography.Paragraph>
        </div>
      )}
    </>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
