import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useFilesQueryKey } from '@/entities/source/@fox/components/RecordsTab/hooks';
import { File } from '@/entities/source/@fox/types';
import { PaginatedWithFoffset } from '@/shared/api';

const useUpdateRecordMetadataCache = (id?: File['id']) => {
  const queryClient = useQueryClient();
  const filesQueryKey = useFilesQueryKey();

  return (updatedMetadata: Partial<File['metadata']>) => {
    queryClient.setQueryData<InfiniteData<PaginatedWithFoffset<File>>>(
      filesQueryKey,
      (staleData): InfiniteData<PaginatedWithFoffset<File>> | undefined => {
        if (!staleData) return;
        const { pages } = staleData;

        const updatedPages = pages.map((page) => {
          return {
            ...page,
            models: page.models.map((file) => {
              if (file.id !== id) return file;
              return {
                ...file,
                metadata: {
                  ...file.metadata,
                  ...updatedMetadata,
                },
              };
            }),
          };
        });

        return {
          ...staleData,
          pages: updatedPages,
        };
      }
    );
  };
};

export default useUpdateRecordMetadataCache;
