import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { DrawMapState as MapState } from './types';

const initialState: MapState = {
  isPolygonEnabled: false,
  isRulerEnabled: false,
};

const drawMapSlice = createSlice({
  name: 'drawMap',
  initialState,
  reducers: {
    setIsPolygonEnabled: (state: MapState, action: PayloadAction<boolean>) => {
      state.isPolygonEnabled = action.payload;
    },
    setIsRulerEnabled: (state: MapState, action: PayloadAction<boolean>) => {
      state.isRulerEnabled = action.payload;
    },
    resetDrawState: () => initialState,
  },
});

export const { setIsPolygonEnabled, setIsRulerEnabled, resetDrawState } = drawMapSlice.actions;

export const selectIsPolygonEnabled = (state: RootState) => state.drawMap.isPolygonEnabled;
export const selectIsRulerEnabled = (state: RootState) => state.drawMap.isRulerEnabled;

export default drawMapSlice.reducer;
