import { Source } from '@/entities/source/types';
import { useParams } from '@/shared/hooks';
import { SourceRouteParams } from '@/shared/types';
import useGetSourceById, { UseGetSourceByIdParams } from './useGetSourceById';

interface Params<T> extends Pick<UseGetSourceByIdParams<T>, 'options'> {}

const useSource = <T = Source>({ options }: Params<T> = {}) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { source } = useGetSourceById<T>({ sourceId, options });

  return source;
};

export default useSource;
