import { useLocation } from 'react-router-dom';
import { TranscriptQueryParam } from '@/shared/constants';
import { useExtendActiveFilters, useNavigate } from '@/shared/hooks';

type Params = {
  sourceId: number;
  isCluster?: boolean;
};

const useSourceLink = ({ sourceId, isCluster }: Params) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const filterParams = useExtendActiveFilters({
    filterName: TranscriptQueryParam.Source,
    filterValue: String(sourceId),
  });

  const path = pathname;
  const queryString = filterParams;

  const sourceLinkHref = `${path}?${queryString}`;

  const handleSourceClick = () => {
    if (isCluster) return;

    navigate({
      pathname: path,
      options: {
        additionalQueryParams: queryString,
      },
    });
  };

  return {
    sourceLinkHref,
    handleSourceClick,
  };
};

export default useSourceLink;
