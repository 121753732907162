import { PropsWithChildren } from 'react';
import { Flex } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse } from '@kunukn/react-collapse';
import classNames from 'classnames';
import { Button } from '@/shared/components';
import { useReadMore } from './hooks';
import styles from './styles.module.css';

const TRANSITION = 'height 300ms cubic-bezier(0.4, 0, 0.2, 1)';
const MORE = 'Більше';
const ROLL_UP = 'Згорнути';

const ReadMore = ({ children }: PropsWithChildren) => {
  const { isOpen, handleOpenClick, handleCloseClick } = useReadMore();

  return (
    <>
      <Collapse
        isOpen={!isOpen}
        transition={TRANSITION}
      >
        <Flex justify="flex-end">
          <Button
            className={classNames(
              {
                [styles.invisible]: isOpen,
              },
              styles.button
            )}
            type="link"
            onClick={handleOpenClick}
          >
            {MORE}
            <DownOutlined className={styles.icon} />
          </Button>
        </Flex>
      </Collapse>
      <Collapse
        isOpen={isOpen}
        transition={TRANSITION}
      >
        {children}
        <Flex justify="flex-end">
          <Button
            onClick={handleCloseClick}
            type="link"
            className={styles.button}
          >
            {ROLL_UP}
            <UpOutlined className={styles.icon} />
          </Button>
        </Flex>
      </Collapse>
    </>
  );
};

export default ReadMore;
