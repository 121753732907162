import { useEffect, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';

// browser can do maximum 6 requests to one domain simultaneously
// as notifications are not critical data we need to wait vail initial requests are finished
// useIsInitialDataFetched returns true when initial requests finished
// if we need such logic in another place of application, this hook should be moved to shared folder.

const useIsInitialDataFetched = () => {
  const amountOfFetchingQueries = useIsFetching();
  const [isFetchingStarted, setIsFetchingStarted] = useState(false);
  const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);

  useEffect(() => {
    if (isFetchingStarted) return;

    if (amountOfFetchingQueries > 0) {
      setIsFetchingStarted(true);
    }
  }, [isFetchingStarted, amountOfFetchingQueries]);

  useEffect(() => {
    if (!isFetchingStarted) return;

    if (amountOfFetchingQueries === 0 && !isInitialDataFetched) {
      setIsInitialDataFetched(true);
    }
  }, [isFetchingStarted, amountOfFetchingQueries, isInitialDataFetched]);

  return isInitialDataFetched;
};

export default useIsInitialDataFetched;
