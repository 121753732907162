import { forwardRef } from 'react';
import { MaskedInput as RawMaskedInput } from 'antd-mask-input';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import classNames from 'classnames';
import { InputRef } from '@/shared/components';
import { useIsTabletOrMobile } from '@/shared/hooks';
import styles from './styles.module.css';

export const MaskedInput = forwardRef<InputRef, MaskedInputProps>(({ 'aria-invalid': isError, ...props }, ref) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  return (
    <RawMaskedInput
      ref={ref}
      size={isTabletOrMobile ? 'large' : 'middle'}
      {...props}
      className={classNames(styles.rawMaskedInput, {
        [styles.error]: isError,
      })}
    />
  );
});

MaskedInput.displayName = 'MaskedInput';
