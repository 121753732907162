import { MobileView } from '@/shared/constants';
import { useAppDispatch, useIsTablet, useMobileActiveViewQueryParam, usePersistedAuthData } from '@/shared/hooks';
import { setIsNavigationSidebarCollapsed, setNavigationDrawer } from '@/shared/slices';
import useAreNewNotificationsAvailable from './useAreNewNotificationsAvailable';

const useNotificationsNavigationButton = () => {
  const isTablet = useIsTablet();
  const dispatch = useAppDispatch();
  const { setMobileActiveView } = useMobileActiveViewQueryParam();
  const areNewNotificationsAvailable = useAreNewNotificationsAvailable();
  const { syncAuthDataManually } = usePersistedAuthData();

  const handleButtonClick = () => {
    syncAuthDataManually();
    setMobileActiveView?.(MobileView.PrimaryScreen, false);
    if (isTablet) dispatch(setIsNavigationSidebarCollapsed(true));
    dispatch(setNavigationDrawer('notifications'));
  };

  return {
    handleButtonClick,
    areNewNotificationsAvailable,
  };
};

export default useNotificationsNavigationButton;
