import { NotificationImage } from '@/entities/notification/api/types';

interface FormattedImage {
  url: string;
  width: number;
  aspectRatio: number;
}

export class ImageService {
  getImage({
    imageWrapperWidth,
    notificationImage,
  }: {
    imageWrapperWidth: number;
    notificationImage: NotificationImage;
  }) {
    const allImages = this.getAllImages(notificationImage);
    return this.getSuitableImage({ imageWrapperWidth, images: allImages });
  }

  private getAllImages(notificationImage: NotificationImage): FormattedImage[] {
    const baseImage = {
      url: notificationImage.url,
      width: notificationImage.width,
      aspectRatio: notificationImage.width / notificationImage.height,
    };

    const { formats } = notificationImage;

    if (!formats) return [baseImage];

    return Object.keys(formats).reduce(
      (accumulator, currentFormat) => {
        if (
          currentFormat !== 'large' &&
          currentFormat !== 'medium' &&
          currentFormat !== 'small' &&
          currentFormat !== 'thumbnail'
        )
          return accumulator;

        if (!formats[currentFormat]) return accumulator;

        return [
          ...accumulator,
          {
            url: formats[currentFormat]!.url,
            width: formats[currentFormat]!.width,
            aspectRatio: formats[currentFormat]!.width / formats[currentFormat]!.height,
          },
        ];
      },
      [baseImage]
    );
  }

  private getSuitableImage({ imageWrapperWidth, images }: { imageWrapperWidth: number; images: FormattedImage[] }) {
    const widthDifferences = images.map(({ width }) => {
      if (width > imageWrapperWidth) return width - imageWrapperWidth;
      return imageWrapperWidth - width;
    });

    let suitableImageIndex = 0;
    let suitableImageWidthDifference = widthDifferences[0];
    widthDifferences.forEach((width, index) => {
      if (width < suitableImageWidthDifference) {
        suitableImageWidthDifference = width;
        suitableImageIndex = index;
      }
    });

    return {
      url: images[suitableImageIndex].url,
      width: imageWrapperWidth,
      height: imageWrapperWidth / images[suitableImageIndex].aspectRatio,
    };
  }
}

export const imageService = new ImageService();
