import { NEW_FREQUENCY } from '@/entities/source/@fox/components/ScanningTab/constants';
import { LoadingWrapper, MobileListManager, ScrollUpToRefresh } from '@/shared/components';
import AddNewFoxFrequencyButton from '../AddNewFoxFrequencyButton';
import { useMobileFoxFrequenciesList } from './hooks';

type Props = {
  highlightedFoxFrequencyId: string | null;
};

const MobileFoxFrequenciesList = ({ highlightedFoxFrequencyId }: Props) => {
  const {
    isSourceOnline,
    foxFrequencies,
    isLoading,
    handleRowClassName,
    renderRow,
    renderDetailsTitle,
    renderDetailsComponent,
    renderCreateComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    refetchFoxFrequencies,
    scrollTopPosition,
    frequenciesListWrapperRef,
  } = useMobileFoxFrequenciesList({ highlightedFoxFrequencyId });

  return (
    <>
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={foxFrequencies.length > 0}
        onRefresh={refetchFoxFrequencies}
        hasMobileVirtualListChildren
        isRefreshTriggerDisabled={scrollTopPosition > 0}
      >
        <LoadingWrapper
          isLoading={isLoading}
          isFixedOverlay
        >
          <MobileListManager
            withEmptyPlaceholder
            listProps={{
              onScroll: handleScroll,
              listRef: frequenciesListWrapperRef,
            }}
            listData={foxFrequencies}
            rowClassName={handleRowClassName}
            renderRow={renderRow}
            onItemClick={handleItemClick}
            itemDetailsManager={{
              createTitlePrefix: NEW_FREQUENCY,
              renderDetailsTitle,
              renderDetailsComponent,
              renderCreateComponent,
              renderActions,
            }}
          />
        </LoadingWrapper>
      </ScrollUpToRefresh>
      {isSourceOnline && <AddNewFoxFrequencyButton />}
    </>
  );
};

export default MobileFoxFrequenciesList;
