import { useMemo } from 'react';
import { RoleName } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks/storeHooks';
import { selectCurrentUserRole } from '@/shared/slices';

interface Params {
  memoRoles: RoleName[];
}

const useIsUserRole = ({ memoRoles }: Params) => {
  const role = useAppSelector(selectCurrentUserRole);

  return useMemo(() => {
    return memoRoles.includes(role);
  }, [role, memoRoles]);
};

export default useIsUserRole;
