import { FILES_QUERY_KEY } from '@/shared/constants';
import { useParams } from '@/shared/hooks';
import { jsonService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import useFoxRecordsQueryParams from './useFoxRecordsQueryParams';

const useFilesQueryKey = () => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const queryParams = useFoxRecordsQueryParams();

  return [FILES_QUERY_KEY, sourceId, jsonService.stringify(queryParams)];
};

export default useFilesQueryKey;
