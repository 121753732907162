import { Flex } from 'antd';
import { Tag } from '@/shared/components';
import { useTopics } from './hooks';
import { TopicsProps } from './types';

const Topics = ({ topics }: TopicsProps) => {
  const { parsedTopics } = useTopics({ topics });

  if (!parsedTopics) return null;

  return (
    <Flex>
      {parsedTopics.map((topic) => (
        <Tag key={topic}>{topic}</Tag>
      ))}
    </Flex>
  );
};

export default Topics;
