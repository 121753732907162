import { MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react';
import { useElementSize, useWindowSize, useThrottleFn } from '@reactuses/core';
import { THROTTLE_DEFAULT } from '@/shared/constants';
import { CANVAS_ID, TOP_FREQUENCIES_AXIS_ID } from '../constants';
import { CursorClickPosition } from '../types';
import {
  getCanvasOffset,
  getEventTargetAttributeValue,
  getFrequenciesSelectedBoundaries,
  getSelectedBoundaries,
  getSelectedFrequenciesIds,
} from '../utils';
import { UseMouseEventsParams as Params } from './types';
import useCreateFrequency from './useCreateFrequency';

const INITIAL_CLICK = {
  down: 0,
  up: 0,
};

const useMouseEvents = ({
  currentStartFrequency,
  currentEndFrequency,
  onCreateFrequency,
  zoomData,
  updateZoomData,
  areMouseEventsEnabled,
  memoOnCanvasClick,
  memoFrequencies,
  memoOnSpectrumSelect,
  isZoomUpdateEnabled,
}: Params) => {
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const [cursorClickXPosition, setCursorClickXPosition] = useState<CursorClickPosition>(INITIAL_CLICK);
  const [cursorClickYPosition, setCursorClickYPosition] = useState<CursorClickPosition>(INITIAL_CLICK);
  const [cursorXPosition, setCursorXPosition] = useState<null | number>(null);
  const [cursorYPosition, setCursorYPosition] = useState<null | number>(null);
  const [isMouseDownOnCanvas, setIsMouseDownOnCanvas] = useState(false);
  const [isMouseDownOnWaterfall, setIsMouseDownOnWaterfall] = useState(false);

  const { run: throttledMemoOnSpectrumSelect } = useThrottleFn(memoOnSpectrumSelect, THROTTLE_DEFAULT);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [canvasWidth, canvasHeight] = useElementSize(canvasWrapperRef, { box: 'border-box' });

  const { isFrequencyCreating, memoHandleCreateFrequency } = useCreateFrequency({ onCreateFrequency });

  const boundGetCanvasOffsetX = getCanvasOffset.bind(null, windowWidth, canvasWidth);
  const boundGetCanvasOffsetY = getCanvasOffset.bind(null, windowHeight, canvasHeight);

  const getCursorPosition = (e: MouseEvent | TouchEvent) => {
    return {
      clientX: 'clientX' in e ? e.clientX : e.changedTouches[0].clientX,
      clientY: 'clientY' in e ? e.clientY : e.changedTouches[0].clientY,
    };
  };

  const handleMouseDown = (e: MouseEvent | TouchEvent) => {
    if (!areMouseEventsEnabled) return;
    const targetId = getEventTargetAttributeValue({ target: e.target, attributeName: 'id' });
    if (targetId === CANVAS_ID && !isMouseDownOnCanvas) {
      setIsMouseDownOnCanvas(true);
    } else if (targetId !== CANVAS_ID && isMouseDownOnCanvas) {
      setIsMouseDownOnCanvas(false);
    }

    const { clientX, clientY } = getCursorPosition(e);
    const canvasOffsetX = boundGetCanvasOffsetX(clientX);
    setCursorClickXPosition((prevState) => ({
      ...prevState,
      down: canvasOffsetX,
    }));
    const canvasOffsetY = boundGetCanvasOffsetY(clientY);
    setCursorClickYPosition((prevState) => ({
      ...prevState,
      down: canvasOffsetY,
    }));

    const topFrequenciesAxisElement = document.getElementById(TOP_FREQUENCIES_AXIS_ID);
    if (topFrequenciesAxisElement) {
      setIsMouseDownOnWaterfall(clientY > topFrequenciesAxisElement?.getBoundingClientRect().bottom);
    }
  };

  const handleMouseUp = (e: MouseEvent | TouchEvent) => {
    if (!areMouseEventsEnabled) return;
    if (!cursorClickXPosition.down) return;

    const { clientX, clientY } = getCursorPosition(e);
    const canvasOffsetX = boundGetCanvasOffsetX(clientX);
    setCursorClickXPosition((prevState) => ({
      ...prevState,
      up: canvasOffsetX,
    }));
    const canvasOffsetY = boundGetCanvasOffsetY(clientY);
    setCursorClickYPosition((prevState) => ({
      ...prevState,
      down: canvasOffsetY,
    }));
  };

  const handleMouseMove = (e: MouseEvent | TouchEvent) => {
    if (!areMouseEventsEnabled) return;
    const { clientX, clientY } = getCursorPosition(e);
    const canvasOffsetX = Math.round(boundGetCanvasOffsetX(clientX));
    const canvasOffsetY = Math.round(boundGetCanvasOffsetY(clientY));
    setCursorXPosition(canvasOffsetX);
    setCursorYPosition(canvasOffsetY);
  };

  const resetMouseClickData = () => {
    setCursorClickXPosition(INITIAL_CLICK);
    setCursorClickYPosition(INITIAL_CLICK);
  };

  useEffect(() => {
    if (!cursorClickXPosition.down || !cursorClickXPosition.up) return;

    if (isMouseDownOnCanvas && cursorClickXPosition.down === cursorClickXPosition.up) {
      memoOnCanvasClick();
    }

    if (cursorClickXPosition.down !== cursorClickXPosition.up) {
      if (isMouseDownOnWaterfall && isZoomUpdateEnabled) {
        updateZoomData({
          canvasWidth,
          cursorClickXPosition,
          currentEndFrequency,
          currentStartFrequency,
        });
      }
    }
    resetMouseClickData();
  }, [cursorClickXPosition, memoOnCanvasClick, isMouseDownOnCanvas, isMouseDownOnWaterfall, isZoomUpdateEnabled]);

  useEffect(() => {
    if (
      !cursorClickXPosition.down ||
      isMouseDownOnWaterfall ||
      !cursorXPosition ||
      cursorClickXPosition.up ||
      cursorClickXPosition.down === cursorXPosition
    )
      return;

    const selectedFrequenciesIds = getSelectedFrequenciesIds({
      frequenciesSelectedBoundaries: getFrequenciesSelectedBoundaries({
        canvasWidth,
        startFrequency: currentStartFrequency,
        endFrequency: currentEndFrequency,
        selectedBoundaries: getSelectedBoundaries(cursorClickXPosition.down, cursorXPosition),
      }),
      spectrogramFrequencies: memoFrequencies,
    });
    throttledMemoOnSpectrumSelect(selectedFrequenciesIds);
  }, [
    cursorClickXPosition,
    cursorXPosition,
    canvasWidth,
    currentStartFrequency,
    currentEndFrequency,
    memoFrequencies,
    isMouseDownOnWaterfall,
    throttledMemoOnSpectrumSelect,
  ]);

  useEffect(() => {
    if (
      zoomData ||
      !cursorXPosition ||
      !cursorYPosition ||
      !cursorClickXPosition.down ||
      cursorClickXPosition.up ||
      !areMouseEventsEnabled
    )
      return;

    const handleCanvasWrapperMouseLeave = () => {
      setCursorClickXPosition((prevState) => ({
        ...prevState,
        up: cursorXPosition,
      }));
      setCursorClickYPosition((prevState) => ({
        ...prevState,
        up: cursorYPosition,
      }));
    };

    canvasWrapperRef.current?.addEventListener('mouseleave', handleCanvasWrapperMouseLeave);
    return () => {
      canvasWrapperRef.current?.removeEventListener('mouseleave', handleCanvasWrapperMouseLeave);
    };
  }, [cursorClickXPosition.down, cursorClickXPosition.up, cursorXPosition, cursorYPosition, zoomData]);

  return {
    isFrequencyCreating,
    isMouseDownOnCanvas,
    isMouseDownOnWaterfall,
    cursorClickXPosition,
    cursorClickYPosition,
    cursorXPosition,
    canvasWidth,
    canvasWrapperRef,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    memoHandleCreateFrequency,
  };
};

export default useMouseEvents;
