import { z } from 'zod';

export const spectrumPublicationValueSchema = z.string();

export const spectrumPublicationSchema = z.object({
  data: z.object({
    value: spectrumPublicationValueSchema,
  }),
});

export const scannerPublicationValueItemSchema = z.object({
  frequency: z.number(),
  isBursting: z.boolean(),
  isDigital: z.boolean(),
  snr: z.number(),
});
