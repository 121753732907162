import { useState } from 'react';
import { getFiles } from '@/entities/source/@fox/api';
import { File } from '@/entities/source/@fox/types';
import { RequestQueryParams } from '@/shared/api';
import { FILES_QUERY_KEY } from '@/shared/constants';
import { useInfiniteListQuery, useParams, useUpdateFoffset, UseInfiniteListQueryOptions } from '@/shared/hooks';
import { jsonService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';

interface Params {
  options?: UseInfiniteListQueryOptions<File>;
  queryParams?: RequestQueryParams;
}

const useGetFiles = ({ options, queryParams }: Params = {}) => {
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const [foffset, setFoffset] = useState<string | null>(null);

  const {
    data,
    list: filesList,
    getListRef: getFileRef,
    isLoading: isFilesLoading,
    isFetching: isFilesFetching,
    refetch: refetchFiles,
  } = useInfiniteListQuery({
    enabled: Boolean(sourceId),
    ...options,
    // We intentionally do not observe foffset parameter in queryKey to avoid redundant requests
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [FILES_QUERY_KEY, sourceId, jsonService.stringify(queryParams)],
    queryFn: ({ pageParam = 1 }) =>
      getFiles({
        ...queryParams,
        source: [sourceId],
        page: pageParam,
        foffset: pageParam !== 1 ? foffset : null,
      }),
  });

  useUpdateFoffset({ data, setFoffset });

  return {
    filesList,
    refetchFiles,
    isFilesLoading,
    isFilesFetching,
    getFileRef,
  };
};

export default useGetFiles;
