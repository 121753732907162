import { useState } from 'react';
import { SEEN_NOTIFICATIONS_STORAGE_KEY } from '@/entities/notification/constants';
import { NotificationsDictionary } from '@/entities/notification/types';
import { useLocalStorage, useLocalStorageListener } from '@/shared/hooks';

const useSeenNotifications = () => {
  const { getStorageValue } = useLocalStorage<NotificationsDictionary>(SEEN_NOTIFICATIONS_STORAGE_KEY);
  const [seenNotifications, setSeenNotifications] = useState<NotificationsDictionary | null>(getStorageValue());

  useLocalStorageListener({
    key: SEEN_NOTIFICATIONS_STORAGE_KEY,
    memoOnLocalStorageChange: setSeenNotifications,
  });

  return seenNotifications ?? {};
};

export default useSeenNotifications;
