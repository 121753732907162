import type { FormInstance } from 'rc-field-form';
import type { RuleObject } from 'rc-field-form/lib/interface';
import { FoxKeyEncryption } from '@/entities/source/@fox/constants';
import { CreateFoxKeyPayload } from '@/entities/source/@fox/types';
import { exhaustiveGuard } from '@/shared/utils';

const getFoxKeyBodyValidationRule = ({ getFieldsValue }: FormInstance<Partial<CreateFoxKeyPayload>>): RuleObject => {
  let foxKeyBodyValidationRule: RuleObject = { required: true, message: "Key body є обов'язковим" };
  const { encryption, body } = getFieldsValue();

  switch (encryption) {
    case undefined:
      break;
    case FoxKeyEncryption.RC4:
    case FoxKeyEncryption.Hytera40:
      if (body?.length !== 10) {
        foxKeyBodyValidationRule = {
          required: true,
          min: 10,
          max: 10,
          message: 'Довжина Key body має бути 10 символів',
        };
      }
      break;
    case FoxKeyEncryption.AES256:
      if (body?.length !== 64) {
        foxKeyBodyValidationRule = {
          required: true,
          min: 64,
          max: 64,
          message: 'Довжина Key body має бути 64 символів',
        };
      }
      break;
    case FoxKeyEncryption.TYT380Enhanced:
    case FoxKeyEncryption.Hytera256:
    case FoxKeyEncryption.Hytera128:
      break;
    default:
      exhaustiveGuard(encryption);
      break;
  }

  return foxKeyBodyValidationRule;
};

export default getFoxKeyBodyValidationRule;
