import { BellOutlined } from '@ant-design/icons';
import { NavigationButton } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { useNotificationsNavigationButton } from './hooks';
import styles from './styles.module.css';

const NotificationsNavigationButton = () => {
  const { handleButtonClick, areNewNotificationsAvailable } = useNotificationsNavigationButton();

  return (
    <div className={styles.wrapper}>
      <NavigationButton
        Icon={BellOutlined}
        label={COPIES.NOTIFICATIONS}
        onClick={handleButtonClick}
      />
      {areNewNotificationsAvailable && <div className={styles.pulsingDot} />}
    </div>
  );
};

export default NotificationsNavigationButton;
