import { Typography } from 'antd';
import { TranscriptFrequencyProps } from '@/entities/transcript/types';
import { ParsedFrequencyValue } from './components';
import styles from './styles.module.css';
import useTranscriptFrequency from './useTranscriptFrequency';

const TranscriptFrequency = (props: TranscriptFrequencyProps) => {
  const { radioNetworkId, isNetwork, frequencyValue, parsedFrequencyValue, renderFrequencyLink } =
    useTranscriptFrequency(props);

  const parsedFrequency = <ParsedFrequencyValue frequencyValue={parsedFrequencyValue} />;

  return (
    <Typography.Title
      className={styles.frequency}
      level={5}
      data-testid="frequency"
    >
      {!radioNetworkId || isNetwork
        ? renderFrequencyLink({
            frequencyValue: frequencyValue ?? '',
            children: parsedFrequency,
          })
        : parsedFrequency}
    </Typography.Title>
  );
};

export default TranscriptFrequency;
