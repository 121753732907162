import { Modal as AntdModal, Flex, ModalProps } from 'antd';
import { MobileContainer } from '@/shared/components/mobile/MobileContainer';
import { Button } from '@/shared/components/ui/Button';
import { MobileView, COPIES } from '@/shared/constants';
import { useModal } from './hooks';

type Props = ModalProps & {
  onCancel: VoidFunction;
  withForm?: boolean;
};

export const Modal = ({
  title,
  onCancel,
  onOk,
  okText = COPIES.APPLY,
  cancelText = COPIES.CANCEL,
  open = false,
  confirmLoading,
  children,
  cancelButtonProps,
  okButtonProps,
  afterOpenChange,
  withForm = false,
  ...restModalProps
}: Props) => {
  const { isMobile, buttonSize, formSubmitButtonProps, handleAfterOpenChange } = useModal({
    afterOpenChange,
    withForm,
  });

  return isMobile ? (
    <MobileContainer
      title={title}
      viewType={MobileView.SecondaryScreen}
      isOpen={open}
      isLoading={confirmLoading}
      onClose={onCancel}
      shouldSynchronizeWithModalQueryParam
      footer={
        <Flex
          justify="space-between"
          gap={12}
        >
          <Button
            block
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            block
            type="primary"
            disabled={confirmLoading}
            onClick={onOk}
          >
            {okText}
          </Button>
        </Flex>
      }
    >
      {children}
    </MobileContainer>
  ) : (
    <AntdModal
      title={title}
      open={open}
      onCancel={onCancel}
      okText={okText}
      onOk={!withForm ? onOk : undefined}
      cancelButtonProps={{
        size: buttonSize,
        ...cancelButtonProps,
      }}
      okButtonProps={{
        size: buttonSize,
        ...formSubmitButtonProps,
        ...okButtonProps,
      }}
      confirmLoading={confirmLoading}
      afterOpenChange={handleAfterOpenChange}
      destroyOnClose
      data-testid="modal"
      {...restModalProps}
    >
      {children}
    </AntdModal>
  );
};
