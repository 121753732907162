import Icon from '@ant-design/icons';
import { SpectrogramToggle } from '@/shared/assets';
import { LayoutTwoColumns, ContentSize, Empty, Drawer } from '@/shared/components';
import { SPECTROGRAM_DRAWER_CLASSNAME } from '@/shared/constants';
import ToggleDetailsButton from '../ToggleDetailsButton';
import {
  FoxFrequenciesTable,
  SpectrogramController,
  Spectrogram,
  ScanningFiltersDrawer,
  MobileFoxFrequenciesList,
  SortButton,
  ScanningSearchField,
} from './components';
import { useScanningTab } from './hooks';
import styles from './styles.module.css';

const ScanningTab = () => {
  const {
    isMobile,
    sourceName,
    isSpectrogramShown,
    sourceIsOffline,
    sidebarStorageKey,
    sidebarMinWidth,
    isFiltersOpen,
    handleOpenFilters,
    handleCloseFilters,
    isMobileSpectrogramOpen,
    handleOpenMobileSpectrogram,
    handleCloseMobileSpectrogram,
    highlightedFoxFrequencyId,
    scrollToFoxFrequencyIndex,
    memoHandleScrollToSpectrogramFrequency,
  } = useScanningTab();

  const emptySpectrogram = (
    <Empty
      className={styles.sourceIsOffline}
      description={sourceIsOffline}
    />
  );

  return (
    <>
      <ContentSize
        renderChildren={({ contentWidth: wrapperWidth }) => (
          <LayoutTwoColumns
            drawerProps={{
              keyboard: false,
              maskClassName: SPECTROGRAM_DRAWER_CLASSNAME,
            }}
            isSidebarRight
            sidebarMinWidth={sidebarMinWidth}
            sidebarDefaultWidth={wrapperWidth / 2}
            sidebarMaxWidth={wrapperWidth * 0.75}
            storageKey={sidebarStorageKey}
            panel={
              isSpectrogramShown ? (
                <ContentSize
                  renderChildren={({ contentWidth }) => (
                    <SpectrogramController
                      SpectrogramComponent={Spectrogram}
                      isSpectrogramEnabled={contentWidth >= sidebarMinWidth - 1}
                      memoOnFrequencyClick={memoHandleScrollToSpectrogramFrequency}
                    />
                  )}
                />
              ) : (
                emptySpectrogram
              )
            }
            isMobileViewCollapsed
          >
            {({ isSidebarVisible, toggleSidebarVisibility }) => (
              <>
                <ScanningSearchField
                  onFilterClick={handleOpenFilters}
                  additionalControlsLeft={<SortButton />}
                  additionalControlsRight={
                    <ToggleDetailsButton
                      onClick={isMobile ? handleOpenMobileSpectrogram : toggleSidebarVisibility}
                      isActive={isSidebarVisible}
                      icon={<Icon component={SpectrogramToggle} />}
                    />
                  }
                />
                {isMobile ? (
                  <MobileFoxFrequenciesList highlightedFoxFrequencyId={highlightedFoxFrequencyId} />
                ) : (
                  <FoxFrequenciesTable
                    highlightedFoxFrequencyId={highlightedFoxFrequencyId}
                    scrollToRowIndex={scrollToFoxFrequencyIndex ?? undefined}
                  />
                )}
              </>
            )}
          </LayoutTwoColumns>
        )}
      />
      <ScanningFiltersDrawer
        isOpen={isFiltersOpen}
        onClose={handleCloseFilters}
      />
      {isMobile && (
        <Drawer
          title={sourceName}
          open={isMobileSpectrogramOpen}
          onClose={handleCloseMobileSpectrogram}
        >
          {isSpectrogramShown ? (
            <SpectrogramController
              SpectrogramComponent={Spectrogram}
              isSpectrogramEnabled
              memoOnFrequencyClick={memoHandleScrollToSpectrogramFrequency}
            />
          ) : (
            emptySpectrogram
          )}
        </Drawer>
      )}
    </>
  );
};

export default ScanningTab;
