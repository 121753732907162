import { RefObject, useEffect } from 'react';
import { useThrottleFn } from '@reactuses/core';
import { Key } from 'ts-key-enum';
import { MINUS_KEY, PLUS_KEY, THROTTLE_DEFAULT } from '@/shared/constants';
import { hotkeysHelperService } from '@/shared/services';

interface Params {
  spectrogramRef: RefObject<HTMLElement>;
  memoOnAddPress: VoidFunction;
  memoOnSubtractPress: VoidFunction;
  memoOnArrowLeftPress: VoidFunction;
  memoOnArrowRightPress: VoidFunction;
  memoOnScrollDown: VoidFunction;
  memoOnScrollUp: VoidFunction;
}

const useSpectrogramEventHandlers = ({
  spectrogramRef,
  memoOnAddPress,
  memoOnSubtractPress,
  memoOnArrowRightPress,
  memoOnArrowLeftPress,
  memoOnScrollDown,
  memoOnScrollUp,
}: Params) => {
  const { run: throttledMemoOnScrollDown } = useThrottleFn(memoOnScrollDown, THROTTLE_DEFAULT);
  const { run: throttledMemoOnScrollUp } = useThrottleFn(memoOnScrollUp, THROTTLE_DEFAULT);

  useEffect(() => {
    const controller = new AbortController();

    const handleWheel = (e: WheelEvent) => {
      if (e.deltaY < 0) {
        throttledMemoOnScrollUp();
      } else if (e.deltaY > 0) {
        throttledMemoOnScrollDown();
      }
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (hotkeysHelperService.getIsInputOrTextarea(e)) return;

      if (e.key === PLUS_KEY) {
        memoOnAddPress();
      }

      if (e.key === MINUS_KEY) {
        memoOnSubtractPress();
      }

      if (e.key === Key.ArrowLeft) {
        memoOnArrowLeftPress();
      }

      if (e.key === Key.ArrowRight) {
        memoOnArrowRightPress();
      }
    };

    spectrogramRef.current?.addEventListener('wheel', handleWheel, { signal: controller.signal });

    document.addEventListener('keydown', handleKeydown, { signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [
    memoOnAddPress,
    memoOnArrowLeftPress,
    memoOnArrowRightPress,
    memoOnSubtractPress,
    spectrogramRef,
    throttledMemoOnScrollDown,
    throttledMemoOnScrollUp,
  ]);
};

export default useSpectrogramEventHandlers;
