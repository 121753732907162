import { Typography } from 'antd';
import styles from './styles.module.css';
import TranscriptMessageProps from './types';
import { useTranscriptMessage } from './useTranscriptMessage';

const TranscriptMessage = (props: TranscriptMessageProps) => {
  const { parsedMessage } = useTranscriptMessage(props);
  const { isCluster, transcriptId, networkId } = props;

  return (
    <Typography.Text data-testid="content">
      <pre
        className={styles.transcriptMessage}
        data-cluster={isCluster}
        data-transcript-id={transcriptId}
        data-network-id={networkId}
        dangerouslySetInnerHTML={{
          __html: parsedMessage,
        }}
      />
    </Typography.Text>
  );
};

export default TranscriptMessage;
