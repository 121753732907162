import {
  useGetNotifications,
  useGetNotificationsQueryParams,
  useSeenNotifications,
} from '@/entities/notification/hooks';
import { useAppSelector, useTabVisibilityChange, usePersistedAuthData, useTimestampInterval } from '@/shared/hooks';
import { selectNavigationDrawer } from '@/shared/slices';
import { selectSortedNotificationsByDate } from '../utils';
import useIsInitialDataFetched from './useIsInitialDataFetched';
import useRefetchNotificationsOnDrawerOpen from './useRefetchNotificationsOnDrawerOpen';
import useSaveSeenNotifications from './useSaveSeenNotifications';

const ONE_HOUR = 60_000 * 60;

const useNotificationsDrawer = () => {
  const navigationDrawer = useAppSelector(selectNavigationDrawer);
  const isDrawerOpen = navigationDrawer === 'notifications';
  const { syncAuthDataManually } = usePersistedAuthData();

  const isInitialDataFetched = useIsInitialDataFetched();
  const queryParams = useGetNotificationsQueryParams();
  const { notifications, isFetching, refetch } = useGetNotifications({
    queryParams,
    options: {
      enabled: isInitialDataFetched,
      select: selectSortedNotificationsByDate,
    },
  });
  const isEmpty = isFetching || notifications.length === 0;

  const seenNotifications = useSeenNotifications();

  useSaveSeenNotifications({ memoNotifications: notifications, isSavingEnabled: isDrawerOpen, savingDelay: 3000 });

  useTabVisibilityChange({ memoOnTabBecameActive: syncAuthDataManually });

  useTimestampInterval({
    callbackInterval: ONE_HOUR,
    memoCallback: refetch,
  });

  useRefetchNotificationsOnDrawerOpen({ isDrawerOpen, memoRefetch: refetch });

  return {
    isOpen: isDrawerOpen,
    isFetching,
    isEmpty,
    notifications,
    seenNotifications,
  };
};

export default useNotificationsDrawer;
