import { Flex } from 'antd';
import classNames from 'classnames';
import type { Notification as NotificationType } from '@/entities/notification/api/types';
import { Media, Body, Date, Topics, Title, Attention } from './components';
import styles from './styles.module.css';

interface Props {
  memoNotification: NotificationType;
  isNew: boolean;
}

const Notification = ({ memoNotification, isNew }: Props) => {
  return (
    <Flex
      vertical
      gap={12}
      className={styles.wrapper}
    >
      <Flex
        justify="space-between"
        align="center"
      >
        <Topics topics={memoNotification.topic} />
        <Flex
          justify="flex-end"
          align="center"
          gap={4}
          className={classNames({
            [styles.headerRightColumnWrapper]: !memoNotification.topic,
          })}
        >
          <Date date={memoNotification.date} />
          <Attention isVisible={isNew} />
        </Flex>
      </Flex>
      <Title title={memoNotification.title} />
      <Media memoMedia={memoNotification.media} />
      <Body
        shortDescription={memoNotification.shortDescription}
        fullDescription={memoNotification.fullDescription}
        button={memoNotification.button}
      />
    </Flex>
  );
};

export default Notification;
