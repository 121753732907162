import { useRef, useMemo, useEffect } from 'react';
import { theme } from 'antd';
import { useUnmount } from '@reactuses/core';
import { useWavesurfer as useWs } from '@wavesurfer/react';
import Minimap from 'wavesurfer.js/dist/plugins/minimap';
import { NEUTRAL_COLOR_PALETTE, STATUS_TYPES } from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { useAppSelector } from '@/shared/hooks';
import { sentryService } from '@/shared/services';
import { selectIsDarkMode } from '@/shared/slices';
import { AudioPlayerProps } from '../type';
import { renderFunction } from '../utils';
import useWavesurferStyles from './useWavesurferStyles';

const { gray4, gray5, gray6, gray7, gray10 } = NEUTRAL_COLOR_PALETTE;

const PROGRESS_COLOR = '#FF4D4F';
const FAILED_TO_LOAD_AUDIO = 'Не вдалося завантажити аудіо';

interface Params extends Pick<AudioPlayerProps, 'memoSettings' | 'url' | 'duration' | 'stringifiedPeaks'> {}

const useWavesurfer = ({ memoSettings: { audioRate }, url, stringifiedPeaks, duration }: Params) => {
  const { token } = theme.useToken();
  const { openNotification } = useNotificationContext();
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const containerRef = useRef(null);
  const waveSurferOptions = useMemo(
    () => ({
      container: containerRef,
      dragToSeek: true,
      height: 24,
      normalize: false,
      waveColor: isDarkMode ? gray7 : gray5,
      progressColor: PROGRESS_COLOR,
      cursorColor: PROGRESS_COLOR,
      renderFunction,
      audioRate,
      plugins: [
        Minimap.create({
          dragToSeek: true,
          height: 32,
          cursorWidth: 0,
          waveColor: isDarkMode ? gray7 : gray6,
          progressColor: isDarkMode ? gray4 : gray10,
          overlayColor: token.colorBgContainer,
          barWidth: 2,
          barGap: 2,
        }),
      ],
    }),
    [isDarkMode, token.colorBgContainer]
  );

  const { wavesurfer, currentTime, isPlaying, isReady } = useWs(waveSurferOptions);

  useWavesurferStyles(wavesurfer);

  const loadedAudioDuration = wavesurfer?.getDuration() ?? 0;

  useEffect(() => {
    if (!wavesurfer || !url) return;

    const loadParams: Parameters<typeof wavesurfer.load> = [url];
    if (stringifiedPeaks && duration) {
      const peaks = stringifiedPeaks.split('').map(Number);
      loadParams.push([peaks]);
      loadParams.push(duration);
    }

    if (wavesurfer.isPlaying()) wavesurfer.stop();
    wavesurfer.empty();
    wavesurfer
      .load(...loadParams)
      .then(() => {
        if (audioRate) {
          wavesurfer.setPlaybackRate(audioRate);
        }
      })
      .catch((exception) => {
        openNotification({
          type: 'error',
          title: STATUS_TYPES.ERROR,
          description: FAILED_TO_LOAD_AUDIO,
        });
        sentryService.captureExpandedException({
          exception,
          extraData: {
            name: 'wavesurfer.load exception',
            value: { url, peaks: stringifiedPeaks, duration },
          },
        });
      });
  }, [wavesurfer, url, stringifiedPeaks, duration]);

  useEffect(() => {
    wavesurfer?.setOptions({ audioRate });
  }, [wavesurfer, audioRate]);

  useUnmount(() => wavesurfer?.destroy());

  return {
    wavesurfer,
    containerRef,
    currentTime,
    isPlaying,
    isReady,
    loadedAudioDuration,
  };
};

export default useWavesurfer;
