import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type Params<T> = {
  filterName: T;
  filterValue: string;
};

const useExtendActiveFilters = <T extends string>({ filterName, filterValue }: Params<T>) => {
  const { search } = useLocation();

  return useMemo(() => {
    const filters = new URLSearchParams(search);

    filters.set(filterName, filterValue);

    return filters.toString();
  }, [filterName, filterValue, search]);
};

export default useExtendActiveFilters;
