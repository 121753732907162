import { Flex, Space, Typography } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.css';

type Props = {
  label: string;
  unit: string;
  value: string;
};

const DrawLegend = ({ label, unit, value }: Props) => {
  if (!value) return null;

  return (
    <div className={styles.legend}>
      <Flex
        vertical
        align="center"
      >
        <Typography.Text className={styles.text}>{label}</Typography.Text>
        <Space>
          <Typography.Text className={classnames(styles.text, styles.value)}>{value}</Typography.Text>
          <Typography.Text className={styles.text}>{unit}</Typography.Text>
        </Space>
      </Flex>
    </div>
  );
};

export default DrawLegend;
