import { Form } from 'antd';
import UnitSelect from '@/entities/unit/components/UnitSelect';
import { Modal, Input } from '@/shared/components';
import { normalizeFormItem } from '@/shared/utils';
import { useEditUnitUserModal } from './hooks';
import { EditUnitUserModalProps } from './types';

const GROUP_NAME = 'groupId';
const GROUP_LABEL = 'Група доступів';
const GROUP_PLACEHOLDER = 'Оберіть групу доступів';

const EditUnitUserModal = ({ userId, onClose }: EditUnitUserModalProps) => {
  const {
    form,
    modalTitle,
    modalOkText,
    fullNameFieldLabel,
    fullNameFieldName,
    fullNameInputPlaceholder,
    fullNameFieldValidation,
    isUnitUserUpdating,
    handleFormFinish,
    renderUserRoleSelect,
    renderAccessGroupSelect,
  } = useEditUnitUserModal({
    userId,
    onClose,
  });

  return (
    <Modal
      destroyOnClose
      open={!!userId}
      title={modalTitle}
      okText={modalOkText}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={isUnitUserUpdating}
      withForm
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
      >
        <Form.Item
          label={fullNameFieldLabel}
          name={fullNameFieldName}
          rules={fullNameFieldValidation}
          normalize={normalizeFormItem}
        >
          <Input placeholder={fullNameInputPlaceholder} />
        </Form.Item>
        {renderUserRoleSelect?.()}
        {renderAccessGroupSelect?.({
          name: GROUP_NAME,
          label: GROUP_LABEL,
          placeholder: GROUP_PLACEHOLDER,
          required: true,
        })}
        <UnitSelect required />
      </Form>
    </Modal>
  );
};

export default EditUnitUserModal;
