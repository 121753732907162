export const FOX_SIDEBAR_MIN_WIDTH = 240;
export const FOX_SIDEBAR_DEFAULT_WIDTH = 400;
export const FOX_SIDEBAR_MAX_WIDTH = 600;

export enum FoxFrequencyRangeLimitation {
  Min = 100,
  Max = 500,
}

export enum FoxFrequencyStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum FoxFrequencyEncryptionType {
  UNENCRYPTED = 'UNENCRYPTED',
  UNKNOWN = 'UNKNOWN',
  BASIC = 'BASIC',
  RC4 = 'RC4',
  Hytera40 = 'Hytera40',
  Hytera128 = 'Hytera128',
  Hytera256 = 'Hytera256',
  HyteraFull = 'HyteraFull',
  AES128 = 'AES128',
  AES256 = 'AES256',
  TYT380Basic = 'TYT380Basic',
  TYT380Enhanced = 'TYT380Enhanced',
}

export enum FoxRecordSignalType {
  Digital = 'digital',
  Analog = 'analog',
}

export enum FoxKeyEncryption {
  RC4 = 'RC4',
  AES256 = 'AES256',
  Hytera40 = 'Hytera40',
  TYT380Enhanced = 'TYT380Enhanced', // temporary encryption type, will be removed in future from Fox side
  Hytera256 = 'Hytera256', // temporary encryption type, will be removed in future from Fox side
  Hytera128 = 'Hytera128', // temporary encryption type, will be removed in future from Fox side
}

export const FOX_SCANNING_FILTERS_STORAGE_KEY = 'fox-scanning-filters';

export const FOX_RECORDS_FILTERS_STORAGE_KEY = 'fox-records-filters';
