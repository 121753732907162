import { selectCallingElementPosition, selectMarkerCoordinates } from '@/entities/map/slices';
import { useAppSelector } from '@/shared/hooks';
import { getModalMapPosition } from './utils';

const useModalMap = () => {
  const callingElementPosition = useAppSelector(selectCallingElementPosition);
  const markerCoordinates = useAppSelector(selectMarkerCoordinates);

  const modalPosition = getModalMapPosition({
    callingElementPosition,
    modalMapWidth: 320,
    spaceBetweenMapAndCallingElement: 15,
  });

  return {
    modalPosition,
    markerCoordinates,
  };
};

export default useModalMap;
