import { useRecordsFilterButtonCount } from '@/entities/source/@fox/components/RecordsTab/hooks';
import useFiltersManager from './useFiltersManager';
import useOptions from './useOptions';
import useSaveRecordsFilter from './useSaveRecordsFilter';

const useRecordsFiltersDrawer = () => {
  const activeFiltersCount = useRecordsFilterButtonCount();
  const {
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoDateRange,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    ...restFiltersData
  } = useFiltersManager();

  useSaveRecordsFilter({
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    memoDateRange,
  });

  const { isLoading, options } = useOptions();

  return {
    hasActiveFilters: activeFiltersCount > 0,
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoDateRange,
    isLoading,
    ...options,
    ...restFiltersData,
  };
};

export default useRecordsFiltersDrawer;
