import { useState, useCallback, useRef } from 'react';
import { Stack } from '@/shared/services';
import { ZoomData, UpdateZoomDataParams, SpectrogramProps } from '../types';
import {
  getSelectedBoundaries,
  getZoomData,
  getFrequenciesSelectedBoundaries,
  getZoomDataShiftedRight,
  getZoomDataShiftedLeft,
  getOneStepInData,
  validateFrequenciesSelectedBoundaries,
} from '../utils';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {}

const useZoomData = ({ startFrequency, endFrequency }: Params) => {
  const [zoomData, setZoomData] = useState<ZoomData>(null);
  const [isLowestZoomLevel, setIsLowestZoomLevel] = useState(false);
  const zoomStack = useRef<Stack<ZoomData>>(new Stack<ZoomData>());

  const updateZoomData = ({
    canvasWidth,
    cursorClickXPosition,
    currentStartFrequency,
    currentEndFrequency,
  }: UpdateZoomDataParams) => {
    const selectedBoundaries = getSelectedBoundaries(cursorClickXPosition.down, cursorClickXPosition.up);
    const frequenciesSelectedBoundaries = getFrequenciesSelectedBoundaries({
      canvasWidth,
      startFrequency: currentStartFrequency,
      endFrequency: currentEndFrequency,
      selectedBoundaries,
    });
    const { isRestrictedZoomLevel, validFrequenciesSelectedBoundaries } = validateFrequenciesSelectedBoundaries({
      frequenciesSelectedBoundaries,
    });

    if (isRestrictedZoomLevel) {
      setIsLowestZoomLevel(true);
    }

    const newZoomData = getZoomData({
      frequenciesSelectedBoundaries: validFrequenciesSelectedBoundaries,
      startFrequency,
      endFrequency,
    });
    zoomStack.current.push(newZoomData);
    setZoomData(newZoomData);

    window.getSelection()?.removeAllRanges();
  };

  const memoHandleResetZoomData = useCallback(() => {
    setZoomData(null);
    setIsLowestZoomLevel(false);
    zoomStack.current.reset();
  }, []);

  const memoHandleZoomOneStepOut = useCallback(() => {
    zoomStack.current.pop();
    const lastZoomData = zoomStack.current.peek();

    if (!lastZoomData) {
      memoHandleResetZoomData();
      return;
    }

    setZoomData(lastZoomData);
    if (isLowestZoomLevel) {
      setIsLowestZoomLevel(false);
    }
  }, [memoHandleResetZoomData, isLowestZoomLevel]);

  const memoHandleZoomOneStepIn = useCallback(() => {
    if (isLowestZoomLevel) return;

    const { isRestrictedZoomLevel, frequenciesSelectedBoundaries } = getOneStepInData({
      zoomData,
      startFrequency,
      endFrequency,
    });

    if (isRestrictedZoomLevel) {
      setIsLowestZoomLevel(true);
    }

    const newZoomData = getZoomData({
      frequenciesSelectedBoundaries,
      startFrequency,
      endFrequency,
    });
    zoomStack.current.push(newZoomData);
    setZoomData(newZoomData);
  }, [endFrequency, isLowestZoomLevel, startFrequency, zoomData]);

  const moveHandleShiftZoomedDataLeft = useCallback(() => {
    const newZoomData = getZoomDataShiftedLeft({
      zoomData,
      startFrequency,
      endFrequency,
    });

    if (!newZoomData) return;

    setZoomData(newZoomData);
  }, [endFrequency, startFrequency, zoomData]);

  const moveHandleShiftZoomedDataRight = useCallback(() => {
    const newZoomData = getZoomDataShiftedRight({
      zoomData,
      startFrequency,
      endFrequency,
    });

    if (!newZoomData) return;

    setZoomData(newZoomData);
  }, [endFrequency, startFrequency, zoomData]);

  return {
    zoomData,
    updateZoomData,
    isLowestZoomLevel,
    memoHandleResetZoomData,
    memoHandleZoomOneStepOut,
    memoHandleZoomOneStepIn,
    moveHandleShiftZoomedDataLeft,
    moveHandleShiftZoomedDataRight,
  };
};

export default useZoomData;
