import { useEffect, useState } from 'react';
import { useSearchParam } from '@/shared/hooks/routingHooks/useSearchParam';

const TRUE = 'true';

enum QueryParam {
  MobileModal = 'modal',
}

export const useSyncMobileModalStateWithQueryParam = (
  isModalOpen: boolean,
  shouldSync: boolean,
  closeModal?: VoidFunction
) => {
  const [, setModal] = useSearchParam(QueryParam.MobileModal);
  const [isModalOpenStored, setIsModalOpenStored] = useState(false);

  useEffect(() => {
    if (!shouldSync) return;

    if (isModalOpen && !isModalOpenStored) {
      setIsModalOpenStored(true);
      setModal(TRUE, false);
    }

    if (isModalOpenStored && !isModalOpen) {
      setIsModalOpenStored(false);
      setModal('');
    }
  }, [shouldSync, isModalOpen, isModalOpenStored]);

  useEffect(() => {
    if (!shouldSync || !isModalOpenStored || !closeModal) return;
    window.addEventListener('popstate', closeModal);
    return () => {
      window.removeEventListener('popstate', closeModal);
    };
  }, [isModalOpenStored]);
};
