import { FC } from 'react';
import { Space } from 'antd';
import { Icon } from '@/shared/components';

type Props = {
  icon: FC;
  label: string;
};

const ContextMenuItem = ({ icon, label }: Props) => {
  return (
    <Space>
      <Icon component={icon} />
      {label}
    </Space>
  );
};

export default ContextMenuItem;
