import { CallingElementPosition } from '@/entities/map/slices';

interface Params {
  callingElementPosition: CallingElementPosition | null;
  modalMapWidth: number;
  modalMapHeight?: number;
  spaceBetweenMapAndCallingElement: number;
}

const getModalMapPosition = ({
  callingElementPosition,
  modalMapWidth,
  modalMapHeight = modalMapWidth,
  spaceBetweenMapAndCallingElement,
}: Params) => {
  if (!callingElementPosition) return;

  const { top, left, right } = callingElementPosition;

  const availableHeight = window.innerHeight - top;
  const croppedHeight = modalMapHeight - availableHeight;
  const fixedTop = croppedHeight > 0 ? top - croppedHeight : top;

  if (left - spaceBetweenMapAndCallingElement < modalMapWidth) {
    return {
      top: fixedTop,
      left: right + spaceBetweenMapAndCallingElement,
    };
  }

  return {
    top: fixedTop,
    left: left - modalMapWidth - spaceBetweenMapAndCallingElement,
  };
};

export default getModalMapPosition;
