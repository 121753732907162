import { useResponsiveManager } from './hooks';
import ResponsiveManagerProps from './types';

const ResponsiveManager = ({ desktop, tablet, mobile }: ResponsiveManagerProps) => {
  const { shouldRenderTablet, shouldRenderMobile } = useResponsiveManager({ tablet, mobile });

  if (shouldRenderMobile) return mobile;

  if (shouldRenderTablet) return tablet;

  return desktop;
};

export default ResponsiveManager;
