import { Typography } from 'antd';
import { Transcription } from '@/entities/transcript/types';
import { Tooltip } from '@/shared/components';
import { useRenderPropsContext } from '@/shared/context';
import { TranscriptRenderProps } from '@/shared/types';
import styles from './styles.module.css';
import { getTooltipTitle } from './utils';

interface Props {
  creatorName: Transcription['creatorName'];
  sourceName: Transcription['sourceName'];
  sourceType: 'signal' | 'whatsapp';
  tooltipMessage: string | null;
}

const TranscriptMessengerName = ({ creatorName, sourceName, sourceType, tooltipMessage }: Props) => {
  const { renderSourceTypeIcon } = useRenderPropsContext<TranscriptRenderProps>();
  const tooltipTitle = getTooltipTitle({ creatorName, sourceName, tooltipMessage });

  return (
    <>
      {renderSourceTypeIcon?.({
        type: sourceType,
      })}
      <Tooltip
        overlayClassName={styles.tooltipOverlay}
        title={tooltipTitle}
        isDesktopOnly
      >
        <Typography.Text ellipsis>{creatorName || sourceName}</Typography.Text>
      </Tooltip>
    </>
  );
};

export default TranscriptMessengerName;
