import { Row, Col } from 'antd';
import { FiltersDrawer, FormDateRangePicker, Select } from '@/shared/components';
import { COPIES, FoxRecordsQueryParam } from '@/shared/constants';
import { SignalType, DecodingType, Title } from './components';
import { GROUP_ADDRESS, SOURCE_ADDRESS, ENCRYPTION_TYPE } from './constants';
import { useRecordsFiltersDrawer } from './hooks';
import styles from './styles.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RecordsFiltersDrawer = ({ isOpen, onClose }: Props) => {
  const {
    hasActiveFilters,
    isLoading,
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    encryptionTypeOptions,
    frequencyOptions,
    sourceAddressOptions,
    groupAddressOptions,
    memoDateRange,
    handleEncryptionTypeChange,
    handleFrequencyChange,
    handleSourceAddressChange,
    handleGroupAddressChange,
    handleDateRangeChange,
  } = useRecordsFiltersDrawer();

  return (
    <FiltersDrawer
      title={COPIES.FILTERS}
      open={isOpen}
      onClose={onClose}
      hasActiveFilters={hasActiveFilters}
      filterQueryParam={FoxRecordsQueryParam}
    >
      <Row className={styles.wrapper}>
        <Col>
          <Title value={ENCRYPTION_TYPE} />
          <Select
            mode="multiple"
            value={memoEncryptionType}
            options={encryptionTypeOptions}
            loading={isLoading}
            onChange={handleEncryptionTypeChange}
            optionFilterProp="value"
          />
        </Col>
        <Col>
          <Title value={COPIES.FREQUENCY} />
          <Select
            mode="multiple"
            value={memoFrequency}
            options={frequencyOptions}
            loading={isLoading}
            onChange={handleFrequencyChange}
            optionFilterProp="value"
          />
        </Col>
        <Col>
          <Title value={SOURCE_ADDRESS} />
          <Select
            mode="multiple"
            value={memoSourceAddress}
            options={sourceAddressOptions}
            loading={isLoading}
            onChange={handleSourceAddressChange}
            optionFilterProp="value"
          />
        </Col>
        <Col>
          <Title value={GROUP_ADDRESS} />
          <Select
            mode="multiple"
            value={memoGroupAddress}
            options={groupAddressOptions}
            loading={isLoading}
            onChange={handleGroupAddressChange}
            optionFilterProp="value"
          />
        </Col>
        <SignalType />
        <DecodingType />
        <FormDateRangePicker
          value={memoDateRange}
          onChange={handleDateRangeChange}
          formItemProps={{
            labelCol: { span: 24 },
          }}
        />
      </Row>
    </FiltersDrawer>
  );
};

export default RecordsFiltersDrawer;
