import { useState, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useGetFoxSettings, useSaveFoxSettings, useUpdateDeviceRange } from '@/entities/source/@fox';
import { FoxFrequencyRangeLimitation } from '@/entities/source/@fox/constants';
import { useGetSourceById } from '@/entities/source/hooks';
import { NOTIFICATION_TYPES } from '@/shared/constants';
import validations from '@/shared/constants/validation.json';
import { useNotificationContext } from '@/shared/context';
import { useParams, useOpenFormItemValidationNotification } from '@/shared/hooks';
import { validationService } from '@/shared/services';
import { SourceRouteParams } from '@/shared/types';
import { SettingsFormData } from '../types';
import useUpdateSourceArea from './useUpdateSourceArea';

const { SUCCESS } = NOTIFICATION_TYPES;
const SETTINGS_SAVED = 'Налаштування збережено';

const fieldsToCompare = ['range', 'frequencyAmplification', 'frequencyAutoAmplification'];

const useSettingsTab = () => {
  const { openNotification } = useNotificationContext();
  const openFormItemValidationNotification = useOpenFormItemValidationNotification();
  const { sourceId = '' } = useParams<SourceRouteParams>();
  const { source, refetchSource } = useGetSourceById({ sourceId });
  const isFoxSource = source?.type === 'fox';
  const { settings, isSettingsLoading, isSettingsEmpty, refetchSettings } = useGetFoxSettings({
    sourceId,
    isFoxSource,
  });
  const updateDeviceRange = useUpdateDeviceRange(sourceId);
  const [form] = Form.useForm<SettingsFormData>();
  const [range, setRange] = useState<number[]>([FoxFrequencyRangeLimitation.Min, FoxFrequencyRangeLimitation.Max]);
  const [amplification, setAmplification] = useState(0);
  const [autoAmplification, setAutoAmplification] = useState(false);
  const initialCoverageArea = useMemo(() => source?.area, [source]);
  const [coverageArea, setCoverageArea] = useState<string[]>();
  const [isDrawModeEnabled, setIsDrawModeEnabled] = useState(false);
  const isSourceOnline = isFoxSource && source?.status === 'online';
  const isSdrStopped = isFoxSource && source?.deviceStatus === 'sdr_stopped';

  const initialSettings: SettingsFormData = useMemo(() => {
    if (isSettingsEmpty) {
      return {
        range: {
          from: FoxFrequencyRangeLimitation.Min,
          to: FoxFrequencyRangeLimitation.Max,
        },
        frequencyAmplification: 0,
        frequencyAutoAmplification: false,
      };
    }

    return pick(settings, fieldsToCompare) as SettingsFormData;
  }, [settings, isSettingsEmpty]);

  const updatedSettings: SettingsFormData = {
    range: {
      from: range[0],
      to: range[1],
    },
    frequencyAmplification: amplification,
    frequencyAutoAmplification: autoAmplification,
  };

  const isSettingsUpdated = !isEqual(initialSettings, updatedSettings);
  const isCoverageUpdated = !isEqual(initialCoverageArea, coverageArea);

  const { saveFoxSettings, isFoxSettingsSaving } = useSaveFoxSettings({
    onSuccess: () => {
      updateDeviceRange(updatedSettings.range);
      refetchSettings();
    },
    onError: (error) => {
      validationService.validateForm({ form, error, validations });
    },
  });

  const { updateSourceArea, isSourceAreaUpdating } = useUpdateSourceArea({
    onSuccess: () => {
      refetchSource();
    },
    onError: (error) => {
      openFormItemValidationNotification({
        error,
        validations,
        errorPointer: 'area',
      });
    },
  });

  const handleSettingsReset = () => {
    setRange([initialSettings.range.from, initialSettings.range.to]);
    setAmplification(initialSettings.frequencyAmplification);
    setAutoAmplification(initialSettings.frequencyAutoAmplification);
  };

  const handleCoverageReset = () => {
    setCoverageArea(initialCoverageArea);
  };

  const handleFormReset = () => {
    handleSettingsReset();
    handleCoverageReset();
  };

  const handleFormSave = async () => {
    if (!isSettingsUpdated && !isCoverageUpdated) return;

    if (isSettingsUpdated) {
      await saveFoxSettings({
        sourceId,
        payload: updatedSettings,
      });
    }
    if (isCoverageUpdated) {
      await updateSourceArea({
        sourceId,
        payload: {
          area: coverageArea ?? [],
        },
      });
    }
    openNotification({ type: SUCCESS, title: SETTINGS_SAVED });
  };

  useEffect(() => {
    handleSettingsReset();
  }, [initialSettings]);

  useEffect(() => {
    handleCoverageReset();
  }, [initialCoverageArea]);

  return {
    form,
    isFormChanged: isSettingsUpdated || isCoverageUpdated,
    isFormSaving: isFoxSettingsSaving || isSourceAreaUpdating,
    handleFormReset,
    handleFormSave,
    settings,
    isSettingsEmpty,
    isSettingsDisabled: isSdrStopped || !isSourceOnline,
    range,
    setRange,
    amplification,
    setAmplification,
    autoAmplification,
    setAutoAmplification,
    coverageArea,
    setCoverageArea,
    isDrawModeEnabled,
    setIsDrawModeEnabled,
    isTabContentLoading: isFoxSource ? isSettingsLoading : false,
  };
};

export default useSettingsTab;
