import { useEffect, useMemo, useRef, useState } from 'react';
import { imageService } from '../services';
import { ImageProps } from '../types';

interface Params extends Pick<ImageProps, 'memoImage'> {}

const useImage = ({ memoImage }: Params) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const [imageWrapperWidth, setImageWrapperWidth] = useState<number | null>(null);
  const image = useMemo(() => {
    if (!imageWrapperWidth) return null;

    return imageService.getImage({ notificationImage: memoImage, imageWrapperWidth });
  }, [imageWrapperWidth, memoImage]);

  useEffect(() => {
    if (!imageWrapperRef.current?.clientWidth) return;

    setImageWrapperWidth(imageWrapperRef.current?.clientWidth);
  }, []);

  return {
    imageWrapperRef,
    image,
  };
};

export default useImage;
