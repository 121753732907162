import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/shared/types';
import { ModalMapState as MapState } from './types';

const initialState: MapState = {
  callingElementPosition: null,
  markerCoordinates: null,
  markerType: 'location',
};

const modalMapSlice = createSlice({
  name: 'modalMap',
  initialState,
  reducers: {
    setCallingElementPosition: (state: MapState, action: PayloadAction<MapState['callingElementPosition']>) => {
      state.callingElementPosition = action.payload;
    },
    setMarkerCoordinates: (state: MapState, action: PayloadAction<MapState['markerCoordinates']>) => {
      state.markerCoordinates = action.payload;
    },
    setMarkerType: (state: MapState, action: PayloadAction<MapState['markerType']>) => {
      state.markerType = action.payload;
    },
    resetModalMapState: () => initialState,
  },
});

export const { setCallingElementPosition, setMarkerCoordinates, setMarkerType, resetModalMapState } =
  modalMapSlice.actions;

export const selectCallingElementPosition = (state: RootState) => state.modalMap.callingElementPosition;
export const selectMarkerCoordinates = (state: RootState) => state.modalMap.markerCoordinates;
export const selectMarkerType = (state: RootState) => state.modalMap.markerType;

export default modalMapSlice.reducer;
