import { SpectrogramProps, ZoomData } from '../types';
import getRoundedFloat from './getRoundedFloat';

interface Params extends Pick<SpectrogramProps, 'startFrequency' | 'endFrequency'> {
  zoomData: ZoomData;
  shiftPercent?: number;
}

const getZoomDataShiftedRight = ({
  zoomData,
  startFrequency,
  endFrequency,
  shiftPercent = 5,
}: Params): ZoomData | null => {
  if (!zoomData || zoomData.endZoomPercent === 100) return null;

  const newStartZoomPercent = zoomData.startZoomPercent + shiftPercent;
  const newEndZoomPercent = zoomData.endZoomPercent + shiftPercent;
  const newStartZoomFrequency = getRoundedFloat(
    ((endFrequency - startFrequency) / 100) * newStartZoomPercent + startFrequency
  );
  const newEndZoomFrequency = getRoundedFloat(
    ((endFrequency - startFrequency) / 100) * newEndZoomPercent + startFrequency
  );
  const isAtRightCorner = newEndZoomPercent >= 100;

  const newZoomData = {
    startZoomFrequency: newStartZoomFrequency,
    endZoomFrequency: newEndZoomFrequency,
    startZoomPercent: newStartZoomPercent,
    endZoomPercent: newEndZoomPercent,
  };

  const rightCornerZoomData = {
    startZoomPercent: 100 - (zoomData.endZoomPercent - zoomData.startZoomPercent),
    endZoomPercent: 100,
    endZoomFrequency: endFrequency,
    startZoomFrequency: endFrequency - (zoomData.endZoomFrequency - zoomData.startZoomFrequency),
  };

  return isAtRightCorner ? rightCornerZoomData : newZoomData;
};

export default getZoomDataShiftedRight;
