import { useEffect } from 'react';

interface Params {
  memoOnTabBecameActive: VoidFunction;
}

const useTabVisibilityChange = ({ memoOnTabBecameActive }: Params) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        memoOnTabBecameActive();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [memoOnTabBecameActive]);
};

export default useTabVisibilityChange;
