import { Row } from 'antd';
import { FileUnknownOutlined } from '@ant-design/icons';
import { LoadingWrapper, MobileListManager, ScrollUpToRefresh } from '@/shared/components';
import PlayButton from '../PlayButton';
import { MetadataRow } from './components';
import { useMobileRecordsList } from './hooks';
import styles from './styles.module.css';

const MobileRecordsList = () => {
  const {
    filesList,
    getFileRef,
    isLoading,
    rowClassName,
    renderDetailsTitle,
    renderDetailsComponent,
    renderActions,
    handleItemClick,
    handleScroll,
    handleScrollUpToReFetch,
    scrollTopPosition,
    recordsListWrapperRef,
  } = useMobileRecordsList();

  return (
    <LoadingWrapper
      isLoading={isLoading}
      isFixedOverlay
    >
      <ScrollUpToRefresh
        isScrollUpToRefreshEnabled={filesList.length > 0}
        onRefresh={handleScrollUpToReFetch}
        hasMobileVirtualListChildren
        isRefreshTriggerDisabled={scrollTopPosition > 0}
      >
        <MobileListManager
          withEmptyPlaceholder
          listProps={{
            onScroll: handleScroll,
            listRef: recordsListWrapperRef,
          }}
          listData={filesList}
          getItemRef={getFileRef}
          onItemClick={handleItemClick}
          rowClassName={rowClassName}
          renderRow={(file) => (
            <Row className={styles.rowWrapper}>
              {file.metadata.isDecoded ? (
                <PlayButton
                  id={file.id}
                  className={styles.playButton}
                />
              ) : (
                <FileUnknownOutlined className={styles.notDecodedIcon} />
              )}
              <MetadataRow metadata={file.metadata} />
            </Row>
          )}
          itemDetailsManager={{
            headerClassName: styles.detailsHeader,
            renderDetailsTitle,
            renderDetailsComponent,
            renderActions,
          }}
        />
      </ScrollUpToRefresh>
    </LoadingWrapper>
  );
};

export default MobileRecordsList;
