import { openSelectionBox } from '@/entities/transcript/slices';
import { useAppDispatch, useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams } from '@/shared/types';

export const useSelectionBox = () => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const dispatch = useAppDispatch();

  return (messageElement: HTMLElement) => {
    const selection = window.getSelection();
    const selectedEntry = selection?.toString().trim();
    const transcriptElement = messageElement.closest<HTMLElement>('.selection-wrap');

    const {
      dataset: { cluster, transcriptId, networkId },
    } = messageElement;

    // Avoid clusters and selection with { code }
    if (
      // do not show selection box for clusters on "All Interceptions" page
      (cluster === 'true' && !radioNetworkId) ||
      !transcriptId ||
      !selection ||
      selectedEntry?.search(/[{}]/) !== -1
    ) {
      return;
    }

    const selectionRange = selection.getRangeAt(0);
    const selectionCoords = selectionRange.getBoundingClientRect();
    const parentElemCoords = transcriptElement?.getBoundingClientRect() ?? { x: 0, y: 0 };

    const pointX = selectionCoords.x - parentElemCoords.x + selectionCoords.width / 2;
    const pointY = selectionCoords.y - parentElemCoords.y - 60;

    return dispatch(
      openSelectionBox({
        transcriptId,
        networkId,
        selection: selectedEntry,
        points: { x: pointX, y: pointY, originalX: selectionCoords.x, originalY: selectionCoords.y },
      })
    );
  };
};
