import { Flex } from 'antd';
import { Notification } from '@/entities/notification/api/types';
import { ExternalIcon } from '@/shared/assets';
import { Button as ButtonComponent } from '@/shared/components';
import styles from './styles.module.css';

interface Props {
  data: Notification['button'];
}

const Button = ({ data }: Props) => {
  if (!data) return null;

  return (
    <Flex justify="flex-end">
      {data.isExternal ? (
        <ButtonComponent
          type="link"
          className={styles.externalButton}
          href={data.link}
          target="_blank"
        >
          {data.text}
          <ExternalIcon />
        </ButtonComponent>
      ) : (
        <ButtonComponent
          block
          type="primary"
          href={data.link}
          target="_blank"
        >
          {data.text}
        </ButtonComponent>
      )}
    </Flex>
  );
};

export default Button;
