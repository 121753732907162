import { TranscriptNetwork } from '@/shared/types';

export const EMPTY_NETWORK = {
  networkId: null,
  networkName: null,
};

const moveEmptyNetworkToEnd = (networks: TranscriptNetwork[] = []) => {
  const emptyNetworkIndex = networks.findIndex(({ networkId }) => networkId === null);

  if (emptyNetworkIndex < 0) return networks;

  return [...networks.slice(0, emptyNetworkIndex), ...networks.slice(emptyNetworkIndex + 1), EMPTY_NETWORK];
};

export default moveEmptyNetworkToEnd;
