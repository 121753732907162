import { useEffect } from 'react';
import { Key } from 'ts-key-enum';
import { useIsMobile, useIsTablet } from '@/shared/hooks';
import { DrawerProps } from '../types';

interface Params extends Pick<DrawerProps, 'open' | 'onClose'> {}

const useDrawer = ({ open, onClose }: Params) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  useEffect(() => {
    if (!open) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === Key.Enter && document.activeElement?.querySelector('[data-drawer]')) {
        onClose?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return {
    isMobile,
    isTablet,
  };
};

export default useDrawer;
