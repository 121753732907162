import { useEffect, useState, useCallback } from 'react';
import { Buffer } from 'buffer';
import zstd from 'zstandard-wasm';
import { sentryService } from '@/shared/services';
import { ScannerPublicationValueItem } from '../types';

const useDecoder = () => {
  const [isDecoderReady, setIsDecoderReady] = useState(false);

  useEffect(() => {
    const loadZstdWasm = async () => {
      await zstd.loadWASM();
    };
    loadZstdWasm().then(() => {
      setIsDecoderReady(true);
    });
  }, []);

  const decodeSpectrumBase64 = useCallback(
    (spectrumBase64: string) => {
      if (!isDecoderReady) {
        console.error('decoder is not ready');
        return;
      }

      try {
        const spectrumDataUint8Array = zstd.decompress(Buffer.from(spectrumBase64, 'base64'));
        const spectrumData = Array.from(spectrumDataUint8Array);
        const convertedSpectrumData = spectrumData.map((num: number) => (255 * (num - 50)) / 110);
        return convertedSpectrumData;
      } catch (e) {
        sentryService.captureExpandedException({
          exception: e,
          extraData: {
            name: 'useDecoder',
            value: 'decodeSpectrumBase64',
          },
        });
      }
    },
    [isDecoderReady]
  );

  const decodeScannerBase64 = useCallback(
    (scannerBase64: string) => {
      if (!isDecoderReady) {
        console.error('decoder is not ready');
        return;
      }

      try {
        const view = zstd.decompress(new Uint8Array(Buffer.from(scannerBase64, 'base64')));
        const textData = new TextDecoder().decode(view);
        const scannerData = JSON.parse(textData) as Pick<ScannerPublicationValueItem, 'frequency' | 'isDigital'>[];
        return scannerData.map(({ frequency, isDigital }) => {
          return { frequency, isDigital };
        });
      } catch (e) {
        sentryService.captureExpandedException({
          exception: e,
          extraData: {
            name: 'useDecoder',
            value: 'decodeScannerBase64',
          },
        });
      }
    },
    [isDecoderReady]
  );

  return {
    isDecoderReady,
    decodeSpectrumBase64,
    decodeScannerBase64,
  };
};

export default useDecoder;
