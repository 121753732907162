import { useIsMobile, useIsTablet } from '@/shared/hooks';
import ResponsiveManagerProps from '../types';

interface Params extends Pick<ResponsiveManagerProps, 'tablet' | 'mobile'> {}

const useResponsiveManager = ({ tablet, mobile }: Params) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return {
    shouldRenderMobile: isMobile && !!mobile,
    shouldRenderTablet: isTablet && !!tablet,
  };
};

export default useResponsiveManager;
