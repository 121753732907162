export const MAP_COLORS = {
  base: '#fff',
  primary: '#ff4d4f',
  location: '#a5b8ca',
  locationCounterText: 'rgba(0, 0, 0, 0.85)',
  clusterPolygon: '#627bc1',
  watermark: 'rgba(139, 139, 139, 0.5)',
  hexagonUnselectedBg: 'rgba(217, 217, 217, 0.1)',
  hexagonHoveredBg: 'rgba(255, 77, 79, 0.25)',
  hexagonSelectedBg: 'rgba(255, 77, 79, 0.5)',
  hexagonBorder: 'rgba(0, 0, 0, 0.2)',
  heatmapGradient: {
    step1: 'rgba(255, 255, 204, 0.3)',
    step2: 'rgba(255, 237, 160, 0.5)',
    step3: 'rgba(254, 217, 118, 0.5)',
    step4: 'rgba(254, 178, 76, 0.5)',
    step5: 'rgba(253, 141, 60, 0.5)',
    step6: 'rgba(252, 78, 42, 0.5)',
    step7: 'rgba(227, 26, 28, 0.5)',
    step8: 'rgba(189, 0, 38, 0.5)',
    step9: 'rgba(128, 0, 38, 0.5)',
  },
};
