import { NavigationDrawer, LoadingWrapper } from '@/shared/components';
import { COPIES } from '@/shared/constants';
import { Notification } from './components';
import { useNotificationsDrawer } from './hooks';
import styles from './styles.module.css';

const NotificationsDrawer = () => {
  const { isOpen, isFetching, isEmpty, notifications, seenNotifications } = useNotificationsDrawer();

  return (
    <NavigationDrawer
      title={COPIES.NOTIFICATIONS}
      open={isOpen}
      className={styles.wrapper}
      mobileClassName={styles.wrapper}
    >
      <LoadingWrapper
        isFixedOverlay
        isEmpty={isEmpty}
        isLoading={isFetching}
        emptyText={COPIES.NO_DATA}
        emptyClassName={styles.noData}
      >
        {notifications.map((notification) => (
          <Notification
            memoNotification={notification}
            key={notification.documentId}
            isNew={!seenNotifications[notification.documentId]}
          />
        ))}
      </LoadingWrapper>
    </NavigationDrawer>
  );
};

export default NotificationsDrawer;
