import { useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useOnViewportTypeChange } from '@/shared/hooks';
import { AudioPlayerProps } from '../type';

interface Params
  extends Pick<
    AudioPlayerProps,
    'shouldBePlaying' | 'memoOnPlayPause' | 'isRecordSelected' | 'hasVoice' | 'isDisabled'
  > {
  wavesurfer: WaveSurfer | null;
  isPlaying: boolean;
  isReady: boolean;
  isSkipSilenceEnabled?: boolean;
}

const usePlayPause = ({
  wavesurfer,
  isRecordSelected,
  shouldBePlaying,
  isPlaying,
  isReady,
  memoOnPlayPause,
  isSkipSilenceEnabled,
  hasVoice,
  isDisabled,
}: Params) => {
  const memoPlay = useCallback(
    (shouldPlaySelectedRecord: boolean = false) => {
      if (isDisabled) return;

      if (isReady) {
        wavesurfer?.play();
      }

      memoOnPlayPause?.({ isPlaying: true, shouldPlaySelectedRecord });
    },
    [isDisabled, isReady, wavesurfer, memoOnPlayPause]
  );

  const memoPause = useCallback(() => {
    wavesurfer?.pause();
    memoOnPlayPause?.({ isPlaying: false });
  }, [wavesurfer, memoOnPlayPause]);

  const memoStop = useCallback(() => {
    wavesurfer?.stop();
    memoOnPlayPause?.({ isPlaying: false });
  }, [wavesurfer, memoOnPlayPause]);

  const memoPlayPauseSelectedRecord = useCallback(() => {
    if (isDisabled) return;

    if (isPlaying) {
      if (isRecordSelected) {
        memoPause();
      } else {
        memoOnPlayPause?.({ isPlaying: false });
        memoPlay(true);
      }
    } else {
      memoPlay(true);
    }
  }, [isDisabled, isPlaying, isRecordSelected, memoOnPlayPause, memoPause, memoPlay]);

  const memoPlayPause = useCallback(
    (shouldPlaySelectedRecord: boolean = false) => {
      if (shouldPlaySelectedRecord) {
        memoPlayPauseSelectedRecord();
      } else if (isPlaying) {
        memoPause();
      } else {
        memoPlay();
      }
    },
    [isPlaying, isRecordSelected, memoPause, memoPlay]
  );

  useEffect(() => {
    const reactToShouldBePlayingUpdate = () => {
      if (isPlaying && !hasVoice && isSkipSilenceEnabled) {
        memoStop();
      } else {
        memoPlayPause();
      }
    };

    if (isReady && isPlaying !== shouldBePlaying) {
      reactToShouldBePlayingUpdate();
    }
  }, [isReady, shouldBePlaying, isPlaying, memoPlayPause, hasVoice, isSkipSilenceEnabled, memoStop]);

  useOnViewportTypeChange({ memoOnChange: memoPause });

  return { memoPlayPause, memoPlay, memoPause, memoStop };
};

export default usePlayPause;
